import { backendClient } from '../../../../common_lib_front/utilities/BackendAPI';
import { passFormInput } from '../../../../components/passForm/passForm';
import { licensePrimaryDriverPicker } from './licenseNumPicker';
import {
  EDIT_PASS,
  EDIT_PASS_RES,
  EDIT_PASS_VARS,
  EDIT_VEHICLE,
  EDIT_VEHICLE_RES,
  EDIT_VEHICLE_VARS,
  SAVE_PASS, SAVE_PASS_RES, SAVE_VEHICLE, SAVE_VEHICLE_RES, SAVE_VEHICLE_VARS,
} from './registerVehicleRequests';

export async function createEditVehiclePass(props: {
  p: passFormInput,
  registrationId: string,
  isRenewal: boolean,
}): Promise<void> {
  const { p, registrationId, isRenewal } = props;
  // console.log(`Saving neww pass ${p.number}`);
  let pid: string | null = p.passId || null;
  // let pRes: FetchResult<EDIT_PASS_RES | SAVE_PASS_RES>;
  let pError: string | undefined;

  // ======== edit pass ========
  if (pid) {
    const pRes = await backendClient.mutate<EDIT_PASS_RES, EDIT_PASS_VARS>({
      mutation: EDIT_PASS,
      variables: {
        passId: pid,
        addons: p.addons,
        startDate: isRenewal ? null : p.startDate,
        endDate: isRenewal ? null : p.endDate,
      },
    })
      .catch((err) => {
        console.error(err);
        pError = 'Something went wrong. Your changes may not have been saved.';
      });
    if (pRes?.errors) {
      pError = 'Something went wrong. Your changes may not have been saved.';
    } else if (pRes?.data?.editPass.error) {
      pError = pRes.data.editPass.error;
    }
  // ======== register pass ========
  } else {
    const pRes = await backendClient.mutate<SAVE_PASS_RES>({
      mutation: SAVE_PASS,
      variables: {
        registrationId,
        passInfoId: p.passInfoId,
        startDate: p.startDate,
        endDate: p.endDate,
        addons: p.addons,
      },
    })
      .catch((err) => {
        console.error(err);
        pError = 'Something went wrong. Your changes may not have been saved.';
      });
    pid = pRes?.data?.registerPass.data?.[0].passId || null;
    if (pRes?.errors) {
      pError = 'Something went wrong. Your changes may not have been saved.';
    } else if (pRes?.data?.registerPass.error) {
      pError = pRes.data.registerPass.error;
    }
  }

  // vehicle cannot be saved without passid
  if (!pid) throw Error(pError || 'Could not register pass. Data may not be saved.');

  let vErr: string | undefined;
  // ======== register vehicle ========
  if (!p.vehicle.vehicleId) {
    const vRes = await backendClient.mutate<SAVE_VEHICLE_RES, SAVE_VEHICLE_VARS>({
      mutation: SAVE_VEHICLE,
      variables: {
        passId: pid,
        make: p.vehicle.make || '',
        vehicleModel: p.vehicle.model || '',
        year: p?.vehicle?.year,
        type: p.vehicle.type || '',
        color: p.vehicle.color || '',
        licensePlate: p.vehicle.licensePlate || p.vehicle.plateNumber || '',
        fleetNumber: p.vehicle.fleetNumber || '',
        licensePlateState: p.vehicle.licensePlateState || '',
        employeeList: (p.vehicle.employeeSelectList && p.vehicle.employeeSelectList.length > 0) ? p.vehicle.employeeSelectList : p.vehicle.employeeList,
        // primaryDriverName: p.vehicle.primaryDriverName || '',
        primaryDriverName: p.employee
          ? `${p.employee.firstName} ${p.employee.lastName}`
          : p.vehicle.primaryDriverName || '',
        driverEmail: p?.employee?.email || '',
        driverPhone: p?.employee?.phoneNumber || '',
        driversLicenseExp: p?.employee?.driversLicenseExp || '',
        employeeId: p.employee
          ? p.employee.employeeId : '',
        licensePrimaryDriver: licensePrimaryDriverPicker(p),
        destination: p.destination || '',
        identificationSource: p?.employee?.source || '',
      },
    })
      .catch((err) => {
        console.error(err);
        vErr = 'Something went wrong. Changes to your vehicle data may not have been saved.';
      });
    if (vRes?.errors) {
      vErr = 'Something went wrong. Changes to your vehicle data may not have been saved.';
    } else if (vRes?.data?.registerVehicle.error) {
      vErr = vRes.data.registerVehicle.error;
    }
  // ======== edit vehicle ========
  } else {
    const vRes = await backendClient.mutate<EDIT_VEHICLE_RES, EDIT_VEHICLE_VARS>({
      mutation: EDIT_VEHICLE,
      variables: {
        vehicleId: p.vehicle.vehicleId,
        make: p.vehicle.make || '',
        vehicleModel: p.vehicle.model || '',
        year: p?.vehicle?.year,
        type: p.vehicle.type || '',
        color: p.vehicle.color || '',
        licensePlate: p.vehicle.licensePlate || p.vehicle.plateNumber || '',
        fleetNumber: p.vehicle.fleetNumber || '',
        licensePlateState: p.vehicle.licensePlateState || '',
        employeeList: (p.vehicle.employeeSelectList && p.vehicle.employeeSelectList.length > 0) ? p.vehicle.employeeSelectList : p.vehicle.employeeList,
        // primaryDriverName: p.vehicle.primaryDriverName || '',
        primaryDriverName: p.employee
          ? `${p.employee.firstName} ${p.employee.lastName}`
          : p.vehicle.primaryDriverName || '',
        driverEmail: p?.employee?.email || '',
        driverPhone: p?.employee?.phoneNumber || '',
        driversLicenseExp: p?.employee?.driversLicenseExp || '',
        employeeId: p.employee
          ? p.employee.employeeId : '',
        licensePrimaryDriver: licensePrimaryDriverPicker(p),
        destination: p.destination || '',
        identificationSource: p?.employee?.source || '',
      },
    })
      .catch((err) => {
        console.error(err);
        vErr = 'Something went wrong. Changes to your vehicle data may not have been saved.';
      });
    if (vRes?.errors) {
      vErr = 'Something went wrong. Changes to your vehicle data may not have been saved.';
    } else if (vRes?.data?.editVehicle.error) {
      vErr = vRes.data?.editVehicle.error;
    }
  }

  // throw errors at the end
  // try to save as much data as possible
  if (pError) {
    throw Error(pError);
  }
  if (vErr) {
    throw Error(vErr);
  }
}
