import { QueryHookOptions, gql, useQuery } from '@apollo/client';
import { backendResponse } from '../types/backendResponse';
import { InfoConfigValues } from '../types/InfoConfigValues';

const GET_VEHICLE_CONFIG = gql`
  query GetVehicleInfoConfig($passInfoId: String!) {
    getVehicleInfoConfig(passInfoId: $passInfoId) {
      success
      error
      data {
        color
        completeLater
        employeeList
        fleetNumber
        licensePlate
        licensePlateState
        licensePrimaryDriver
        make
        name
        passInfoId
        primaryDriverName
        type
        vehicleModel
        year
        vehicleSelect
        driverSelect
        destination
        employeeSelectList
      }
    }
  }
`;

type GET_VEHICLE_CONFIG_VARS = {
  passInfoId: string;
};
type GET_VEHICLE_CONFIG_RES = {
  getVehicleInfoConfig: backendResponse<{
    color: keyof typeof InfoConfigValues;
    completeLater: keyof typeof InfoConfigValues;
    employeeList: keyof typeof InfoConfigValues;
    fleetNumber: keyof typeof InfoConfigValues;
    licensePlate: keyof typeof InfoConfigValues;
    licensePlateState: keyof typeof InfoConfigValues;
    licensePrimaryDriver: keyof typeof InfoConfigValues;
    make: keyof typeof InfoConfigValues;
    name: keyof typeof InfoConfigValues;
    passInfoId: keyof typeof InfoConfigValues;
    primaryDriverName: keyof typeof InfoConfigValues;
    type: keyof typeof InfoConfigValues;
    vehicleModel: keyof typeof InfoConfigValues;
    year: keyof typeof InfoConfigValues;
    vehicleSelect: keyof typeof InfoConfigValues;
    driverSelect: keyof typeof InfoConfigValues;
    destination: keyof typeof InfoConfigValues;
    employeeSelectList: keyof typeof InfoConfigValues;
  }>;
};

type optionsType = QueryHookOptions<GET_VEHICLE_CONFIG_RES, GET_VEHICLE_CONFIG_VARS>;
export const useVehicleConfig = (options = {} as optionsType) =>
  useQuery<GET_VEHICLE_CONFIG_RES, GET_VEHICLE_CONFIG_VARS>(GET_VEHICLE_CONFIG, options);
