import { ApolloQueryResult, gql, useQuery } from '@apollo/client';
import { keyBy, map, uniq } from 'lodash';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import PassInfo, { newPassInfo } from '../../../../common_lib_front/types/passInfo';
import { backendClient } from '../../../../common_lib_front/utilities/BackendAPI';
import { GET_PRICE_INFO } from '../registerVehicle/registerVehicleRequests';

const GET_PASS = gql`
query GetPassesByRegistrationWithPassInfo (
  $registrationId: String!
) {
  getPassesByRegistrationWithPassInfo (
    registrationId: $registrationId
  ) {
    success
    error
    data {
      passId
      passType
      passInfoId
      userId
      number
      externalCredentialNumber
      startDate
      endDate
      # validThrough
      status
      url
      shared
      vehicle {
        passId
        vehicleId
        make
        vehicleModel
        type
        color
        licensePlate
        fleetNumber
        isRental
        licensePlateState
        primaryDriverName
        licensePrimaryDriver
      }
      passInfo {
        name
        communityId
        passApprovalRequire
      }
      addons
    }
  }
}
`;

interface IGetPassResponse {
  getPassesByRegistrationWithPassInfo: {
    success: boolean;
    error: string | null;
    data: PassInfo[] | null;
  }
}

interface IUseOrderSummary {
  passes: PassInfo[];
  errorMsg: string;
  refetch: () => Promise<ApolloQueryResult<IGetPassResponse>>;
}

export default function useOrderSummary(): IUseOrderSummary {
  const { registrationId } = useParams<{ registrationId: string }>();
  const [errorMsg, setErrorMsg] = useState<string | null>();
  const [passes, setPasses] = useState<any>([]);
  const { data, refetch } = useQuery<IGetPassResponse>(GET_PASS, {
    fetchPolicy: 'network-only',
    variables: {
      registrationId,
    },
    onError: (e) => {
      setErrorMsg(e.message);
    },
    onCompleted: async (d) => {
      if (!d.getPassesByRegistrationWithPassInfo.success) {
        setErrorMsg(d.getPassesByRegistrationWithPassInfo.error);
      }

      const res = d?.getPassesByRegistrationWithPassInfo?.data?.map((p: any) => newPassInfo(p));

      // Get passes' prices by passInfoId
      const passInfoIds = uniq(
        map(res, 'passInfoId'),
      );
      try {
        const priceInfoRes = await Promise.all(
          map(passInfoIds, (id: string) => backendClient.query({
            query: GET_PRICE_INFO,
            variables: { passInfoId: id },
          })),
        );
        // Set price info to passes by passInfoId
        const priceInfoMap = keyBy(map(priceInfoRes, 'data.getPriceInfo.data'), 'passInfoId');
        res?.forEach((item: any) => {
          // eslint-disable-next-line no-param-reassign
          item.priceInfo = priceInfoMap[item.passInfoId];
        });
      } catch (error: any) {
        console.log(error);
      }
      setPasses(res);
    },
  });

  return {
    passes: passes || [],
    errorMsg: errorMsg || '',
    refetch,
  };
}
