const en = {
  otherLanguage: 'espa\xF1ol',
  registration_instructions: 'Please follow the instructions below to access your Community Access Pass. You must register and review community rules before checking out. Registration takes approximately 5-10 minutes to complete. Please Note: You cannot enter the community or access ammenities without a Community Access Pass',
  address_no_match: 'This address does not match a known rental. We recomend that you select your address if it exists in the list provided. Or if you do not see your rental address, you can continue with the one currently entered.',
  step2_subtitle: 'Please register all vehicles entering the community. Failure to do so will result in fines and possible expulsion from the community',
  rental_prompt: 'Once you have your rental car information, please return to this system and click on the "Register Rental Car" button to register your rental car and fully activate your pass.',
  step5_subtitle: 'Please verify all information displayed is current and accurate before submitting',
  step4_subtitle: 'Please upload the required documents below before continuing to secure online checkout',
  step6_subtitle: 'We hope you enjoy your stay!',
  price_info: 'Prices in US Dollars (USD). Community Access Passes are nonrefundable. Total calculated at checkout.',
  pass_share_prompt: 'To share a pass, please enter the recipient\'s name, phone number and/or email',
};
export default en;
