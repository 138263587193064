import React, { ReactElement, useState } from 'react';
import style from './vehicleTypeSelect.module.css';
import { useVehicleTypes } from '../../communityConfigs/communitiesRequests';

interface VehicleTypeSelectProps {
  htmlFor: string;
  labelTitle?: string;
  inputValue?: string | undefined;
  changeHandler?: ((e: React.ChangeEvent<HTMLSelectElement>) => void) | undefined;
  labelStyle?: string;
  inputStyle?: string;
  smallSize?: boolean;
  largeSize?: boolean;
  lightText?: boolean;
  highlightOnFocus?: boolean;
  required?: boolean;
}

export const VehicleTypeSelect = (props: VehicleTypeSelectProps): ReactElement => {
  const [focused, setFocus] = useState<boolean>(false);

  const { vehicleTypeList } = useVehicleTypes();
  const {
    labelTitle,
    htmlFor,
    changeHandler,
    inputValue,
    labelStyle,
    inputStyle,
    smallSize,
    largeSize,
    lightText,
    highlightOnFocus,
    required,
  } = props;

  return (
    <div className={style.container}>
      <label className={style.labelBox} htmlFor={htmlFor}>
        <div className={`${style.text} textColorSuperDark `}>{labelTitle}</div>
        <div className={style.selectBox}>
          <select
            className={style.select}
            value={inputValue}
            id={htmlFor}
            onChange={changeHandler}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            required={required}
          >
            <option selected disabled hidden>
              {' '}
            </option>
            {vehicleTypeList.map((type, index) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>
      </label>
    </div>
  );
};
VehicleTypeSelect.defaultProps = {
  labelTitle: '',
  inputValue: undefined,
  changeHandler: undefined,
  labelStyle: ' default_style',
  inputStyle: ' default_style',
  smallSize: false,
  largeSize: false,
  lightText: false,
  highlightOnFocus: false,
  required: false,
};

export default VehicleTypeSelect;
