import {
  QueryHookOptions, QueryResult, gql, useQuery,
} from '@apollo/client';
import { backendResponse } from '../common_lib_front/types/backendResponse';

const GET_REGISTRATION = gql`
query GetRegistration($registrationId: String!) {
  getRegistration(registrationId: $registrationId) {
    success
    error
    data {
      complete
      registrationId
      stepNumber
      userId
      renew
    }
  }
}
`;

type GET_REGISTRATION_VARS = {
  registrationId: string;
}

export type registrationResponse = {
  registrationId: string;
  complete: boolean;
  stepNumber: number;
  userId: string;
  renew: boolean;
}

type GET_REGISTRATION_RES = {
  getRegistration: backendResponse<registrationResponse[]>;
}

type optionsType = QueryHookOptions<GET_REGISTRATION_RES, GET_REGISTRATION_VARS>;
type resType = QueryResult<GET_REGISTRATION_RES, GET_REGISTRATION_VARS>;

export const useGetRegistration = (
  variables: GET_REGISTRATION_VARS, options?: optionsType,
): resType => useQuery<
  GET_REGISTRATION_RES, GET_REGISTRATION_VARS
>(GET_REGISTRATION, {
  ...options,
  variables,
});
