import { QueryResult, gql, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { CommunityContext } from '../common_lib_front/communityConfigs/communityContextProvider';
import { backendResponse } from '../common_lib_front/types/backendResponse';

const GET_PASS_INFOS = gql`
query GetPassInfosByCommunity($complete: Boolean!, $communityId: String!) {
  getPassInfosByCommunity(complete: $complete, communityId: $communityId) {
    success
    error
    data {
      passInfoId
      name
      description
      customInstructions
      passType
      passesPerRental
      portal
      needsApplication
      passApprovalRequire
    }
  }
}
`;
type GET_PASS_INFOS_VARS = {
  complete: boolean,
  communityId: string,
}
type GET_PASS_INFOS_RES = {
  getPassInfosByCommunity: backendResponse<{
      passInfoId: string;
      name: string;
      description: string;
      customInstructions: string;
      passType: string;
      passesPerRental: string;
      portal: string;
      needsApplication: boolean;
      passApprovalRequire: boolean;
  }[]>;
}

export function useGetAllPassInfos(): QueryResult<GET_PASS_INFOS_RES, GET_PASS_INFOS_VARS> {
  const { communityId } = useContext(CommunityContext);
  return useQuery<GET_PASS_INFOS_RES, GET_PASS_INFOS_VARS>(GET_PASS_INFOS, {
    variables: {
      communityId,
      complete: true,
    },
  });
}
