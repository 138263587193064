import {
  MutationHookOptions, MutationTuple, gql, useMutation,
} from '@apollo/client';
import { backendResponse } from '../common_lib_front/types/backendResponse';

const CREATE_REGISTRATION = gql`
mutation Mutation ($type: String!) {
  createRegistration (type: $type) {
    success
    error
    data {
      registrationId
    }
  }
}
`;

type CREATE_REGISTRATION_VARS = {
  type: string;
};

export type createRegistrationResponse = {
  registrationId: string;
}

type CREATE_REGISTRATION_RES = {
  createRegistration: backendResponse<createRegistrationResponse[]>;
}

type optionsType = MutationHookOptions<CREATE_REGISTRATION_RES, CREATE_REGISTRATION_VARS>;
type resType = MutationTuple<CREATE_REGISTRATION_RES, CREATE_REGISTRATION_VARS>;

export const useCreateRegistration = (options?: optionsType): resType => useMutation<
  CREATE_REGISTRATION_RES, CREATE_REGISTRATION_VARS
>(CREATE_REGISTRATION, {
  ...options,
});
