import map from 'lodash/map';
import uniq from 'lodash/uniq';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  ApolloError, gql, useMutation, useQuery,
} from '@apollo/client';

import { IDocumentConfig } from '../../../../common_lib_front/types/documentInfo';
import PassInfo from '../../../../common_lib_front/types/passInfo';
import { backendClient } from '../../../../common_lib_front/utilities/BackendAPI';
import store, { documentConfig as reduxDocumentConfig } from '../../../../common_lib_front/utilities/store';
import { registrationStepProps } from '../wrapper';

const GET_PASSES = gql`
  query GetPasses (
    $registrationId: String!
  ) {
    getPassesByRegistration (
      registrationId: $registrationId
    ) {
      success
      error
      data {
        passId
        passInfoId
      }
    }
  }
`;

const GET_EDUCATION_INFO = gql`
  query GetEducationInfo (
    $passInfoId: String!
  ) {
    getEducationInfoConfig (
      passInfoId: $passInfoId
    ) {
      success
      error
      data {
        passInfoId
        englishEducation
      }
    }
  }
`;

const SET_EDUCATION_COMPLETE = gql`
  mutation editEducation(
    $registrationId: String!,
    $educationName: String!,
  ) {
    editEducation(
      registrationId: $registrationId,
      educationName: $educationName,
    ) {
      success,
      error
    }
  }
`;

export default function useCommunityOrientation(props: registrationStepProps): {
  passes: Array<PassInfo>,
  registrationId: string,
  errorMessage: string,
  educationInfos: Array<any>,
  doCompleteHandle: (name: string) => void,
  redirect: string,
  documentConfig: IDocumentConfig[],
  } {
  const { registrationId } = useParams<{ registrationId: string }>();
  const [errorMsg, setErrorMsg] = useState<string | null>();
  const [educationInfos, setEducationInfos] = useState<any>([]);

  const [redirect, setRedirect] = useState<string>('');
  const [documentConfig, setDocumentConfig] = useState<IDocumentConfig[]>(reduxDocumentConfig());

  // eslint-disable-next-line max-len
  useEffect(() => store.subscribe(() => setDocumentConfig(reduxDocumentConfig())), [setDocumentConfig]);

  const { data: passesData } = useQuery(GET_PASSES, {
    variables: { registrationId },
    fetchPolicy: 'cache-and-network',
    onError: (e: ApolloError) => {
      setErrorMsg(e.message);
    },
    onCompleted: async (d) => {
      if (!d.getPassesByRegistration.success) {
        setErrorMsg(d.getPassesByRegistration.error);
      } else {
        const passInfoIds = uniq(map(d.getPassesByRegistration.data, 'passInfoId'));
        try {
          const educationInfoConfigRes = await Promise.all(
            map(passInfoIds, (id: string) => backendClient.query({
              query: GET_EDUCATION_INFO,
              variables: { passInfoId: id },
            })),
          );

          const educations = map(educationInfoConfigRes, 'data.getEducationInfoConfig.data');
          setEducationInfos(educations);

          if (educations?.filter((e: any) => e.englishEducation).length === 0) {
            setErrorMsg('No community orientation was found');
          }
        } catch (error: any) {
          setErrorMsg(error?.message);
        }
      }
    },
  });

  const [doSetComplete] = useMutation(SET_EDUCATION_COMPLETE);

  const doCompleteHandle = async (educationName: string) => {
    doSetComplete({
      variables: {
        registrationId,
        educationName,
      },
      onCompleted: (d) => {
        if (!d.editEducation.success) {
          setErrorMsg(d.editEducation.error);
        } else {
          setRedirect(props.nextPath(registrationId));
        // } else if (documentConfig && documentConfig.length > 0) {
        //   setRedirect(`/vendor/registration/${registrationId}/documents`);
        // } else {
        //   setRedirect(`/vendor/registration/${registrationId}/secure-checkout`);
        }
      },
    });
  };

  return {
    passes: passesData?.getPassesByRegistration?.data || [],
    registrationId,
    errorMessage: errorMsg || '',
    educationInfos: educationInfos || [],
    doCompleteHandle,
    redirect,
    documentConfig,
  };
}
