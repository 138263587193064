import React, {
  ReactElement, SetStateAction, useContext,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import alertIcon from '../../assets/alert.svg';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import InputField from '../../common_lib_front/components/inputField/inputField';
import PhoneInputWrapper from '../../common_lib_front/components/phoneInputWrapper/phoneInputWrapper';
import { CompanyInfo as TCompanyInfo, vendorInfoConfigType } from '../../common_lib_front/types/companyInfo';
import store from '../../common_lib_front/utilities/store';
import BusinessTypeDropdown from '../businessTypesDropDown/businessTypesDropdown';
import UsStateSelect from '../usStateSelect/UsStateSelect';
import style from './companyInformationForm.module.css';

interface CompanyInfoProps {
  data: TCompanyInfo | undefined;
  // setData: (data: TCompanyInfo) => void;
  setData: React.Dispatch<SetStateAction<TCompanyInfo>>;
  vendorInfo: vendorInfoConfigType | undefined;
  readOnlyFields?: [string];
  autofillFromUser?: boolean;
}

export default function CompanyInformationForm(props: CompanyInfoProps): ReactElement {
  const {
    data, setData, vendorInfo, readOnlyFields, autofillFromUser,
  } = props;
  useEffect(() => {
    const user = store.getState()?.user;
    setData((prev) => ({
      ...prev,
      firstName: prev.firstName || user?.firstName || '',
      lastName: prev.lastName || user?.lastName || '',
      email: prev.email || user?.email || '',
    }));
  }, []);
  const { t } = useTranslation();
  const { communityId, featuresConfig } = useContext(CommunityContext);

  return (
    <div className={style.infoBox}>
      <div className={style.inputBox}>
        <div className={style.flexBox}>
          <div className={style.inputLong}>
            <InputField
              largeSize
              highlightOnFocus
              htmlFor="company-name-inpt"
              labelTitle={t('Business Name')}
              inputType="text"
              inputValue={data?.companyName}
              required={vendorInfo?.businessName === 'required'}
              changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                setData({ ...data, companyName: e.target.value });
              }}
            />
          </div>
          {
            (featuresConfig?.vendor?.businessTypeRequired) ? (
              <div className={style.inputShort}>
                <BusinessTypeDropdown
                  title={t('Business Type')}
                  htmlFor="business-type-inpt"
                  value={data?.businessType || ''}
                  changeHandler={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setData({ ...data, businessType: e.target.value });
                  }}
                  readonly={readOnlyFields ? readOnlyFields?.indexOf('businessType') > -1 : false}
                />
              </div>
            ) : null
          }
          {vendorInfo && ['required', 'optional'].indexOf(vendorInfo.businessLicenseNumber) >= 0 && (
            <div className={style.inputLong}>
              <InputField
                largeSize
                highlightOnFocus
                htmlFor="business-license-inpt"
                labelTitle={t('Business License Number')}
                inputType="text"
                required={vendorInfo.businessLicenseNumber === 'required'}
                inputValue={data?.businessLicenseNumber}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setData({ ...data, businessLicenseNumber: e.target.value });
                }}
              />
            </div>
          )}
          {vendorInfo && ['required', 'optional'].indexOf(vendorInfo.businessAddress) >= 0 && (
            <div className={style.inputLong}>
              <InputField
                largeSize
                highlightOnFocus
                htmlFor="company-address-inpt"
                labelTitle={t('Business Address')}
                inputType="text"
                required={vendorInfo.businessAddress === 'required'}
                inputValue={data?.address}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setData({ ...data, address: e.target.value });
                }}
              />
            </div>
          )}
          {vendorInfo && ['required', 'optional'].indexOf(vendorInfo.businessAddress) >= 0 && (

            <div className={style.inputShort}>
              <InputField
                largeSize
                highlightOnFocus
                htmlFor="city-inpt"
                labelTitle={t('City')}
                inputType="text"
                inputValue={data?.city}
                required={vendorInfo.businessAddress === 'required'}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setData({ ...data, city: e.target.value });
                }}
              />
            </div>
          )}
          {vendorInfo && ['required', 'optional'].indexOf(vendorInfo.businessAddress) >= 0 && (
            <div className={style.inputShort}>
              {/* <InputField
                largeSize
                highlightOnFocus
                htmlFor="state-inpt"
                labelTitle="State"
                inputType="text"
                inputValue={data?.state}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setData({ ...data, state: e.target.value });
                }}
              /> */}
              <UsStateSelect
                title={t('State')}
                htmlFor="state-inpt"
                value={data?.state || ''}
                changeHandler={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  setData({ ...data, state: e.target.value });
                }}
              />
            </div>
          )}

          {vendorInfo && ['required', 'optional'].indexOf(vendorInfo.businessAddress) >= 0 && (

            <div className={style.inputShort}>
              <InputField
                largeSize
                highlightOnFocus
                htmlFor="zip-inpt"
                labelTitle={t('Zip Code')}
                inputType="text"
                formatCode="zip-strict"
                inputValue={data?.zipCode}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setData({ ...data, zipCode: e.target.value });
                }}
              />
            </div>

          )}
          {vendorInfo
            && ['firstName', 'lastName', 'email'].some((k) => ['required', 'optional'].includes(vendorInfo[k as keyof vendorInfoConfigType] || ''))
            && (
              <>
                <h3>
                  Primary Company Contact:
                </h3>
                {['required', 'optional'].includes(vendorInfo.firstName) && (
                  <div className={style.inputShort}>
                    <InputField
                      largeSize
                      highlightOnFocus
                      htmlFor="first-name"
                      labelTitle={t('First Name')}
                      inputType="text"
                      inputValue={data?.firstName}
                      changeHandler={(e) => setData((prev) => ({
                        ...prev,
                        firstName: e.target.value,
                      }))}
                    />
                  </div>
                )}
                {['required', 'optional'].includes(vendorInfo.lastName) && (
                  <div className={style.inputShort}>
                    <InputField
                      largeSize
                      highlightOnFocus
                      htmlFor="last_name"
                      labelTitle={t('Last Name')}
                      inputType="text"
                      inputValue={data?.lastName}
                      changeHandler={(e) => setData((prev) => ({
                        ...prev,
                        lastName: e.target.value,
                      }))}
                    />
                  </div>
                )}
                {['required', 'optional'].includes(vendorInfo.email) && (
                  <div className={style.inputLong}>
                    <InputField
                      largeSize
                      highlightOnFocus
                      htmlFor="email_inpt"
                      labelTitle={t('Email Address')}
                      inputType="text"
                      inputValue={data?.email}
                      changeHandler={(e) => setData((prev) => ({
                        ...prev,
                        email: e.target.value,
                      }))}
                    />
                  </div>
                )}
              </>
            )}
          {vendorInfo && ['required', 'optional'].indexOf(vendorInfo.businessPhone) >= 0 && (
            <div className={style.inputLong}>
              <PhoneInputWrapper
                standardInput
                // formerly "Business Phone Number"
                inputTitle={t('Primary Phone Number')}
                value={data?.businessPhone}
                changeHandler={(val: string) => {
                  setData({ ...data, businessPhone: val });
                }}
              />
            </div>
          )}
          {vendorInfo && vendorInfo.alternatePhone && ['required', 'optional'].indexOf(vendorInfo.alternatePhone) >= 0 && (
            <div className={style.inputLong}>
              <PhoneInputWrapper
                standardInput
                inputTitle={t('Alternate Phone Number')}
                value={data?.altPhoneNumber}
                changeHandler={(val: string) => {
                  setData({ ...data, altPhoneNumber: val });
                }}
              />
            </div>
          )}
          <h3>Emergency Company Contact:</h3>
          {vendorInfo && ['required', 'optional'].indexOf(vendorInfo.emergencyContactName) >= 0 && (
            <div className={style.inputLong}>
              <InputField
                largeSize
                highlightOnFocus
                htmlFor="emergency-contact-name-inpt"
                labelTitle={t('Emergency Contact Name')}
                inputType="text"
                required={vendorInfo.emergencyContactName === 'required'}
                inputValue={data?.emergencyContactName}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setData({ ...data, emergencyContactName: e.target.value });
                }}
              />
            </div>
          )}
          <div className={style.inputLong}>
            <PhoneInputWrapper
              standardInput
              inputTitle={t('Emergency Contact Cell Phone Number')}
              value={data?.emergencyPhoneNumber}
              changeHandler={(val: string) => {
                setData({ ...data, emergencyPhoneNumber: val });
              }}
            />
          </div>
        </div>

        <div className={style.alertBox}>
          <img src={alertIcon} alt="" />
          <p className={`${style.alert} textColorMedium `}>
            {' '}
            &nbsp;
            {t('Please verify that the above information is current and accurate.')}
          </p>
        </div>
      </div>
    </div>
  );
}

CompanyInformationForm.defaultProps = {
  readOnlyFields: [],
  autofillFromUser: false,
};
