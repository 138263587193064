import { gql, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import LoadingDiamonds from '../../../common_lib_front/components/loadingDiamonds/loadingDiamonds';
import EmployeeInfo from '../../../common_lib_front/types/employeeInfo';
import EmployeesGrid from '../../../components/employeesGrid/employeesGrid';
import style from './employee.module.css';

export const GET_EMPLOYEE = gql`
  query Query (
  $employeeId: String
) {
  getEmployee (
    employeeId: $employeeId
  ) {
    success
    error
    data {
      employeeId
      firstName
      lastName
      phoneNumber
      email
      driversLicenseNum
      driversLicenseExp
      isLicenseSelected
      driversLicenseImageUrl
      employeePictureUrl
      source
      identificationNumber
    }
  }
}
`;

export default function Employees(): React.ReactElement {
  const [employees, setEmployees] = useState<EmployeeInfo[]>([]);
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');

  const { loading } = useQuery(GET_EMPLOYEE, {
    fetchPolicy: 'cache-and-network',
    onError: (e) => {
      setAlert(e.message);
      setAlertColor('red');
    },
    onCompleted: (d) => {
      if (!d.getEmployee.success) {
        setAlert(d.getEmployee.error);
        setAlertColor('red');
      } else {
        setEmployees(d.getEmployee.data);
      }
    },
  });

  return (
    <div className={style.container}>
      <div className={style.box}>
        <div className={`${style.infoBox} white`}>
          <div className={`${style.subHeader} mainColor`}>
            <div className={style.subHeaderLeft}>
              <div className={`${style.circle} white`} />
              <h1 className={`${style.title} textColorWhite`}>
                Employees
              </h1>

            </div>
            <div className={style.subHeaderRight}>
              <Link to={{
                pathname: '/vendor/popup/add-employee',
              }}
              >
                <GenericButton
                  color="yellow"
                  size="medium"
                  title="Add employee +"
                  type="button"
                />
              </Link>

            </div>
          </div>
          <div className={style.mainContent}>
            <GenericAlert
              color={alertColor}
              title={alert}
              hidden={!alert}
            />
            <div className={style.tableBox}>
              <table width="100%" cellSpacing="0px" className={style.table}>
                <tbody>
                  {loading ? (
                    <LoadingDiamonds
                      title="Loading Employees"
                    />
                  ) : (
                    <EmployeesGrid
                      data={employees}
                    />
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
