import { gql, useMutation } from '@apollo/client';
import React, { ReactElement, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import alertPic from '../../../assets/alert.svg';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import { PopUp } from '../../../components/popUp/popUp';
import style from './deleteDocument.module.css';

export const DELETE_COMPANY_DOCUMENT_MUTATION = gql`
  mutation DeleteCompanyDocument(
    $documentId: String!,
  ){
    deleteUserDocumentById (
      documentId: $documentId
    ) {
      success
      error
      data {
        documentId
        userId
        userDocumentName
        documentUrl
      }
    }
  }
`;

export default function DeleteDocument(): ReactElement {
  const history = useHistory();
  const { documentId } = useParams<{ documentId: string }>();
  const [alert, setAlert] = useState<string>('');

  const [doDelete] = useMutation(DELETE_COMPANY_DOCUMENT_MUTATION, {
    onError: (e) => {
      setAlert(e.message);
    },
    onCompleted: (d) => {
      if (d.deleteUserDocumentById.success) {
        history.goBack();
      } else {
        setAlert(d.deleteUserDocumentById.error);
      }
    },
  });

  return (
    <PopUp
      title="Delete Document"
      close={() => history.goBack()}
    >
      <div className={style.box}>
        <div className={style.alertBox}>
          <img src={alertPic} alt="" className={style.alertImg} />
          <h2>Warning:</h2>
          <h4 className={style.text}> Are you sure you want to delete the document?</h4>
        </div>
        <GenericAlert
          color="red"
          title={alert}
          hidden={!alert}
        />
        <div className={style.btnBox}>
          <button onClick={() => history.goBack()} className={style.cancel}>
            Cancel
          </button>
          <button
            onClick={() => doDelete({ variables: { documentId } })}
            className={style.delete}
          >
            Delete
          </button>
        </div>

      </div>
    </PopUp>
  );
}
