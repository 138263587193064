import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import style from './UsStateSelect.module.css';

const statesList = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas	',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'Washington DC',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

type UseStateSelectProps = {
  title: string,
  value: string,
  changeHandler: (e: React.ChangeEvent<HTMLSelectElement>) => void,
  htmlFor: string,
}

export default function UsStateSelect(props: UseStateSelectProps) :ReactElement {
  const {
    title, value, changeHandler, htmlFor,
  } = props;

  if (statesList.length < 50) {
    console.error(`Warning: there is a state missing in components/UsStateSelect.\
      Found ${statesList.length}, expected at least 50`);
  }

  const { t } = useTranslation();

  return (
    <label htmlFor={htmlFor} className={`${style.label} textColorMedium`}>
      {title}
      <select
        value={value}
        id={htmlFor}
        onChange={changeHandler}
        className={`${style.select} `}
      >
        <option hidden value="">{t('Select Your State')}</option>
        {statesList.map((s: string) => (
          <option key={s} value={s}>
            {s}
          </option>
        ))}
      </select>
    </label>
  );
}
