import {
  QueryHookOptions, QueryResult, gql, useQuery,
} from '@apollo/client';
import { backendResponse } from '../common_lib_front/types/backendResponse';

export const GET_EMPLOYEE = gql`
  query Query (
  $employeeId: String
) {
  getEmployee (
    employeeId: $employeeId
  ) {
    success
    error
    data {
      employeeId
      firstName
      lastName
      phoneNumber
      email
      driversLicenseNum
      driversLicenseExp
      isLicenseSelected
      source
      employeePictureUrl
      driversLicenseImageUrl
      driversLicenseExp
      identificationNumber
    }
  }
}
`;
type GET_EMPLOYEE_VARS = {
  employeeId?: string;
};
export type GET_EMPLOYEE_OBJ = Partial<{
  employeeId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  driversLicenseNum: string;
  isLicenseSelected: boolean;
  source: string;
  employeePictureUrl: string;
  driversLicenseImageUrl: string;
  driversLicenseExp: string;
  identificationNumber?: string;
}>
export type GET_EMPLOYEE_RES = {
  getEmployee: backendResponse<GET_EMPLOYEE_OBJ[]>;
}

export const useEmployee = (
  options?: QueryHookOptions<GET_EMPLOYEE_RES, GET_EMPLOYEE_VARS>,
): QueryResult<GET_EMPLOYEE_RES, GET_EMPLOYEE_VARS> => useQuery<
GET_EMPLOYEE_RES, GET_EMPLOYEE_VARS
>(GET_EMPLOYEE, options);
