import { gql, useMutation, useQuery } from '@apollo/client';
import React, { ReactElement, useContext, useState } from 'react';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import LoadingDiamonds from '../../../common_lib_front/components/loadingDiamonds/loadingDiamonds';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';
import {
  CompanyInfo as TCompanyInfo, companyInfoRequiredFields, newCompanyInfo, vendorInfoConfigType,
} from '../../../common_lib_front/types/companyInfo';
import CompanyInformationForm from '../../../components/companyInformationForm/companyInformationForm';
import style from './companyProfile.module.css';

const vendorInfoConfig: vendorInfoConfigType = {
  businessLicenseNumber: 'required',
  firstName: 'required',
  lastName: 'required',
  email: 'required',
  businessName: 'required',
  businessPhone: 'required',
  cellPhone: 'required',
  businessAddress: 'required',
  alternatePhone: 'optional',
  emergencyContactName: 'optional',
  businessType: 'optional',
};

export const GET_COMPANY_PROFILE = gql`
  query getCompanyProfile {
      getCompanyProfile {
      success
      error
      data {
        firstName
        lastName
        email
        companyName
        businessLicenseNumber
        altPhoneNumber
        businessPhone
        address
        city
        state
        zipCode
        emergencyContactName
        emergencyPhoneNumber
        businessType
    }
  }
}
`;
export type GET_COMPANY_PROFILE_RES = {
  getCompanyProfile: backendResponse<{
    firstName: string;
    lastName: string;
    email: string;
    companyName: string;
    businessLicenseNumber: string;
    altPhoneNumber: string;
    businessPhone: string;
    address: string;
    city: string;
    state: string;
    zipCode: string;
    emergencyContactName: string;
    emergencyPhoneNumber: string;
    businessType: string;
  }>;
}

export const EDIT_COMPANY_PROFILE = gql`
  mutation editCompanyProfile (
    $data: CompanyInfoInput!
) {
  editCompanyProfile (
    data: $data
  ) {
    success
    error
  }
}
`;

const REGISTER_COMPANY_PROFILE = gql`
mutation registerCompanyProfile(
  $data: CompanyInfoInput!
) {
  registerCompanyProfile (
    data: $data
  ) {
    success
    error
  }
}
`;

export default function CompanyProfile(): ReactElement {
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green' | undefined>(undefined);
  const [profile, setProfile] = useState<TCompanyInfo>(newCompanyInfo());
  const [isRegister, setIsRegister] = useState<boolean>(false);
  const { communityId, featuresConfig } = useContext(CommunityContext);
  const hideAlert = () => {
    setTimeout(() => {
      setAlert('');
      setAlertColor(undefined);
    }, 2000);
  };
  const { loading } = useQuery(GET_COMPANY_PROFILE, {
    fetchPolicy: 'cache-and-network',
    onError: (e) => {
      setAlert(e.message);
      setAlertColor('red');
    },
    onCompleted: (d) => {
      if (!d.getCompanyProfile.success) {
        setAlert(d.getCompanyProfile.error);
        setAlertColor('red');
      } else {
        setProfile(d.getCompanyProfile.data);
      }
      if (!d.getCompanyProfile.data) {
        setIsRegister(true);
      }
    },
  });

  const [doSubmit, { loading: submitting }] = useMutation(EDIT_COMPANY_PROFILE, {
    onError: (e) => {
      setAlert(e.message);
      setAlertColor('red');
    },
    onCompleted: (d) => {
      if (!d.editCompanyProfile.success) {
        setAlert(d.editCompanyProfile.error);
        setAlertColor('red');
      } else {
        setAlert('Data has been saved');
        setAlertColor('green');
        hideAlert();
      }
    },
  });

  const [doRegisterCompany, { loading: registering }] = useMutation(REGISTER_COMPANY_PROFILE, {
    onError: (e) => {
      setAlert(e.message);
      setAlertColor('red');
    },
    onCompleted: (d) => {
      if (!d.registerCompanyProfile.success) {
        setAlert(d.registerCompanyProfile.error);
        setAlertColor('red');
      } else {
        setAlert('Data has been saved');
        setAlertColor('green');
        setIsRegister(false);
        hideAlert();
      }
    },
  });

  // * Could use submitting to disable Save Changes button.
  // console.log(submitting);

  const submitHandler = () => {
    setAlert('');
    if (companyInfoRequiredFields.some((k) => !profile?.[k])) {
      setAlert('Please fill out the required info');
      setAlertColor('red');
    } else {
      const variables = {
        data: {
          companyName: profile?.companyName,
          altPhoneNumber: profile?.altPhoneNumber || '',
          businessPhone: profile?.businessPhone,
          emergencyPhoneNumber: profile?.emergencyPhoneNumber,
          address: profile?.address,
          city: profile?.city,
          state: profile?.state,
          zipCode: profile?.zipCode,
          businessType: profile?.businessType,
          firstName: profile.firstName,
          lastName: profile.lastName,
          email: profile.email,
        },
      };
      if (!featuresConfig?.vendor?.businessTypeRequired) {
        delete variables.data.businessType;
      }
      if (!isRegister) {
        doSubmit({
          variables,
        });
      } else {
        doRegisterCompany({
          variables,
        });
      }
    }
  };

  return (
    <>
      <div className={`${style.subHeader} mainColor `}>
        <div className={`${style.circle} white `} />
        <h1 className={`${style.title} textColorWhite `}>
          Company Profile
        </h1>
      </div>
      {loading ? (
        <LoadingDiamonds
          title="Loading Company Profile"
        />
      ) : (
        <div className={style.form}>
          <form
            className={style.innerForm}
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
              e.preventDefault();
              submitHandler();
            }}
          >
            <div className={style.flexBox}>
              <div>
                <div className={style.alertMsg}>
                  <GenericAlert
                    color={alertColor}
                    title={alert}
                    hidden={!alert}
                  />
                </div>
                <CompanyInformationForm
                  data={profile}
                  setData={setProfile}
                  vendorInfo={vendorInfoConfig}
                  readOnlyFields={['businessType']}
                  autofillFromUser
                />
                <div className={style.btn_small}>
                  <GenericButton
                    size="large"
                    color="blue"
                    title="Save Changes"
                    type="submit"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
}
