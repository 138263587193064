import { gql, useMutation } from '@apollo/client';
import React, {
  useContext, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router-dom';
import { ReactComponent as RemoveImg } from '../../../assets/close.svg';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import FileInputWithMetaData, { fileWithMetaDataType } from '../../../common_lib_front/components/fileInputWithMetaData/fileInputWithMetaData';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import SpecialInstruction from '../../../common_lib_front/components/specialInstruction/specialInstruction';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';
import { backendClient } from '../../../common_lib_front/utilities/BackendAPI';
import { useDocumentConfigs } from '../../../hooks/useDocumentConfigs';
import { useGetRegistration } from '../../../hooks/useRegistration';
import { useSetRegistrationStep } from '../../../hooks/useSetRegistrationStep';
import RegistrationNavHeader from '../registrationNavHeader/registrationNavHeader';
import style from './uploadDocuments.module.css';

// const STEP_NUMBER = 4;

// const EDIT_STEP = gql`
//   mutation editStep(
//     $registrationId: String!,
//   ) {
//     editRegistrationStepNumber (
//       registrationId: $registrationId,
//       stepNumber: ${STEP_NUMBER},
//     ) {
//       success
//       error
//     }
//   }
// `;

const UPLOAD_COMPANY_DOCUMENT_MUTATION = gql`
  mutation UploadCompanyDocument(
    $fileName: String!,
    $userDocumentName: String!,
    $documentType: String!,
    $documentConfigId: String,
    $documentExpiration: DateTime,
    $file: String!,
  ){
    addUserDocument (
      fileName: $fileName,
      documentType: $documentType,
      userDocumentName: $userDocumentName,
      documentConfigId: $documentConfigId,
      documentExpiration: $documentExpiration,
      file: $file,
    ) {
      success
      error
      data {
        documentId
        userId
        fileName
        userDocumentName
        documentUrl
        documentConfigId
      }
    }
  }
`;
type UPLOAD_COMPANY_DOCUMENT_MUTATION_VARS = {
  fileName: string;
  userDocumentName: string;
  documentConfigId?: string;
  documentExpiration?: string | null;
  file?: string;
  documentType: string;
}
type UPLOAD_COMPANY_DOCUMENT_MUTATION_RES = {
  addUserDocument: backendResponse<{
    documentId: string;
    userId: string;
    fileName: string;
    userDocumentName: string;
    documentUrl: string;
    documentInfoId: string;
  }>;
}

const DELETE_COMPANY_DOCUMENT_MUTATION = gql`
  mutation DeleteCompanyDocument(
    $documentId: String!,
  ) {
    deleteUserDocumentById (
      documentId: $documentId
    ) {
      success
      error
    }
  }
`;
type DELETE_COMPANY_DOCUMENT_MUTATION_VARS = {
  documentId: string;
}
type DELETE_COMPANY_DOCUMENT_MUTATION_RES = {
  deleteUserDocumentById: backendResponse<undefined>
}

function makeid(length = 15) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

type fileElemInfo = fileWithMetaDataType & {
  errorMessage?: string,
  successMessage?: string,
  uploaded: boolean,
  key: string,
}

const emptyFileData: () => fileElemInfo = () => ({
  documentConfigId: '',
  documentType: '',
  userDocumentName: '',
  fileName: '',
  file: '',
  uploaded: false,
  key: makeid(),
  noExpirationDate: false,
});

const specialInstructionCommunities = ['oceanlakes', 'isleworth'];

export default function UploadDocument(): React.ReactElement {
  const [alert] = useState<string>('');
  const [redirect, setRedirect] = useState<string>('');
  const { registrationId } = useParams<{ registrationId?: string }>();

  const [file, setFile] = useState<fileElemInfo>(emptyFileData());
  const [uploadedFiles, setUploadedFiles] = useState<fileElemInfo[]>([]);
  const { communityId } = useContext(CommunityContext);

  const { t } = useTranslation();

  const [doDeleteDoc] = useMutation<
    DELETE_COMPANY_DOCUMENT_MUTATION_RES, DELETE_COMPANY_DOCUMENT_MUTATION_VARS
  >(DELETE_COMPANY_DOCUMENT_MUTATION);

  const { data: regData } = useGetRegistration({
    registrationId: registrationId || '',
  }, {
    fetchPolicy: 'network-only',
  });

  const { data: documentTypesData } = useDocumentConfigs({
    fetchPolicy: 'network-only',
  });

  const [doEditStep] = useSetRegistrationStep({
    onError: (err) => console.warn(err),
  });

  const submitHandler = async () => backendClient.mutate<
    UPLOAD_COMPANY_DOCUMENT_MUTATION_RES, UPLOAD_COMPANY_DOCUMENT_MUTATION_VARS
  >({
    mutation: UPLOAD_COMPANY_DOCUMENT_MUTATION,
    variables: {
      documentExpiration: file.documentExpiration || null,
      documentConfigId: file.documentConfigId,
      fileName: file.fileName,
      userDocumentName: file.userDocumentName,
      documentType: file.documentType || '',
      file: file.file,
    },
  })
    .then((res) => {
      setUploadedFiles((prev) => ([
        ...prev,
        {
          ...file,
          documentId: res?.data?.addUserDocument.data?.documentId,
          uploaded: !!res?.data?.addUserDocument.success,
          errorMessage: !res?.data?.addUserDocument.success
            ? res?.data?.addUserDocument.error || 'Something went wrong. This file could not be uploaded.'
            : undefined,
          successMessage: res?.data?.addUserDocument.success
            ? 'File uploaded successfully'
            : undefined,
        },
      ]));
      setFile(emptyFileData());
    })
    .catch((err) => {
      console.warn(err);
      setFile((prev) => ({
        ...prev,
        errorMessage: 'Something went wrong. This file could not be uploaded',
      }));
    });

  if (redirect) {
    return (
      <Redirect to={redirect} />
    );
  }

  const displayTitleText = () => {
    if (['isleworth'].includes(communityId)) {
      return (
        <div>
          <p>
            Required Documents:
            {' '}
          &nbsp;
            <u>
              All vendors are required to upload a Business License or articles of incorporation.
            </u>
            {' '}
            &nbsp;
            Additionally, Animal and Wildlife Removal, Construction,
            Arbor / Tree Service, Landscape / Lakescape, and Valet Companies are required
            to upload copies of their Certificates of Insurance (proof of liability insurance
            and workers compensation).
          </p>
        </div>
      );
    }
    if (['sipoa'].includes(communityId)) {
      return (
        <div>
          <p>
            Please upload your Town of Seabrook
            Island Business License before continuing to secure online checkout.
          </p>
        </div>
      );
    }
    return t('step4_subtitle');
  };

  return (
    <div
      className={style.container}
    >
      <div className={`${style.box} white `}>
        <RegistrationNavHeader
          activeNum={3}
          title={`${t('Step 3')}: ${t('Upload Documents')}`}
          subtitle={displayTitleText()}
        />
        <GenericAlert
          color="red"
          title={alert}
          hidden={!alert}
        />
        <div className={`${style.uploadBox} ${communityId === 'isleworth' && style.isleworhtDocContainer}`}>
          <div className={style.instructionBox}>
            {specialInstructionCommunities.indexOf(communityId) >= 0 && (
              <SpecialInstruction pathName="registration.vendor-initial.documents" />
            )}
          </div>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (file.file) {
                submitHandler();
              }
            }}
            className={style.form}
          >
            <FileInputWithMetaData
              key={file.key}
              id="fileile-input"
              documentTypes={(documentTypesData?.getCommunityDocumentsConfig?.data || [])
                .map((dt) => ({
                  name: dt.name,
                  documentConfigId: dt.documentConfigId,
                  noExpirationDate: dt.noExpirationDate,
                }))}
              errorMessage={file.errorMessage}
              successMessage={file.successMessage}
              required
              value={file}
              setValue={(n) => {
                setFile((prev) => ({
                  ...prev,
                  ...n,
                }));
              }}
            />
            <div className={style.addBtnBox}>
              <div className={style.addBtn}>
                <GenericButton
                  title="Upload"
                  type="submit"
                  color="grey"
                  outline="no-border"
                  size="medium"
                />
              </div>
            </div>
          </form>
        </div>
        <div className={style.uploadedFilesBox}>
          {uploadedFiles.map((f) => (
            <div>
              {f.userDocumentName}
              {f.documentId && (
                <button
                  type="button"
                  onClick={() => {
                    if (!f.documentId) return;
                    doDeleteDoc({
                      variables: {
                        documentId: f.documentId,
                      },
                      onCompleted: () => {
                        setUploadedFiles(
                          (prev) => prev.filter((ff) => ff.documentId !== f.documentId),
                        );
                      },
                      onError: (err) => console.warn(err),
                    });
                  }}
                >
                  <RemoveImg />
                </button>
              )}
            </div>
          ))}
        </div>

        <GenericButton
          title="Continue"
          type="button"
          color="blue"
          outline="no-border"
          size="medium"
          clickHandler={async () => {
            const currentStepNum = regData?.getRegistration.data?.[0]?.stepNumber;
            if (!currentStepNum || currentStepNum < 4) {
              await doEditStep({
                variables: {
                  registrationId: registrationId || '',
                  stepNumber: 4,
                },
              });
            }
            setRedirect(`/vendor/initial-registration/${registrationId}/secure-checkout`);
          }}
        />
      </div>
    </div>
  );
}
