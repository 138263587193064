import Tippy from '@tippyjs/react';
import React, { useState, useRef } from 'react';
import { ReactComponent as ActionImg } from '../../assets/actionIcon.svg';
import style from './companyDocumentsGrid.module.css';

interface PopupCellRendererProps {
  data: {
    actions: {
      documentId?: string;
      documentUrl?: string;
    };
  };
}
/* eslint-disable react/prop-types */
const PopupCellRenderer: React.FC<PopupCellRendererProps> = ({
  data,
}) => {
  const tippyRef = useRef<Element>(null);
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);
  const onClickHandler = (option: string) => {
    hide();
    const docURL = data.actions?.documentUrl;
    if (option === 'view') {
      window.open(docURL);
    }
  };

  const openLinkHandler = (option: string) => {
    hide();
    const docId = data.actions.documentId;
    if (option === 'delete') {
      window.location.href = `../popup/delete-document/${docId}`;
    }
  };

  const dropDownContent = (
    <div className={style.menuContainer} role="menu">
      <div
        onClick={() => onClickHandler('view')}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onClickHandler('view');
          }
        }}
        role="menuitem"
        tabIndex={0} // Add tabIndex to make it focusable
        className={style.menuItem}
      >
        <span className={style.itemText}>View/Download</span>
      </div>
      <div
        onClick={() => openLinkHandler('delete')}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            openLinkHandler('delete');
          }
        }}
        role="menuitem"
        tabIndex={0} // Add tabIndex to make it focusable
        className={style.menuItem}
      >
        <span className={style.itemText}>Delete</span>
      </div>
    </div>
  );

  return (
    <Tippy
      ref={tippyRef}
      content={dropDownContent}
      visible={visible}
      onClickOutside={hide}
      allowHTML
      arrow={false}
      appendTo={document.body}
      interactive
      placement="right"
    >
      <div className={style.actionBox}>
        <button className={style.actionBtn} onClick={visible ? hide : show}>
          <p>Actions</p>
          <ActionImg />
        </button>
      </div>
    </Tippy>
  );
};

export default PopupCellRenderer;
