interface minRequirements {
  employee?: {
    identificationNumber?: string;
    driversLicenseNum?: string;
    isLicenseSelected?: boolean;
    source?: string;
  }
  vehicle?: {
    licensePrimaryDriver?: string;
  }
}

export function licensePrimaryDriverPicker<T extends minRequirements>(obj: T): string {
  let res = obj.employee?.identificationNumber || obj.employee?.driversLicenseNum;
  if (!res) {
    res = obj.vehicle?.licensePrimaryDriver;
  }
  return res || '';
}
