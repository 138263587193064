import {
  ApolloProvider,
} from '@apollo/client';
import * as Sentry from '@sentry/react';
import { LicenseManager } from 'ag-grid-enterprise';
import React from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import { CommunityContextProvider } from './common_lib_front/communityConfigs/communityContextProvider';
import { backendClient } from './common_lib_front/utilities/BackendAPI';
import { persistor } from './common_lib_front/utilities/store';
import './i18n';
import './index.css';
import reportWebVitals from './reportWebVitals';

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_KEY || '');
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={backendClient}>
      <PersistGate loading={null} persistor={persistor}>
        <CommunityContextProvider>
          <App />
        </CommunityContextProvider>
      </PersistGate>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
