import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import inptStyle from '../inputField/inputField.module.css';

export type documentTypeOption = {
  documentConfigId: string;
  name: string;
  noExpirationDate?: boolean;
};
type documentTypesDropdown = {
  title: string;
  value: string;
  changeHandler: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  htmlFor: string;
  inputStyle?: string;
  labelStyle?: string;
  smallSize?: boolean;
  largeSize?: boolean;
  lightText?: boolean;
  readonly?: boolean;
  documentTypes?: Array<documentTypeOption>;
  required?: boolean;
};

export default function DocumentTypeDropdown(props: documentTypesDropdown): ReactElement {
  const {
    title,
    value,
    changeHandler,
    htmlFor,
    readonly,
    documentTypes,
    inputStyle,
    labelStyle,
    smallSize,
    largeSize,
    lightText,
    required,
  } = props;

  /// / determine css for component
  let inptCname = inputStyle;
  let labelCname = labelStyle;
  if (smallSize) {
    labelCname = ` ${inptStyle.labelSmall} textColorMedium `;
    inptCname = ` ${inptStyle.smallSize} textColorSuperDark `;
  } else if (largeSize) {
    labelCname = ` ${inptStyle.labelLarge} textColorMedium `;
    inptCname = ` ${inptStyle.largeSize} textColorSuperDark `;
  }

  if (lightText) {
    labelCname += ' textColorSecondary ';
    inptCname += ' textColorSecondary ';
  }

  const { t } = useTranslation();

  return (
    <label htmlFor={htmlFor} className={labelCname}>
      {title}
      <div>
        <select
          disabled={readonly}
          value={value}
          id={htmlFor}
          onChange={changeHandler}
          className={inptCname}
          required={required}
        >
          <option hidden value="">
            {t('Select Document Type')}
          </option>
          {documentTypes ? (
            documentTypes.map((dt: documentTypeOption) => (
              <option key={dt.documentConfigId} value={dt.documentConfigId}>
                {dt.name}
              </option>
            ))
          ) : (
            <option value="other">Other</option>
          )}
        </select>
      </div>
    </label>
  );
}

DocumentTypeDropdown.defaultProps = {
  readonly: false,
  labelStyle: ' default_style',
  inputStyle: ' default_style',
};
