import { gql, QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { backendResponse } from '../common_lib_front/types/backendResponse';

const GET_DOCUMENT_CONFIGS = gql`
query GetCommunityDocumentsConfig {
  getCommunityDocumentsConfig {
    success
    error
    data {
      documentConfigId
      name
      status
      noExpirationDate
    }
  }
}
`;

export type documentConfigsResponse = {
  documentConfigId: string;
  name: string;
  status: string;
  noExpirationDate: boolean;
}

type GET_DOCUMENT_CONFIGS_RES = {
  getCommunityDocumentsConfig: backendResponse<documentConfigsResponse[]>;
}

type optionsType = QueryHookOptions<GET_DOCUMENT_CONFIGS_RES>;
type resType = QueryResult<GET_DOCUMENT_CONFIGS_RES>;

export const useDocumentConfigs = (
  options?: optionsType,
): resType => useQuery<
  GET_DOCUMENT_CONFIGS_RES
>(GET_DOCUMENT_CONFIGS, {
  ...options,
});
