import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router-dom';
import GenericButton from '../../../../common_lib_front/components/genericButton/genericButton';
import RegistrationNavHeader from '../../../../components/registrationNavHeader/registrationNavHeader';
import usePassSelection from './usePassSelection';

import GenericAlert from '../../../../common_lib_front/components/genericAlert/genericAlert';
import { registrationStepProps } from '../wrapper';
import style from './passSelection.module.css';

export default function PassSelection(props: registrationStepProps): React.ReactElement {
  const {
    passGroups, passTypes, isSelected, setQuantity, incQuantity, saveSelected,
  } = usePassSelection();
  const { registrationId } = useParams<{ registrationId: string }>();
  const [redirect, setRedirect] = useState<string>('');

  const { t } = useTranslation();

  const doSubmit = async () => {
    saveSelected();
    // setRedirect(`/vendor/registration/${registrationId}`);
    setRedirect(props.nextPath(registrationId));
  };

  // useEffect(() => {
  //   if (passGroups && passGroups.length < 2 && passTypes && passTypes.length === 1) {
  //     setRedirect(`/vendor/registration/${registrationId}`);
  //   }
  // }, [passGroups]);

  if (redirect) {
    return (
      <Redirect to={redirect} />
    );
  }
  return (
    <div className={style.container}>
      <div className={`${style.box} white `}>
        <RegistrationNavHeader
          title={t('Pass Selection')}
          subtitle={t('Please select passes below')}
        />

        {passGroups?.length === 0 && <GenericAlert title="No passes for this community are available at the moment" color="red" />}
        <div className={style.passContainer}>
          {passGroups
            // show only pass groups that include at least one pass type
            ?.filter((group) => (
              passTypes?.some((passInfo) => (
                group.passInfoList.includes(passInfo.passInfoId)
              ))
            ))
            ?.map((group) => (
              <div
                className={style.passGroup}
                key={group.passGroupId}
              >
                <div className={style.passGroupName}>{group.name}</div>
                {/* eslint-disable-next-line max-len */}
                {passTypes?.filter((passInfo) => group.passInfoList.indexOf(passInfo.passInfoId) !== -1).map((passInfo) => (
                  <div
                    className={style.passInfo}
                    key={passInfo.passInfoId}
                  >
                    <div className={style.passInfoName}>
                      {/* <h4 className={style.passType}>{t('Pass Type:')}</h4> */}
                      <h4 className={style.passName}>
                        {passInfo.name}
                      </h4>
                      <div className={style.passDescriptionBox}>
                        <div className={style.passDescription}>
                          {passInfo.description}
                        </div>
                      </div>
                    </div>
                    <div className={style.priceInfo}>
                      <div className={style.priceInfoLabel}>
                        {/* {t('Price: ')} */}
                        <span className={style.price}>
                          $
                          {passInfo?.priceInfo?.price}
                          .00
                          {' '}
                          {t('Per')}
                        </span>
                      </div>
                    </div>
                    <div className={style.passNumberBox}>
                      <label
                        className={style.label}
                        htmlFor="number-select"
                      >
                        {t('Quantity')}
                        <div className={style.numberInputBox}>
                          <button
                            className={style.btnLeft}
                            onClick={() => incQuantity(passInfo, -1)}
                          >
                            -
                          </button>
                          <input
                            id="number-select"
                            type="number"
                            className={style.numberInput}
                            value={passInfo.quantity}
                            onChange={
                              (event) => setQuantity(passInfo, parseInt(event.target.value, 10))
                            }
                          />
                          <button
                            className={style.btnRight}
                            onClick={() => incQuantity(passInfo, 1)}
                          >
                            +
                          </button>
                        </div>
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            ))}
        </div>

        <div className={style.btn}>
          <GenericButton
            size="large"
            color="blue"
            title={t('Continue')}
            disabled={!isSelected()}
            clickHandler={doSubmit}
          />
        </div>

      </div>
    </div>
  );
}
