import {
  MutationHookOptions, MutationTuple, gql, useMutation,
} from '@apollo/client';
import { backendResponse } from '../common_lib_front/types/backendResponse';

const EDIT_STEP_NUMBER = gql`
  mutation EditStepNumber(
    $registrationId: String!,
    $stepNumber: Float!,
  ) {
    editRegistrationStepNumber (
      registrationId: $registrationId,
      stepNumber: $stepNumber,
    ) {
      success
      error
    }
  }
`;

type EDIT_STEP_NUMBER_VARS = {
  registrationId: string;
  stepNumber: number;
}

type EDIT_STEP_NUMBER_RES = {
  editRegistrationStepNumber: backendResponse<undefined>;
}

type optionsType = MutationHookOptions<EDIT_STEP_NUMBER_RES, EDIT_STEP_NUMBER_VARS>;
type resType = MutationTuple<EDIT_STEP_NUMBER_RES, EDIT_STEP_NUMBER_VARS>;

export const useSetRegistrationStep = (options?: optionsType): resType => useMutation<
  EDIT_STEP_NUMBER_RES, EDIT_STEP_NUMBER_VARS
>(EDIT_STEP_NUMBER, {
  ...options,
});
