import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import React, {
  ReactElement,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { IdentificationTypes } from '../../common_lib/types/IdentificationTypes';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import EmployeeInfo from '../../common_lib_front/types/employeeInfo';
import { formatDate, formatDateWithMonthName } from '../../common_lib_front/utilities/formatDate';
import dataGridStyle from './employeesGrid.module.css';
import PopupCellRenderer from './popupCellRenderer';

type EmployeesGridProps = {
  data: EmployeeInfo[],
};

export default function EmployeesGrid(props: EmployeesGridProps): ReactElement {
  const {
    data,
  } = props;
  const { communityId } = useContext(CommunityContext);
  const gridRef = useRef<AgGridReact>(null); // for accessing Grid's API
  const [itemsPerPage, setItemsPerPage] = useState(25);
  // console.log('EMPLOYEES DATA: ', data);
  const employeesRowData = data.flatMap((elem) => ({
    lastName: elem.lastName ? elem.lastName : '',
    firstName: elem.firstName ? elem.firstName : '',
    phoneNumber: elem.phoneNumber ? elem.phoneNumber : '',
    email: elem.email ? elem.email : '',
    proximityCard: elem.driversLicenseScanUrl ? elem.driversLicenseScanUrl : '',
    dlExpiration: elem.driversLicenseExp || '',
    actions: elem,
    source: IdentificationTypes[elem.source as keyof typeof IdentificationTypes],
    identificationNumber: elem.identificationNumber || elem.driversLicenseNum,
  }));

  const dateFormatter = ({ value }: { value: string }) => formatDate(new Date(value));

  const filterParams = {
    comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
      const dateAsString = cellValue;
      const dateParts = dateAsString.split('-');
      const cellDate = new Date(
        Number(dateParts[0]),
        Number(dateParts[1]) - 1,
        Number(dateParts[2]),
      );
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
  };

  // DefaultColDef sets props common to all Columns
  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    wrapHeaderText: true,
  };

  const [employeesColumnDefs] = useState([
    {
      headerName: '',
      hide: true,
      field: 'bulkselect',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      editable: false,
      sortable: false,
      filter: false,
      maxWidth: 50,
    },
    {
      headerName: 'Last Name',
      field: 'lastName',
      minWidth: 150,
    },
    { headerName: 'First Name', field: 'firstName', minWidth: 150 },
    { headerName: 'Phone Number', field: 'phoneNumber', minWidth: 100 },
    {
      headerName: 'Email', field: 'email', flex: 1.5, minWidth: 150,
    },
    { headerName: 'Identification Type', field: 'source', minWidth: 150 },
    { headerName: 'Identification #', field: 'identificationNumber', minWidth: 150 },
    { headerName: 'Proximity Card', field: 'proximityCard', minWidth: 150 },
    {
      headerName: 'Expiration Date',
      field: 'dlExpiration',
      filter: 'agDateColumnFilter',
      filterParams,
      valueFormatter: dateFormatter,
      minWidth: 150,
    },
    {
      headerName: '',
      hide: false,
      pinned: true,
      field: 'actions',
      cellRenderer: PopupCellRenderer,
      cellRendererParams: {},
      editable: false,
      sortable: false,
      filter: false,
      maxWidth: 100,
    },
  ]);

  // Export to CSV
  const onBtnExport = useCallback(() => {
    const columnKeys = ['lastName', 'firstName', 'phoneNumber', 'email', 'driversLicense', 'proximityCard', 'dlExpiration'];
    const headers: string[] = [];
    const allNodes: any[] = [];
    employeesColumnDefs.slice(1).forEach((col) => {
      headers.push(col.headerName);
    });
    const dataToExport: string[] = [headers.join(',')];
    gridRef.current?.api.forEachNode((row) => {
      if (row.displayed) allNodes.push(row);
    });
    allNodes
      .sort((a, b) => a.rowIndex - b.rowIndex)
      .forEach((row) => {
        const { data: item } = row;
        const itemData: any[] = [];
        columnKeys.forEach((key: string) => {
          if (key === 'dlExpiration') {
            itemData.push(formatDateWithMonthName(new Date(item[key])));
          } else {
            itemData.push(`"${item[key]}"`);
          }
        });
        dataToExport.push(itemData.join(','));
      });
    const str = dataToExport.join('\r\n');

    const blob = new Blob([str], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const pom = document.createElement('a');
    pom.href = url;
    pom.setAttribute('download', 'export.csv');
    pom.click();
  }, []);
  // Quick filter event handler
  const onFilterTextChange = (e: { target: { value: string } }) => {
    gridRef.current?.api.setQuickFilter(e.target.value);
  };

  return (
    <div>
      <div className={dataGridStyle.topLine}>
        <input
          type="search"
          className={dataGridStyle.gridSearch}
          onChange={onFilterTextChange}
          placeholder="Search anything here .."
        />
        <div>
          <li className={dataGridStyle.instruction}>
            Please ensure you complete the employee record and upload of a valid driver license or
            identification (as required) for each employee
          </li>
          <li className={dataGridStyle.instruction}>
            Ensure the image is legible and cropped down to where only the ID is visible
            (no borders).
          </li>
        </div>

        <button className={dataGridStyle.btnExport} onClick={onBtnExport}>Export CSV</button>
      </div>
      <div className={dataGridStyle.paginationBox}>
        <label htmlFor="page-num-inpt">
          Show
          <select
            className={dataGridStyle.selectBox}
            value={itemsPerPage}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setItemsPerPage(Number.parseInt(e.target.value, 10));
            }}
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
          Per Page
        </label>
      </div>

      <div
        className="ag-theme-alpine"
        style={{
          height: '60vh',
        }}
      >
        <AgGridReact
          rowData={employeesRowData}
          columnDefs={employeesColumnDefs}
          defaultColDef={defaultColDef}
          enableCellTextSelection
          ensureDomOrder
          animateRows
          pagination
          paginationPageSize={itemsPerPage}
          // paginationAutoPageSize
          ref={gridRef}
        />
      </div>
    </div>
  );
}
