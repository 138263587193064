import { gql, useMutation } from '@apollo/client';
import React, {
  ReactElement,
  useContext,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as AddImg } from '../../../assets/add.svg';
import { ReactComponent as DelBtn } from '../../../common_lib_front/assets/del.svg';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import InputField from '../../../common_lib_front/components/inputField/inputField';
import LoadingDiamonds from '../../../common_lib_front/components/loadingDiamonds/loadingDiamonds';
import PhoneInputWrapper from '../../../common_lib_front/components/phoneInputWrapper/phoneInputWrapper';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';
import { PopUp } from '../../../components/popUp/popUp';
import style from './addEmployee.module.css';
import useAddEmployee from './useAddEmployee';
import { IdentificationTypes } from '../../../common_lib/types/IdentificationTypes';

export const ADD_EMPLOYEE = gql`
mutation addEmployee (
  $data: EmployeeInput!
) {
  addEmployee (
    data: $data
  ) {
    success
    error
    data {
      employeeId
    }
  }
}
`;
type ADD_EMPLOYEE_RES = {
  addEmployee: backendResponse<{employeeId:string}>
}
export const SCAN_LICENSE = gql`
mutation scanDriverLicense (
  $data: ScanEmployee!
) {
  scanDriverLicense (
    data: $data
  ) {
    success
    error
    data {
      firstName
      lastName
      identificationNumber
      driversLicenseExp
    }
  }
}
`;

export default function AddEmployee(): ReactElement {
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');
  const history = useHistory();
  const [adding, setAdding] = useState<boolean>(false);
  const [employeeSelect, setEmployeeSelect] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { communityId } = useContext(CommunityContext);

  const {
    employees, onAddMore, deleteOne, edit, errorStrings, onSubmit,
  } = useAddEmployee();

  const [doAddEmployee] = useMutation<ADD_EMPLOYEE_RES>(ADD_EMPLOYEE);
  const [doScanLicense] = useMutation(SCAN_LICENSE);
  const  passFormId = sessionStorage.getItem('passFormId') || null;

  const submitHandler = async () => {
    const isValid = onSubmit();
    if (!isValid) {
      return;
    }

    try {
      setAdding(true);
      setAlert('');
      const res = await Promise.all(employees?.map((e) => doAddEmployee({
        variables: {
          data: {
            firstName: e?.firstName,
            lastName: e?.lastName,
            email: e?.email || null,
            phoneNumber: e?.phoneNumber,
            file: e?.file,
            fileName: e?.fileName,
            // driversLicenseNum: e?.driverLc,
            driversLicenseExp: e?.driverLcExp,
            isLicenseSelected: true,
            source: e?.source,
            identificationNumber: e?.identificationNumber,
          },
        },
      })));
      if (res.every((r) => r.data?.addEmployee.success)) {
        if (res && res[0]?.data?.addEmployee?.data?.employeeId) {
          sessionStorage.setItem('employeeId', res[0]?.data?.addEmployee?.data?.employeeId);
        }
        history.goBack();
      } else {
        setAlert(
          res.find((r) => Boolean(r.data?.addEmployee.error))?.data?.addEmployee.error
          || 'Something went wrong. Some or all data may not be saved.',
        );
        setAlertColor('red');
      }
    } catch (error: any) {
      setAlert(error?.message);
      setAlertColor('red');
    } finally {
      setAdding(false);
    }
  };
  const myEmpInputRef = useRef<HTMLInputElement[]>([]);

  myEmpInputRef.current = [];
  const addToEmpRefs: (el: HTMLInputElement) => void = (el) => {
    if (el && !myEmpInputRef.current.includes(el)) {
      myEmpInputRef.current.push(el);
    }
  };
  const fileToBase64 = function (file: any): Promise<string> {
    return new Promise((resolve: any) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(file);
    });
  };
  const selectForUpload = async function (event: any, employee: any, index: number) {
    if (!event?.target?.files[0]) return;
    const url = URL.createObjectURL(event.target.files[0]);
    const s = await fileToBase64(event.target.files[0]);
    edit(index, {
      ...employee, fileName: event.target.files[0].name, file: s, fileUrl: url,
    });
    // if (!employeeSelect) {
    //   setAdding(true);
    //   setLoading(true);

    //   const res = await doScanLicense({
    //     variables: {
    //       data: {
    //         file: s,
    //         fileName: event.target.files[0].name,
    //       },
    //     },
    //   });
    //   edit(index, {
    //     ...employee,
    //     fileName: event.target.files[0].name,
    //     file: s,
    //     fileUrl: url,
    //     driverLc: res?.data?.scanDriverLicense?.data?.driversLicenseNum,
    //     driverLcExp: res?.data?.scanDriverLicense?.data?.driversLicenseExp ?
    //  (new Date(res.data.scanDriverLicense.data?.driversLicenseExp)).toISOString().substring(0, 10) : '',
    //     ...res.data.scanDriverLicense.data,
    //   });
    //   setAdding(false);
    //   setLoading(false);
    // }
    // edit(index, { ...employee, file: event.target.files});
  };
  // eslint-disable-next-line no-nested-ternary
  const getButtonText = (index: number) => (
    <span>
      Upload Identification Image / Photo
    </span>
  ); return (
    <PopUp
      title="Add Employee"
      close={() => history.goBack()}
    >
      <div className={style.box}>
        <div style={{ display: 'block' }} className={style.title}>
          Vendors must complete the employee form (below) to include uploading
          a Driver License prior to applying for and receiving a pass.
        </div>
        <div className={style.box}>
          {/* <div style={{ display: 'flex', width: '100%', marginTop: '10px' }}>
            <p>An employee Identification picture is required</p>
          </div> */}
          <div className={style.completeBox}>
            {/* <input
              type="checkbox"
              checked={employeeSelect}
              onChange={() => {
                setEmployeeSelect(!employeeSelect);
              }}
              className={style.checkbox}
            />
            <p className={style.completeBoxLabel}>Other Form of Identification / Use Photo</p> */}
          </div>
        </div>

        <form
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            submitHandler();
          }}
          className={style.form}
        >
          <div>
            {adding ? (
              <div style={{ paddingBottom: '100px' }}>
                <LoadingDiamonds
                  title={`${loading ? 'Scanning' : 'Saving'} Employee(s)`}
                />
              </div>
            ) : (
              <div className={style.card}>

                {employees?.map((employee, index) => (
                  <div className={style.infoBox}>
                    <GenericAlert
                      color={alertColor}
                      title={errorStrings?.[index]}
                      hidden={!errorStrings?.[index]}
                    />

                    <div className={style.header}>
                      {`Employee ${index + 1}`}
                    </div>
                    <div className={style.employeeBox}>
                      <div className={style.delBtnDiv}>
                        <button onClick={() => deleteOne(index)} className={style.delBtn}>
                          <DelBtn />
                        </button>
                      </div>
                      <div className={style.flexBox}>
                        <div className={style.boxLeft}>
                          <div className={style.row}>
                            <div className={style.input}>
                              <input
                                // accept="image/*,.pdf,.doc,.docx,.xls,.xlsx"
                                accept="image/*"
                                hidden
                                ref={addToEmpRefs}
                                type="file"
                                name="file"
                                onChange={(e) => selectForUpload(e, employee, index)}
                              />
                            </div>
                            <div className={style.rowName}>
                              <div className={style.inputShort}>
                                <InputField
                                  smallSize
                                  highlightOnFocus
                                  htmlFor={`f-name-inpt-${index}`}
                                  labelTitle="First Name *"
                                  inputType="text"
                                  inputValue={employee?.firstName || ''}
                                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    edit(index, { ...employee, firstName: e.target.value });
                                  }}
                                />
                              </div>
                              <div className={style.inputShort}>
                                <InputField
                                  smallSize
                                  highlightOnFocus
                                  htmlFor={`l-name-inpt-${index}`}
                                  labelTitle="Last Name *"
                                  inputType="text"
                                  inputValue={employee?.lastName || ''}
                                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    edit(index, { ...employee, lastName: e.target.value });
                                  }}
                                />
                              </div>
                            </div>

                            <div className={style.input}>
                              <PhoneInputWrapper
                                smallStandardInput
                                inputTitle="Phone Number"
                                value={employee?.phoneNumber || ''}
                                changeHandler={(val: string) => {
                                  edit(index, { ...employee, phoneNumber: val });
                                }}
                              />
                            </div>

                          </div>
                          <div className={style.input}>
                            <InputField
                              smallSize
                              highlightOnFocus
                              htmlFor={`email-inpt-${index}`}
                              labelTitle="Email"
                              inputType="email"
                              inputValue={employee?.email || ''}
                              changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                                edit(index, { ...employee, email: e.target.value });
                              }}
                            />
                          </div>
                          {/* {employeeSelect && ( */}
                          <div className={style.input}>
                            <InputField
                              smallSize
                              highlightOnFocus
                              htmlFor={`identification-number-inpt-${index}`}
                              labelTitle="Identification Number (e.g. Driver License, Passport, etc.) "
                              inputType="identificationNumber"
                              inputValue={employee?.identificationNumber || ''}
                              changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                                edit(index, {
                                  ...employee,
                                  identificationNumber: e.target.value,
                                });
                              }}
                            />
                          </div>
                          {/*  )} */}
                          <div className={employeeSelect ? style.rowName : ''}>
                            {employee.source !== 'other'
                              && (
                                <div className={style.inputShort}>
                                  <div className={style.input}>
                                    <InputField
                                      smallSize
                                      highlightOnFocus
                                      htmlFor={`driverLcExp-inpt-${index}`}
                                      labelTitle="Expiration Date"
                                      inputType="date"
                                      inputValue={employee?.driverLcExp || ''}
                                      changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        edit(index, { ...employee, driverLcExp: e.target.value });
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                            {/* {employeeSelect && ( */}
                            <div className={style.input}>
                              <span className={style.labelFont}>Source</span>
                              <div className={style.selectBox}>
                                <select
                                  onChange={(event) => edit(index, {
                                    ...employee,
                                    source: event.target.value,
                                  })}
                                  value={employee?.source || ''}
                                  className={style.select}
                                >
                                  <option selected disabled hidden>
                                    {' '}
                                  </option>
                                  {
                                    Object.entries(IdentificationTypes).map(
                                      ([key, val]) => <option value={key}>{val}</option>,
                                    )
                                  }

                                </select>
                              </div>
                            </div>
                            {/* )} */}
                          </div>

                          {/* {!employeeSelect && (
                            <div className={style.input}>
                              <InputField
                                smallSize
                                highlightOnFocus
                                htmlFor={`driverLc-inpt-${index}`}
                                labelTitle="DL Number"
                                inputType="driverLc"
                                inputValue={employee?.driverLc || ''}
                                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  edit(index, { ...employee, driverLc: e.target.value });
                                }}
                              />
                            </div>
                          )} */}

                        </div>
                        <div className={style.boxRight}>
                          {employee?.fileUrl
                            ? (
                              <img
                                width="100%"
                                height="auto"
                                alt="Not available"
                                src={employee?.fileUrl}
                                className={style.img}
                              />
                            )
                            : (
                              <div className={style.noImage}>
                                <p>Select picture</p>
                              </div>
                            )}
                          {/* <img width={500} height={350} src={employee?.fileUrl} /> */}
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              myEmpInputRef.current[index].click();
                            }}
                            className={style.uploadeBtn}
                          >
                            {getButtonText(index)}
                          </button>
                        </div>
                      </div>
                      <div className={style.bottomMessage}>
                        <span>* are required fields</span>
                      </div>

                    </div>
                    <div />
                  </div>
                ))}
              </div>
            )}
            {!passFormId &&
              <div className={style.addBtnBox}>
               <div className={style.addBtn}>
                 <GenericButton
                   icon={() => (<AddImg className={style.addBtnImg} />)}
                   title="Add More"
                   type="button"
                   clickHandler={onAddMore}
                 />
               </div>
              </div>
            }
           
          </div>
          <div>
            <GenericAlert
              color={alertColor}
              title={alert}
              hidden={!alert}
            />
          </div>
          <div className={style.btnBox}>
            <div className={style.btn}>
              <GenericButton
                size="large"
                color="blue"
                title="Save Employee(s)"
                type="submit"
                disabled={adding}
              />
            </div>
          </div>
        </form>
      </div>
    </PopUp>
  );
}
