import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ReactComponent as EditImg } from '../../assets/edit.svg';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import { PassPrice } from '../../common_lib_front/hooks/useOrderPrice';
import PassInfo from '../../common_lib_front/types/passInfo';
import { formatDayWithDayName } from '../../common_lib_front/utilities/formatDate';
import usePassInfo from '../../hooks/usePassInfo';
import style from './passSummary.module.css';

type PassSummaryProps = {
  showVehicleInfo: boolean;
  showEditBtn: boolean;
  data: PassInfo;
  // allPasses: PassInfo[];
  idx: number;
  editLink?: string;
  priceInfo?: PassPrice;
  isRenewal?: boolean;
}

export const PassSummary = (props: PassSummaryProps): React.ReactElement => {
  const {
    idx, showEditBtn, data, showVehicleInfo, editLink, priceInfo, isRenewal,
  } = props;

  const history = useHistory();
  const { t } = useTranslation();

  const passInfo = usePassInfo({ passInfoId: data.passInfoId });

  return (
    <div className={style.box}>
      <div className={style.titleBox}>
        <h6 className={`${style.title} textColorDark `}>
          {t(`${idx + 1}. ${passInfo?.name}`)}
        </h6>
        <div className={style.editBtn}>
          {showEditBtn
            ? (
              <GenericButton
                shape="circle"
                icon={() => (<EditImg className={style.circleImg} />)}
                title=""
                clickHandler={() => {
                  if (editLink) {
                    history.push(editLink);
                  }
                }}
              />
            )
            : <></>}

        </div>

      </div>
      <div className={style.container}>
        <div className={style.tableBox}>
          <table className={style.table}>
            <tr className={style.trBox}>
              <th className={`${style.tHead} textColorDark `}>{t('Start Date')}</th>
              <th className={`${style.tHead} textColorDark `}>{t('Valid Through')}</th>
            </tr>
            <tr className={style.trBox}>
              <td className={`${style.tHead}  `}>
                {formatDayWithDayName(data.startDate)}
              </td>
              <td className={`${style.tHead} `}>
                {formatDayWithDayName(isRenewal
                  ? (() => {
                    const ed = new Date(data.endDate);
                    ed.setFullYear(ed.getFullYear() + 1);
                    return ed;
                  })()
                  : data.endDate)}
              </td>
            </tr>
          </table>
        </div>
        {showVehicleInfo
          ? (
            <div className={style.vehicleInfoBox}>
              <div className={style.titleBox}>
                <h6 className={`${style.title} textColorDark `}>{t('Vehicle Information')}</h6>

              </div>
              <div className={style.tableBox}>
                <table className={style.table}>
                  <tr className={style.trBox}>
                    {data?.vehicle?.make ? <th className={`${style.tHead} textColorDark `}>{t('Vehicle Make')}</th> : <></>}
                    {data?.vehicle?.model ? <th className={`${style.tHead} textColorDark `}>{t('Vehicle Model')}</th> : <></>}
                  </tr>
                  <tr className={style.trBox}>
                    {data?.vehicle?.make ? <td className={`${style.tHead} `}>{data?.vehicle?.make}</td> : <></>}
                    {data?.vehicle?.model ? <td className={`${style.tHead}`}>{data?.vehicle?.model}</td> : <></>}
                  </tr>
                  <tr className={style.trBox}>
                    {data?.vehicle?.type ? <th className={`${style.tHeadSpace} textColorDark `}>{t('Vehicle Type')}</th> : <></>}
                    {data?.vehicle?.color ? <th className={`${style.tHeadSpace} textColorDark `}>{t('Vehicle Color')}</th> : <></>}
                  </tr>
                  <tr className={style.trBox}>
                    {data?.vehicle?.type ? <td className={`${style.tHead}`}>{data?.vehicle?.type}</td> : <></>}
                    {data?.vehicle?.color ? <td className={`${style.tHead}`}>{data?.vehicle?.color}</td> : <></>}
                  </tr>
                  <tr className={style.trBox}>
                    {data?.vehicle?.plateNumber ? <th className={`${style.tHeadSpace} textColorDark `}>{t('License Plate')}</th> : <></>}
                    {data?.vehicle?.licensePlateState ? <th className={`${style.tHeadSpace} textColorDark `}>{t('License Plate State')}</th> : <></>}
                  </tr>
                  <tr className={style.trBox}>
                    {data?.vehicle?.plateNumber ? <td className={`${style.tHead}`}>{data?.vehicle?.plateNumber}</td> : <></>}
                    {data?.vehicle?.licensePlateState ? <td className={`${style.tHead}`}>{data?.vehicle?.licensePlateState}</td> : <></>}
                  </tr>
                  <tr className={style.trBox}>
                    {data?.vehicle?.primaryDriverName ? <th className={`${style.tHeadSpace} textColorDark `}>{t('Primary Driver Name')}</th> : <></>}
                    {data?.vehicle?.licensePrimaryDriver ? <th className={`${style.tHeadSpace} textColorDark `}>{t('Primary Driver\'s License #')}</th> : <></>}
                  </tr>
                  <tr className={style.trBox}>
                    {data?.vehicle?.primaryDriverName ? <td className={`${style.tHead}`}>{data?.vehicle?.primaryDriverName}</td> : <></>}
                    {data?.vehicle?.licensePrimaryDriver ? <td className={`${style.tHead}`}>{data?.vehicle?.licensePrimaryDriver}</td> : <></>}
                  </tr>
                  <tr className={style.trBox}>
                    {data?.vehicle?.fleetNumber ? <th className={`${style.tHeadSpace} textColorDark `}>{t('Vehicle Fleet Number')}</th> : <></>}
                  </tr>
                  <tr className={style.trBox}>
                    {data?.vehicle?.fleetNumber ? <td className={`${style.tHead}`}>{data?.vehicle?.fleetNumber}</td> : <></>}
                  </tr>
                </table>
              </div>
            </div>
          )
          : <></>}
        <div className={style.addonBox}>
          <table className={style.table}>
            {passInfo?.priceInfo?.addons?.filter(
              (item: any) => data.addons.includes(item.name),
            ).map((item: any) => (
              <tr className={style.trBox}>
                <td className={`${style.tHead}  `}>
                  {item.name}
                </td>
                <td className={`${style.tHead} ${style.addonPrice}`}>
                  {!item.isFree ? `${(item.price < 0 ? '-$' : '+$') + item.price}` : '+$0'}
                </td>
              </tr>
            ))}
          </table>
        </div>
        <div className={style.priceBox}>
          <span className={style.tSubTotal}>
            Subtotal
          </span>
          <span>
            $
            {
              priceInfo?.price || 0
            }
          </span>
        </div>
      </div>
    </div>
  );
};
PassSummary.defaultProps = {
  editLink: '',
  priceInfo: undefined,
  isRenewal: false,
};

export default PassSummary;
