type ObjValidationPattern<T> = ObjValidationParent<T> | ObjValidationLeaf<T>
interface ObjValidationLeaf<T> {
  field: keyof T,
  isValid?: (val: T[keyof T]) => boolean,
  skip?: boolean,
}
interface ObjValidationParent<T> {
  combine: 'AND' | 'OR',
  fields: (ObjValidationPattern<T>)[],
  skip?: boolean,
}

function isObjValidationLeaf<T>(obj: any): obj is ObjValidationLeaf<T> {
  return Boolean(obj.field);
}
function isObjValidationParent<T>(obj: any): obj is ObjValidationParent<T> {
  return Boolean(obj.combine && obj.fields);
}

function isValidLeaf<T>(obj: T, cfg: ObjValidationLeaf<T>): boolean {
  if (cfg.skip) return true;
  if (cfg.isValid) {
    return cfg.isValid(obj[cfg.field]);
  }
  return Boolean(obj[cfg.field]);
}
function isValidParent<T>(obj: T, cfg: ObjValidationParent<T>): boolean {
  if (cfg.combine === 'AND') {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return cfg.fields.every((f) => isValidParentInner(obj, f));
  }
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return cfg.fields.some((f) => isValidParentInner(obj, f));
}
function isValidParentInner<T>(obj: T, field: ObjValidationPattern<T>): boolean {
  // is leaf
  if (isObjValidationLeaf<T>(field)) {
    return isValidLeaf(obj, field);
    // is parent
  } if (isObjValidationParent<T>(field)) {
    if (field.skip) {
      return true;
    }
    return isValidParent(obj, field);
  }
  // is neither
  throw Error('Invalid configuration');
}

export class ObjValidator<T> {
  readonly cfg: ObjValidationPattern<T>;

  constructor(cfg: ObjValidationPattern<T>) {
    this.cfg = cfg;
  }

  isValid(obj: T): boolean {
    if (isObjValidationParent<T>(this.cfg)) {
      return isValidParent(obj, this.cfg);
    } if (isObjValidationLeaf<T>(this.cfg)) {
      return isValidLeaf(obj, this.cfg);
    }
    throw Error('Invalid configuration');
  }
}
