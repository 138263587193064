import React, { useCallback, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { GET_EMPLOYEE_OBJ, useEmployee } from '../../hooks/useEmployee';
import SelectTable from '../selectTable/selectTable';

type EmployeeSelectProps = {
  id: string;
  employee?: GET_EMPLOYEE_OBJ;
  backupEmployeeId?: string;
  setEmployee: (val: GET_EMPLOYEE_OBJ | undefined) => void;
  required?: boolean;
  filter?: (emp: GET_EMPLOYEE_OBJ) => boolean;
  validityTests?: Record<string, ((employees: GET_EMPLOYEE_OBJ[]) => boolean)>;
  disabled?: boolean;
  displayPlaceholder?: string;
  saveToLocalStorage?: () => void;
  passFormId?: number;

}
export default function EmployeeSelect(props: EmployeeSelectProps): React.ReactElement {
  const {
    id,
    employee,
    setEmployee,
    required,
    filter,
    validityTests,
    backupEmployeeId,
    disabled,
    displayPlaceholder,
    saveToLocalStorage,
    passFormId
  } = props;
  const { data: employeeData } = useEmployee({
    fetchPolicy: 'cache-and-network',
  });

  const filteredEmployees = useMemo(() => (
    employeeData
      ?.getEmployee
      .data
      ?.filter((e) => (typeof filter === 'function' ? filter(e) : true))
      // ?.map((e) => newEmployeeInfo(e))
      || []
  ), [employeeData, filter]);

  const testValidity = useCallback((obj: GET_EMPLOYEE_OBJ) => {
    if (validityTests) {
      const someFailed = Object.values(validityTests).some(
        (fn) => !fn(filteredEmployees),
      );
      if (someFailed) return false;
    }
    return !!(obj.firstName || obj.lastName || obj.identificationNumber);
  }, [validityTests, filteredEmployees]);

  useEffect(() => {
    // caution to not cause infinite rerender loop
    if (backupEmployeeId && !employee?.employeeId) {
      const emp = filteredEmployees.find((em) => em.employeeId === backupEmployeeId);
      // caution to not cause infinite rerender loop
      if (emp !== employee) {
        setEmployee(emp);
      }
    }
  }, [backupEmployeeId, employee?.employeeId, setEmployee, filteredEmployees]);

  
  const handleAddVehicleClick = () => {
    
    if(passFormId || passFormId === 0){
      sessionStorage.setItem('passFormId', passFormId.toString());
    }
    
    // // Call saveToLocalStorage if it's defined
     saveToLocalStorage && saveToLocalStorage();
  };

  useEffect(() => {
    const passSessionFormId = sessionStorage.getItem('passFormId')  || '';
    const employeeId = sessionStorage.getItem('employeeId');
    
    if (employeeId && passSessionFormId && filteredEmployees && parseInt(passSessionFormId) === passFormId) {
      const employee = filteredEmployees.find((v) => v.employeeId === employeeId);
      if (employee) {
        sessionStorage.removeItem('passFormId');
        sessionStorage.removeItem('employeeId');
        setEmployee(employee);
      }
    }
  }, [filteredEmployees]);

  return (
    <SelectTable<GET_EMPLOYEE_OBJ>
      id={id}
      required={required}
      displayVal={(e) => `${e?.firstName} ${e?.lastName}`}
      displayPlaceholder={displayPlaceholder}
      value={employee}
      validityTest={testValidity}
      noHeader
      preventClear
      disabled={disabled}
      setValue={setEmployee}
      data={filteredEmployees}
      headers={[
        {
          label: 'Name',
          field: (e) => `${e.firstName} ${e.lastName}`,
        },
      ]}
      topHead={(
        <div>
          <Link to="/vendor/popup/add-employee" onClick={handleAddVehicleClick}>
            Add Employee
          </Link>
        </div>
      )}
    />
  );
}
EmployeeSelect.defaultProps = {
  employee: undefined,
  required: undefined,
  filter: undefined,
  validityTests: undefined,
  backupEmployeeId: undefined,
  disabled: false,
  displayPlaceholder: undefined,
};
