import React, { useEffect, useMemo, useState } from 'react';
import { ReactComponent as AddIcon } from '../../common_lib_front/assets/add.svg';
import { ReactComponent as DelIcon } from '../../common_lib_front/assets/del.svg';
import useContainerBlur from '../../common_lib_front/utilities/useContainerBlur';
import { GET_EMPLOYEE_OBJ, useEmployee } from '../../hooks/useEmployee';
import style from './employeeSelectList.module.css';

type TBase = Record<string, unknown>;

export type SelectTableHeaderConfig<T extends TBase> = {
  label: string;
  field: keyof T | ((val: T) => React.ReactNode);
}[];

type TAIProps = {
  headers: SelectTableHeaderConfig<TBase>;
  value: string[];
  setValue: (nVal: string[]) => void;
  id: string;
  required?: number;
  label?: string;
  addLabel?: string;
  topHead?: React.ReactElement;
  noHeader?: boolean;
  filter?: (emp: GET_EMPLOYEE_OBJ) => boolean;
  employee?: GET_EMPLOYEE_OBJ;
  backupEmployeeId?: string;
};

function keyOrFnHelper<T extends TBase, K>(
  val: T | undefined,
  displayVal: keyof T | ((v: T) => K),
  displayPlaceholder?: string,
): string | K {
  if (!val) {
    return displayPlaceholder || '';
  }
  if (['string', 'number', 'symbol'].includes(typeof displayVal)) {
    const tmp = val?.[displayVal as keyof T];
    if (typeof tmp === 'string') return tmp;
    return '';
  }
  if (typeof displayVal === 'function') {
    return displayVal(val!);
  }
  return '';
}

export default function EmployeeSelectList(props: TAIProps): React.ReactElement {
  const {
    value,
    setValue,
    id,
    required,
    label,
    addLabel,
    topHead,
    noHeader,
    headers,
    filter,
    employee,
    backupEmployeeId,
  } = props;

  const [openModaId, setOpenModaId] = useState<number>();

  // update item in array
  function update(nval: string, idx: number): void {
    const newVal = [...value];
    newVal[idx] = nval;
    setValue(newVal);
  }

  // remove item from array
  function remove(idx: number): void {
    const newVal = [...value];
    newVal.splice(idx, 1);
    setValue(newVal);
  }

  // add new item to array
  function add(): void {
    const newVal = [...value, ''];
    setValue(newVal);
  }

  // check validity
  useEffect(() => {
    // add one if array is empty
    if (value.length === 0) {
      add();
      // add one if the required length is not met
    } else if (required && value.length < required) {
      add();
    }
  }, [value.length, required]);
  const { open, containerId, setOpen } = useContainerBlur(`${id}-container`);

  const { data: employeeData } = useEmployee({
    fetchPolicy: 'cache-and-network',
  });

  const filteredEmployees = useMemo(() => (
    employeeData
      ?.getEmployee
      .data
      ?.filter((e) => (typeof filter === 'function' ? filter(e) : true))
      // ?.map((e) => newEmployeeInfo(e))
      || []
  ), [employeeData, filter]);

  function openList(idx:number) {
    setOpenModaId(idx);
    setOpen(true);
  }

  useEffect(() => {
    if (!open) {
      setOpenModaId(undefined);
    }
  }, [open]);

  return (
    <div id={containerId} className={style.wrapper}>
      {label && <label htmlFor={`${id}-text-array-${value.length - 1}`}>{label}</label>}
      <ul>
        {value.map((v, idx) => (
          <div>
            {/* eslint-disable-next-line react/no-array-index-key */}
            <li key={idx}>
              <input
                value={v}
                type="text"
                required={
                  Boolean(idx) || (typeof required === 'number' ? required > idx : false)
                }
                onChange={(e) => update(e.target.value, idx)}
                onFocus={() => openList(idx)}
              />
              {idx > 0 && <DelIcon className={style.delIcon} onClick={() => remove(idx)} />}
            </li>
            {(openModaId === idx)
              ? (
                <div className={style.dropdown}>
                  {topHead && (
                  <div className={style.dropdownHead}>
                    {topHead}
                  </div>
                  )}
                  <table className={style.dropdownTable}>
                    {!noHeader && (
                    <thead>
                      <tr>
                        {headers.map((h) => <th>{h.label}</th>)}
                      </tr>
                    </thead>
                    )}
                    {filteredEmployees.length > 0 && (
                    <tbody>
                      {filteredEmployees.map((d, index) => (
                        <tr
                          onClick={(e) => update(`${d.firstName} ${d.lastName}`, idx)}
                          className={style.selectableRow}
                        >
                          {headers.map((h) => <td>{keyOrFnHelper(d, (e) => `${e.firstName} ${e.lastName}`)}</td>)}
                        </tr>
                      ))}
                    </tbody>
                    )}
                  </table>
                  {/* {(value && !preventClear) && (
                  <div className={style.clear}>
                    <GenericButton
                      color="white"
                      title="Clear"
                      outline="small"
                      clickHandler={() => {
                        setValue(undefined);
                      }}
                    />
                  </div>
                )} */}
                  {filteredEmployees.length === 0 && (
                  <div className={style.emptyState}>
                    <p>No Options Yet...</p>
                  </div>
                  )}
                </div>
              )
              : ''}

          </div>
        ))}
      </ul>
      {/* <button type="button" onClick={add}>Add</button> */}
      { value.length <= 4

        && (
        <label htmlFor={`${id}-add-btn`} className={style.addBox}>
          <AddIcon id={`${id}-add-btn`} onClick={add} className={style.addIcon} />
          {addLabel}
        </label>
        )}
    </div>
  );
}
EmployeeSelectList.defaultProps = {
  required: 0,
  label: '',
  addLabel: 'Add',
};
