import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';

import GenericButton from '../../../../common_lib_front/components/genericButton/genericButton';
import ScormWrapper from '../../../../common_lib_front/components/scormWrapper/scormWrapper';

import RegistrationNavHeader from '../../../../components/registrationNavHeader/registrationNavHeader';

import { registrationStepProps } from '../wrapper';
import style from './communityOrientation.module.css';
import useCommunityOrientation from './useCommunityOrientation';

export function CommunityOrientation(props: registrationStepProps): React.ReactElement {
  const { nextPath } = props;
  const {
    registrationId, doCompleteHandle, documentConfig, redirect,
  } = useCommunityOrientation(props);

  const { t } = useTranslation();

  if (redirect) {
    return (
      <Redirect to={redirect} />
    );
  }

  return (
    <div className={style.container}>
      <div className={`${style.box} white `}>
        <RegistrationNavHeader
          activeNum={2}
          title={`${t('Step 2')}: ${t('Community Orientation')}`}
          subtitle={t('')}
        />
        {/* <GenericAlert
          color="red"
          title={errorMessage}
          hidden={!errorMessage}
        /> */}

        {/* {educations?.filter((e: any) => e.englishEducation).map((e: any) => (
          <div className={style.flexBox}>
            <div className={style.LMSWindow}>
              <span>
                {e.englishEducation}
              </span>
            </div>
          </div>
        ))} */}
        <div className={style.flexBox}>
          <div className={style.LMSWindow}>
            <ScormWrapper
              portal="vendor"
              continueHandler={() => {
                doCompleteHandle('education1');
              }}
            />
          </div>
        </div>

        <div className={style.btn}>
          <Link to={nextPath(registrationId)}>
            <GenericButton
              size="large"
              color="blue"
              title="Continue"
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default CommunityOrientation;
