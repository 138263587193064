const es = {
  otherLanguage: 'english',
  'Welcome to Community Guest Registration Portal': 'Bienvenido al Portal de Registro para huéspedes de la comunidad',
  'Registration is required for all guests entering the community. ': 'Es requerido que todos los huéspedes se registren para entrar a la comunidad. ',
  'Please create an account below to begin your registration and receive your community access pass.': 'Por favor crea una cuenta abajo para iniciar su registro y permiso para acceder la comunidad.',
  'Please create your account': 'Por favor crea tu cuenta',
  'First Name': 'Nombre',
  'Last Name': 'Apellido',
  'Email Address': 'Correo Electrónico',
  Password: 'Contraseña',
  'Verify Password': 'Verifica contraseña',
  'Do you already have an account?': '¿Ya tienes una cuenta?',
  'Create Account': 'Crea una Cuenta',
  Login: 'Acceso',
  'If you have previously created an account, please login below to register a new visit and receive your community access pass.': 'Si usted previamente creó una cuenta, por favor inicie sesión para registrar una visita nueva y para recibir su permiso de acceso a la comunidad.',
  'Please log in to your account': 'Por favor inicie sesión en su cuenta',
  'Don\'t have an account?': '¿No tienes una cuenta?',
  'Forgot Password?': '¿Olvidó su Contraseña?',
  'Enter your email address and we\'ll send you a link to reset your password': 'Ingrese su correo electrónico y le mandaremos un enlace para restablecer su contraseña',
  Enter: 'Ingrese',
  'Access My Passes': 'Accede a mis Permisos',
  'View all your current access passes.': 'Ver todos tus permisos de acceso',
  'Manage Passes': 'Administrar Permisos',
  'Purchase New Pass': 'Compre un Permiso Nuevo',
  'Buy new Community Access Passes and update billing information.': 'Compre permisos nuevos de Acceso a la comunidad y actualice la información de pago',
  'Buy a New Pass': 'Compre un permiso nuevo',
  'My Profile': 'Mi Perfil',
  'Access and edit your profile information including name, address, and contact.': 'Accede y edita la información de tu perfil incluyendo nombre, domicilio, y contacto',
  'Edit Info': 'Edita informacion',
  'Register Rental Car': 'Registre Vehiculo Alquilado',
  'Return and register your rental car information if you were unable to before.': 'Regrese y registre la informacion de su vehiculo alquilado si no pudo hacerlo anteriormente',
  'Register My Rental Car': 'Registrar Mi Vehículo Alquilado ',
  'Review Community Rules': 'Revisa Las Reglas De La Comunidad',
  'Review community policies, rules, and procedures before entering the community.': 'Revisa las pólizas, reglas, y procedimientos antes de entrar a la comunidad',
  'Review Rules': 'Revisa las reglas',
  'My Passes': 'Mis Permisos',
  'Purchase Pass': 'Comprar Permiso',
  Help: 'Ayudar',
  'Welcome to': 'Bienvenidos a',
  registration_instructions: 'Por favor sigue las instrucciones abajo para acceder el Permiso de Acceso a la Comunidad. Debes registrar y revisar las reglas de la comunidad antes de finalizar su compra. El registro tomará aproximadamente 5 a 10 minutos en completarse. Por favor tenga en cuenta: No podrás entrar a la comunidad o acceder las comodidades sin un Permiso de Acceso a la Comunidad.',
  'Step 1': 'Primer Paso',
  'Step 2': 'Segundo paso ',
  'Step 3': 'Tercer Paso',
  'Step 4': 'Cuarto Paso',
  'Guest Registration': 'Registro de huésped ',
  'Register Your Vehicle': 'Registre su vehículo',
  'Community Orientation': 'Orientación Comunitaria',
  'Secure Checkout': 'Pago Seguro',
  'Guest & Rental Registration': 'Registro de huésped',
  'Please provide the required information below': 'Por favor provee la información requerida abajo',
  'Please completely fill out guest information!': '¡Por favor complete la información del huésped!',
  'Please completely fill out information for at least one community rental': 'Complete la información para al menos un alquiler comunitario',
  'Guest Information': 'Información del huésped',
  'Please provide the required guest information below': 'Por favor provee la información requerida abajo',
  'Cell Phone Number': 'Número de Celular',
  'Home Address': 'Domicilio',
  Country: 'País',
  City: 'Ciudad',
  State: 'Estado',
  'Select Your State': 'Seleccione su estado',
  'Zip Code': 'Código Postal',
  'Community Rental Information': 'Información de alquiler comunitario',
  'Enter rental details below for each property being registered': 'Ingrese los detalles de alquiler para cada propiedad que está registrando',
  'Add a Rental': 'Eliminar Alquiler',
  'Community Rental': 'Alquiler de la comunidad',
  'Address Or Rental Unit Search': 'Dirección o búsqueda de propiedad alquilada',
  Remove: 'Eliminar',
  'Arrival Date': 'Fecha de Llegada',
  'Departure Date': 'Fecha de Salida',
  'Number of Guests': 'Número de huéspedes',
  Continue: 'Continuar',
  address_no_match: 'Esta dirección no coincide con un alquiler conocido. Recomendamos que selecciones tu dirección si existe en la lista provista. O si no ves tu dirección de alquiler, puedes continuar con el ingresado actualmente.',
  step2_subtitle: 'Por favor, registra todos los vehículos que ingresan a la comunidad. Fallo de hacerlo resultará en multas y posible expulsión de la comunidad.',
  'Pass Details': 'Detalles de Permiso',
  'Enter pass details below for each pass being purchased': 'Ingresa los detalles abajo para cada permiso comprado',
  'Community Access Pass': 'Permiso de Acceso de la Comunidad',
  Make: 'Marca',
  Type: 'Tipo',
  'Licence Plate #': 'Número de placa',
  'Start Date': 'Fecha de inicio',
  'Valid Through': 'Válido hasta',
  'This vehicle is a rental': 'Este vehiculo esta alquilado',
  rental_prompt: 'Una vez que tenga la información de su auto de alquiler, regrese a este sistema y haga clic en el botón "Registrar auto de alquiler" para registrar su auto de alquiler y activar completamente su pase.',
  'Add a Pass': 'Agregue un permiso',
  'Save Changes': 'Guardar Cambios',
  'Vehicle Information': 'Información del Vehículo',
  Subtotal: 'Total Parcial',
  step5_subtitle: 'Por favor verifique toda la informacion presentada que este al corriente y precisa antes de someter su compra',
  'Credit Card': 'Tarjeta de Credito',
  'Name on Card': 'Nombre en tarjeta',
  'Card Number': 'Numero de tarjeta',
  'EXP. Date': 'Fecha de vencemiento',
  price_info: 'Precios en dolar Estado Unidense (EU). Permisos de acceso a la comunidad no son reembolsables. Total calculado a finalizacion de compra',
  'All major credit and debit cards are accepted': 'Se aceptan las principales tarjetas de crédito y débito',
  'Review Information': 'Revise Informacion',
  'Please verify all information below is correct': 'Por favor verifique que toda la informacion abajo este correcta',
  'Personal Information': 'Informacion personal',
  Edit: 'Editar',
  NAME: 'NOMBRE',
  ADDRESS: 'DIRECCIÓN',
  'PHONE #': 'NÚMERO DE TELÉFONO',
  'Thank you for registering at Crystal Beach!': 'Gracias por su registro con Crystal Beach!',
  'We hope you enjoy your stay!': 'Esperamos que disfrute su estancia!',
  'Order Summary': 'Resumen de Orden',
  'A confirmation email has been sent to your email along with your Community Access Pass': 'Una confirmacion se ha mandado a su correo electronico junto con su Permiso de Acceso a la comunidad',
  'Community Pass Numbers': 'Numero de Permiso a la Comunidad',
  Date: 'Fecha',
  'Purchase Total': 'Compra Total',
  'Payment Method': 'Método de pago',
  'Print Passes & Receipt': 'Imprimir Pases y Recibos',
  'Download Access Pass': 'Descargue Permiso de Acceso',
  'Terms of use': 'Términos de Uso',
  Copyright: 'Derechos de autor',
  'Powered by': 'Energizado por',
  'Privacy Policy': 'Política de privacidad',
  'Terms and Conditions': 'Términos y condiciones',
  'Incomplete Passes (Rental Cars)': 'Permisos Incompletos (Autos de alquiler)',
  'Expired Passes': 'Permisos Vencidos',
  'Click here to complete information': 'Seleccione aquí para completar',
  'Ready to Scan': 'Lista para escanear',
  'Activated Passes': 'Permisos Activos',
  View: 'Ver',
  Share: 'Compartir',
  Complete: 'Completa',
  'Plate Number': 'Numero de placa',
  'Pass #': '# de Permiso',
  'Pass Shared': 'Pase compartido',
  Recipient: 'Recipiente',
  pass_share_prompt: 'Para compartir un permiso, por favor ingrese el nombre del recipiente, numero de telefono y/o correo eletcronico.',
  'Add Recipient': 'Agregue recipiente',
  'Please verify that the above information is current and accurate.': 'Verifique que la información anterior sea actual y precisa.',
  'Reset Password': 'Restablecer Contraseña',
  'Sign Out': 'Desconectar',
  'Enter your old password': 'Ingrese su contraseña anterior',
  'Enter your new password': 'Ingrese su nueva contraseña',
  'Confirm your new password': 'Confirme su nueva contraseña',
  'Driver Information (optional)': 'Información del conductor (Opcional)',
  'Register a Rental Car': 'Registrar Vehículo de Alquiler',
  'You have': 'Tienes',
  'incomplete passes': 'permisos incompletos',
  'If you have not yet purchased a pass, please click': 'Si aún no ha comprado un pase, por favor haga clic',
  Here: 'Aquí.',
  'Current Passes': 'Permisos actuales',
  'Buy New Pass': 'Comprar Permiso Nuevo',
  'My Company Profile': 'Mi perfil de compañía',
  'My Vehicle & Passes': 'Mis Vehículos y Permisos',
  'View all current registered vehicles, tickets, and purchased passes. Edit or update vehicle information. Register additional vehicles. View historical purchases and registration information. Print digital passes or share passes with employees electronically via text message or email.': 'Mira todos los vehículos actualmente registrados, tickets, y pases adquiridos. Edita o actualiza la información de vehículos. Registra vehículos adicionales. Mira el historial de compras y registros. Imprime permisos digitales o compártelos con empleados de manera electrónica por mensaje de texto o email.',
  'Purchase Passes': 'Comprar Permisos',
  'Buy new passes and update billing information.': 'Comprar permisos nuevos y actualiza la información de pago',
  'Access and edit your company profile information including company name, address, contact info, documents, and employee info. Register new employees and upload new documents.': 'Accede y edita tu información de perfil de compañía incluyendo el nombre de compañía, dirección, información de contacto, documentos e información de empleados. Registra nuevos empleados y sube documentos nuevos.',
  'Buy Passes': 'Comprar Permisos',
  'Pass Type': 'Tipo de Permiso',
  'Pass Number': 'Número de Permiso',
  'Renew Passes': 'Renovar Permisos',
  'Click here to hide expired passes vehicle info': 'Click aquí para esconder la información de vehículo de permisos expirados',
  'Click here to view expired passes vehicle info': 'Click aquí para mostrar la información de vehículo de permisos expirados',
  'Pass Selection': 'Selección de Permisos',
  'Please select passes below': 'Por favor seleccione los permisos mostrados a continuación',
  'Register Your Company': 'Registra tu Compañía',
  'Business Name': 'Nombre de la Empresa',
  'Business Address': 'Dirección de la Empresa',
  'Business Phone Number': 'Número de teléfono de la Empresa',
  'Emergency Contact Cell Phone Number': 'Número de teléfono de contacto de emergencia',
  step4_subtitle: 'Por favor suba los documentos requeridos abajo antes de continuar al Pago Seguro',
  'Upload Documents': 'Subir Documentos',
  'Company Information': 'Información de la Compañia',
  'ACH Payment': 'Pago por ACH',
  'Payment by Check': 'Pagor por Cheque',
  'Edit Documents': 'Editar Documentos',
  'Contractors/Vendors may pay by check at the Pass Office. Gate passes are not valid until payment is rendered.': 'Contratistas/Vendedores podrían pagar con cheque en la oficina de Permiso. Los Permisos de puerta no serán validos hasta que el pago sea reflejado.',
  'Confirm Purchase': 'Confirmar Compra',
  'Thank you for registering at Seabrook Island': 'Gracias for tu registro en Seabrook Island',
  step6_subtitle: 'Esperamos que disfrutes tu estancia!',
  'A confirmation email has been sent to your email': 'Un correo de confirmación ha sido enviado a tu email',
  'along with your Community Access Pass': 'junto con tu permiso de acceso a la comunidad',
  Voucher: '',
  'Community Pass Number': 'Número de Permiso a la comunidad',
  'Welcome to the Seabrook Island Community Vendor Registration Portal': 'Bienvenido al Portal de Registro para Vendedores de la comunidad de Seabrook Island',
  'Registration is required for all vendors entering the community. If you\'ve previously created an account, please login below to register a new visit and receive your community access pass.': 'El Registro es requerido para todos los vendedores que entren a la comunidad. Si usted ha creado una cuenta previamente, por favor inicie sesión para registrar una nueva visita y recibir su permiso de acceso a la comunidad.',
  'Share & More': 'Compartir y Más',
  Download: 'Descargar',
  Print: 'Imprimir',
  'Vehicle Info': 'Información del Vehiculo',
  Renew: 'Renovar',
  'Pass Info': 'Información de Permiso',
  Status: 'Estatus',
  'Number of Passes': 'Número de Permisos',
  'Please note the following rates are applied depending on the quantity of passes you purchase:': 'Por favor considere que las siguientes tarifas son aplicadas dependiendo de la cantidad de permisos que usted compra:',
  'Annual 2 Axles - When you buy up to 3 vehicle barcodes, the price is $300.00 per barcode. For purchases of 4 barcodes, the price is $275.00 each ($1,100 total). For purchases of more than 4, the price is $275.00 for the first four, then $150.00 per barcode for each additional vehicle.': 'Annual 2 Axles - Cuando compras hasta 3 códigos de barras de vehiculo, el precio es $300.00 por código de barra. Para compras de 4 códigos de barras, el precio es $275.00 cada uno ($1100 en total). Para compras de más de 4, el precio es reducido a $150.00 por código de barras.',
  'Annual 3 Axles - When you buy up to 3 vehicle barcodes, the price is $450.00 per barcode. For purchases of 4 barcodes, the price is $425.00 each ($1,700 total). For purchases of more than 4, the price is $425.00 for the first four, then $225.00 per barcode for each additional vehicle.': 'Annual 3 Axles - Cuando compras hasta 3 códigos de barras de vehiculo, el precio es $450.00 por código de barra. Para compras de 4 códigos de barras, el precio es $425.00 cada uno ($1700 en total). Para compras de más de 4, el precio es reducido a $325.00 por código de barras.',
  'Annual 4+ Axles - When you buy up to 3 vehicle barcodes, the price is $650.00 per barcode. For purchases of 4 barcodes, the price is $625.00 each ($2,500 total). For purchases of more than 4, the price is $625.00 for the first four, then $325.00 per barcode for each additional vehicle.': 'Annual 4+ Axles - Cuando compras hasta 3 códigos de barras de vehiculo, el precio es $650.00 por código de barra. Para compras de 4 códigos de barras, el precio es $625.00 cada uno ($2500 en total). Para compras de más de 4, el precio es reducido a $325.00 por código de barras.',
  'Annual Cement - For all purchases regardless of quantity, the price is $1,200.00 per barcode.': 'Annual Cement - Para todas las compras sin importar la cantidad, el precio es $1,200.00 por código de barra.',
  'For daily passes, the rates are as follows:': 'Para permisos diarios, las tarifas son las siguientes:',
  '2 Axles - One Day: $20.00, Two Days: $40.00, Five Days: $90.00': '2 Axles - Un Día: $20.00, Dos Días: $40.00, Cinco Días: $90.00',
  'Trailer: Free': 'Trailer: Gratis',
  '3 Axles - One Day: $35.00, Two Days: $70.00, Five Days: $165.00': '3 Axles - Un Día: $35.00, Dos Días: $70.00, Cinco Días: $165.00',
  '4+ Axles - One Day: $45.00, Two Days: $90.00, Five Days: $210.00': '4+ Axles - Un Día: $45.00, Dos Días: $90.00, Cinco Días: $210.00',
  'Cement - One Day: $100.00': 'Cement - Un Día: $100.00',
  'Please note, all purchases of multiple daily passes must be for consecutive days in a single week, starting Monday through Saturday.': 'Por favor considere que todas las compras de permisos diarios deben ser en días consecutivos en una sola semana, desde el Lunes hasta el Sábado.',
  'Pass Type:': 'Tipo de Permiso:',
  'Price: ': 'Precio: ',
  'Per Vehicle': 'Por Vehículo',
  'Vehicle Make': 'Marca del Vehículo',
  'Vehicle Model': 'Modelo del Vehículo',
  'Vehicle Color': 'Color del Vehículo',
  'License Plate': 'Matrícula',
  'License Plate State': 'Estado de la Matrícula',
  'Primary Driver Name': 'Nombre del Conductor Primario',
  'Primary Driver\'s License #': 'Número de Licencia del Conductor Primario',
  'Vehicle Fleet Number': 'Número de Flota del Vehículo',
  'DO NOT BOOK A DAY PASS OVER A SUNDAY, VENDORS ARE NOT ALLOWED ENTRY ON SUNDAYS. THERE WILL BE NO REFUNDS FOR PASSES PURCHASED FOR A SUNDAY.': 'NO AGENDES UN PERMISO POR DÍA EN UN DOMINGO, LOS VENDEDORES NO TIENEN PERMITIDO ENTRAR LOS DOMINGOS. NO HABRÁ REEMBOLSOS PARA PERMISOS COMPRADOS DURANTE EL DOMINGO.',
  'Upload and/or Continue': 'Subir y/o Continuar',
  Name: 'Nombre',
  'Email address': 'Correo electrónico',
  'Business name': 'Nombre de la empresa',
  'Business address': 'Dirección de la empresa',
  CITY: 'Ciudad',
  STATE: 'Estado',
  'Zip code': 'Código postal',
  'Business phone number': 'Número telefónico de la empresa',
  'Emergency Contact Cell phone number': 'Número celular de contacto de emergencia',
};

export default es;
