import { gql, useMutation, useQuery } from '@apollo/client';
import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router-dom';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';
import { newCompanyInfo } from '../../../common_lib_front/types/companyInfo';
import CompanyInformationReview from '../../../components/companyInformationReview/companyInformationReview';
import { useGetRegistration } from '../../../hooks/useRegistration';
import { useSEtApplicationStatus } from '../../../hooks/useSetApplicationStatus';
import { useSetRegistrationStep } from '../../../hooks/useSetRegistrationStep';
import RegistrationNavHeader from '../registrationNavHeader/registrationNavHeader';
import style from './secureCheckout.module.css';
import { STEP_FOUR_QUERY, STEP_FOUR_RES, STEP_FOUR_VARS } from './useSecureCheckout';

const GET_BUSINESS_TYPES = gql`
query getBusinessTypes {
  getBusinessTypes {
    success
    data {
      name
      educationUrl
      fee
    }
    error
  }
}
`;
type GET_BUSINESS_TYPES_RES = {
  getBusinessTypes: backendResponse<{
    name: string;
    educationUrl?: string;
    fee?: number;
  }[]>
}

export default function SecureCheckout(): React.ReactElement {
  const [redirect, setRedirect] = useState<string>('');
  const { registrationId } = useParams<{ registrationId: string }>();
  const [price, setPrice] = useState(0);

  const { data: regData } = useGetRegistration({
    registrationId,
  }, {
    fetchPolicy: 'network-only',
  });
  const [doEditStep] = useSetRegistrationStep({
    onError: (err) => console.warn(err),
  });
  const [doSetApplicationStatus] = useSEtApplicationStatus({
    onError: (err) => console.warn(err),
  });
  const [doGetStripe, { loading }] = useMutation(gql`
  mutation doStripeChargeForBusinessType($registrationId: String!) {
    doStripeChargeForBusinessType(registrationId: $registrationId) {
      url
      error
      success
    }
  }`);

  const doSubmit = useCallback(async () => {
    if (loading) {
      return;
    }
    if (price > 0) {
      doGetStripe({
        variables: {
          registrationId,
        },
      }).then((d) => {
        if (d?.data?.doStripeChargeForBusinessType?.url) {
          window.location.href = d?.data?.doStripeChargeForBusinessType?.url;
        }
      }).catch();
      return;
    }
    const currentStepNum = regData?.getRegistration.data?.[0]?.stepNumber;
    if (!currentStepNum || currentStepNum < 5) {
      const stepEdit = await doEditStep({
        variables: {
          registrationId,
          stepNumber: 5,
        },
      });
    }
    const applicationEdit = await doSetApplicationStatus({
      variables: {
        registrationId,
        status: 'pending-review',
      },
    });
    setRedirect(`/vendor/initial-registration/${registrationId}/receipt`);
  }, [doEditStep, doSetApplicationStatus, doGetStripe, price]);

  const { data: companyInfoData } = useQuery<
    STEP_FOUR_RES, STEP_FOUR_VARS
  >(STEP_FOUR_QUERY, {
    variables: {
      registrationId,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });
  const { data: businessTypes } = useQuery<GET_BUSINESS_TYPES_RES>(GET_BUSINESS_TYPES);

  useEffect(() => {
    if (
      companyInfoData?.getCompanyProfile.data?.businessType
      && businessTypes?.getBusinessTypes?.data) {
      // eslint-disable-next-line max-len
      const filtered = businessTypes?.getBusinessTypes?.data.filter((t: { name: string | undefined; }) => t.name === companyInfoData?.getCompanyProfile.data?.businessType);
      if (filtered.length) {
        setPrice(filtered[0].fee || 0);
      }
    }
  }, [businessTypes, companyInfoData]);

  // const [setCompanyInfo] = useState<CompanyInfo>(newCompanyInfo());
  const { t } = useTranslation();

  if (redirect) {
    return (
      <Redirect to={redirect} />
    );
  }

  return (
    <div className={style.container}>
      <div className={`${style.box} white `}>
        <RegistrationNavHeader
          activeNum={4}
          title={`${t('Step 4')}: ${t('Review Information')}`}
          subtitle={t('step5_subtitle')}
        />
        <div className={style.flexBox}>
          <div className={style.boxLeft}>
            <CompanyInformationReview
              data={newCompanyInfo(companyInfoData?.getCompanyProfile.data)}
              editLink={`/vendor/initial-registration/${registrationId}/company-info`}
              docEditLink={`/vendor/initial-registration/${registrationId}/documents`}
              registrationId={registrationId}
            />
          </div>
          {Boolean(price) && (
            <div className={style.boxRight}>
              <div className={style.flexBox}>
                <div className={style.paymentBox}>
                  <div className={style.paymentDetails}>
                    <div className={style.totalBox}>
                      <h4>Total</h4>
                      <h4>{`$${price}`}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={style.btn}>
          <GenericButton
            size="large"
            color="blue"
            title={loading ? t('Please Wait...') : t(!price ? 'Submit Information' : 'Confirm Purchase')}
            disabled={loading}
            clickHandler={doSubmit}
          />
        </div>
      </div>
    </div>
  );
}
