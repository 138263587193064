import { gql, useQuery } from '@apollo/client';
import { useState } from 'react';
import { IspecialInstructionInfo } from '../../types/specialInstructionInfo';
import { SpecialInstructionProps } from './specialInstruction';

const GET_SPECIAL_INSTRUCTIONS = gql`
  query GetSpecialInstructions($pathName: String!) {
    getSpecialInstructions(pathName: $pathName) {
      data {
        instructions {
          text
          type
          popup {
            text
            type
            img
          }
        }
        pathName
      }
      success
      error
    }
  }
`;

export default function useSpecialInstruction(props: SpecialInstructionProps): {
  instructionsData: Array<IspecialInstructionInfo>;
} {
  const { pathName, instructions } = props;
  const [instructionsData, setInstructions] = useState<Array<IspecialInstructionInfo>>(
    instructions || [],
  );

  useQuery(GET_SPECIAL_INSTRUCTIONS, {
    fetchPolicy: 'network-only',
    variables: { pathName },
    skip: Boolean(instructions),
    onCompleted: d => {
      if (d.getSpecialInstructions.success) {
        setInstructions(d.getSpecialInstructions?.data?.instructions);
      }
    },
    // prevent full screen errors
    onError: e => {
      console.error(e);
    },
  });

  return {
    instructionsData,
  };
}
