import { useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  gql, useMutation,
} from '@apollo/client';
import { useSetRegistrationStep } from '../../../hooks/useSetRegistrationStep';
import { useGetRegistration } from '../../../hooks/useRegistration';

/*
const GET_PASSES = gql`
  query GetPasses (
    $registrationId: String!
  ) {
    getPassesByRegistration (
      registrationId: $registrationId
    ) {
      success
      error
      data {
        passId
        passInfoId
      }
    }
  }
`;
*/

/*
const GET_EDUCATION_INFO = gql`
  query GetEducationInfo (
    $passInfoId: String!
  ) {
    getEducationInfoConfig (
      passInfoId: $passInfoId
    ) {
      success
      error
      data {
        passInfoId
        englishEducation
      }
    }
  }
`;
*/

const SET_EDUCATION_COMPLETE = gql`
  mutation editEducation(
    $registrationId: String!,
    $educationName: String!,
  ) {
    editEducation(
      registrationId: $registrationId,
      educationName: $educationName,
    ) {
      success,
      error
    }
  }
`;

export default function useCommunityOrientation(): {
  registrationId: string,
  errorMessage: string,
  educationInfos: Array<any>,
  doCompleteHandle: (name: string) => void,
  redirect: string,
  } {
  const { registrationId } = useParams<{ registrationId: string }>();
  const [errorMsg, setErrorMsg] = useState<string | null>();
  const [educationInfos] = useState<any>([]);

  const [redirect, setRedirect] = useState<string>('');

  const { data: regData } = useGetRegistration({
    registrationId,
  }, {
    fetchPolicy: 'network-only',
  });
  const [doEditStep] = useSetRegistrationStep({
    onError: (err) => console.warn(err),
  });

  const [doSetComplete] = useMutation(SET_EDUCATION_COMPLETE);

  const doCompleteHandle = async (educationName: string) => {
    doSetComplete({
      variables: {
        registrationId,
        educationName,
      },
      onError: (err) => console.warn(err),
      onCompleted: (d) => {
        if (!d.editEducation.success) {
          setErrorMsg(d.editEducation.error);
        }
      },
    });
    const currentStepNum = regData?.getRegistration.data?.[0]?.stepNumber;
    if (!currentStepNum || currentStepNum < 3) {
      await doEditStep({
        variables: {
          registrationId,
          stepNumber: 3,
        },
      });
    }
    setRedirect(`/vendor/initial-registration/${registrationId}/documents`);
  };

  return {
    registrationId,
    errorMessage: errorMsg || '',
    educationInfos: educationInfos || [],
    doCompleteHandle,
    redirect,
  };
}
