import { gql, useMutation } from '@apollo/client';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as SuccessImg } from '../../assets/radioChecked.svg';
import { ReactComponent as ErrorImg } from '../../common_lib_front/assets/del.svg';
import LoadingDiamonds from '../../common_lib_front/components/loadingDiamonds/loadingDiamonds';
import EmployeeInfo from '../../common_lib_front/types/employeeInfo';
import PassInfo from '../../common_lib_front/types/passInfo';
import CommonDialog from '../commonDialog/commonDialog';
import style from './sharePassDialog.module.css';

interface SharePassDialogProps {
  pass: PassInfo
  employees: EmployeeInfo[],
  showDialog: boolean,
  onClose: () => void
}

export const SHARE_PASS_MUTATION = gql`
mutation SharePass(
  $passId: String!,
  $employeeId: String!,
  $shareType: String!
) {
  sharePass(
    passId: $passId,
    employeeId: $employeeId,
    shareType: $shareType
  ) {
    success
    error
    data {
      sharePassId
    }
  }
}
`;

export default function SharePassDialog(props: SharePassDialogProps): React.ReactElement {
  const {
    pass,
    employees,
    showDialog,
    onClose,
  } = props;

  const [shareType, setShareType] = useState<string>('sms');
  const [shareEmployeeId, setShareEmployeeId] = useState<string>('');
  const [shareStatus, setShareStatus] = useState<string>('');
  const [shareStatusMessage, setShareStatusMessage] = useState<string>('');

  const getEmployeeById = (id: string): string => {
    // eslint-disable-next-line no-restricted-syntax
    for (const employee of employees || []) {
      if (employee.employeeId === id) {
        return `${employee.firstName} ${employee.lastName}`;
      }
    }

    return '';
  };

  const [doShare] = useMutation(SHARE_PASS_MUTATION, {
    onError: (e) => {
      setShareStatus('error');
      setShareStatusMessage(e.message);
    },
    onCompleted: (d) => {
      if (!d.sharePass.success) {
        setShareStatus('error');
        setShareStatusMessage(d.sharePass.error);
      } else {
        setShareStatus('success');
        setShareStatusMessage(`Pass shared successfully with ${getEmployeeById(shareEmployeeId)}`);
      }
    },
  });

  const doClose = () => {
    setShareStatus('');
    setShareStatusMessage('');
    onClose();
  };

  const fullSelectedEmployee = useMemo(
    () => employees.find((emp) => emp.employeeId === shareEmployeeId),
    [employees, shareEmployeeId],
  );

  return (
    <>
      <CommonDialog
        showDialog={showDialog && shareStatus === ''}
        onClose={onClose}
      >
        <div className={style.box}>
          <div className={style.selectBox}>
            <span className={style.label}>Email/SMS</span>
            <select
              onChange={(event) => setShareType(event.target.value)}
              value={shareType}
              className={style.select}
            >
              <option value="sms">SMS</option>
              {(!fullSelectedEmployee || !!fullSelectedEmployee.email) && (
                <>
                  <option value="email">Email</option>
                  <option value="email-sms">Email/SMS</option>
                </>
              )}
            </select>
          </div>
          <div className={style.selectBox}>
            <span className={style.label}>Share with</span>
            <select
              onChange={(event) => {
                setShareEmployeeId(event.target.value);
                const emp = employees.find((e) => e.employeeId === event.target.value);
                if (!emp?.email) {
                  setShareType('sms');
                }
              }}
              value={shareEmployeeId}
              className={style.select}
            >
              <option>&nbsp;</option>
              {employees
                .filter((emp) => {
                  if (shareType.includes('email')) {
                    return !!emp.email;
                  }
                  return true;
                })
                .map((employee) => (
                  <option value={employee.employeeId} key={employee.employeeId}>
                    {employee.firstName}
                    {' '}
                    {employee.lastName}
                  </option>
                ))}
            </select>
          </div>
          <div className={style.addBtnBox}>
            <Link to="/vendor/popup/add-employee">
              <button className={style.addBtn}>
                Add Employee +
              </button>
            </Link>
          </div>
          <div className={style.btnBox}>
            <button
              className={style.btnShare}
              onClick={() => {
                setShareStatus('pending');
                doShare({
                  variables: {
                    passId: pass?.passId,
                    employeeId: shareEmployeeId,
                    shareType,
                  },
                });
              }}
            >
              Share
            </button>
            <button className={style.btnCancel} onClick={() => onClose()}>Cancel</button>
          </div>
        </div>
      </CommonDialog>

      <CommonDialog
        showDialog={showDialog && shareStatus !== ''}
        onClose={onClose}
        title="Share Pass"
      >
        <div className={style.successBox}>
          {shareStatus === 'success' ? (
            <>
              <div className={style.Img}>
                <SuccessImg />
              </div>
              <p className={style.message}>
                {shareStatusMessage}
              </p>
            </>
          ) : null}

          {shareStatus === 'error' ? (
            <>
              <div className={style.Img}>
                <ErrorImg />
              </div>
              <p className={style.message}>
                {shareStatusMessage}
              </p>
            </>
          ) : null}

          {shareStatus === 'pending' ? (
            <LoadingDiamonds />
          ) : null}

          <button onClick={() => doClose()} className={style.confirmBtn}>Confirm</button>
        </div>
      </CommonDialog>
    </>
  );
}
