import React, { useEffect, useMemo, useRef, useState } from 'react';
import Dropzone from 'react-dropzone';
import { ReactComponent as CloseImg } from '../../assets/close.svg';
import DocumentTypeDropdown, { documentTypeOption } from '../documentTypesDropDown/documentTypesDropdown';
import GenericAlert from '../genericAlert/genericAlert';
import GenericButton from '../genericButton/genericButton';
import InputField from '../inputField/inputField';
import style from './addDoc.module.css';

export type fileWithMetaDataType = {
  documentId?: string;
  userDocumentName: string;
  documentConfigId: string;
  documentExpiration?: string | null;
  documentType?: string;
  file: string;
  fileName: string;
  noExpirationDate: boolean;
};

type propsType = {
  id: string;
  value: fileWithMetaDataType;
  setValue: (obj: fileWithMetaDataType) => void;
  errorMessage?: string;
  successMessage?: string;
  removeHandler?: () => void;
  required?: boolean;
  documentTypes?: Array<documentTypeOption>;
};

async function readB64(f: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result);
      }
    };
    reader.onerror = err => {
      reject(err);
    };
    reader.readAsDataURL(f);
  });
}

export default function FileInputWithMetaData(props: propsType): React.ReactElement {
  const { id, value, setValue, errorMessage, successMessage, removeHandler, required, documentTypes, } =
    props;
  const [error, setError] = useState<string>('');
  const fileNameRef = useRef<HTMLInputElement | null>(null);

  const shuffleDocumentList = useMemo(() => {
    if (documentTypes && documentTypes.length > 0) {
      // change 'Other' option position to at the end of array
      const docList = documentTypes;
      const findInd = docList.findIndex((ele) => ele.name === 'Other');
      if (findInd !== -1) { // Check if 'Other' element exists
        const otherObj = docList[findInd];
        docList.splice(findInd, 1);
        docList.push(otherObj);
        return docList;
      }
    }
    return documentTypes || [];
  }, [documentTypes]);


  useEffect(() => {
    if (value.file && value.fileName) {
      fileNameRef.current?.setCustomValidity('');
    } else {
      fileNameRef.current?.setCustomValidity('Please select file');
    }
  }, [fileNameRef, value]);

  const setDocumentType = (docID: string) => {
    if (shuffleDocumentList && shuffleDocumentList.length > 0) {
      const docFilter = shuffleDocumentList.filter((ele) => ele.documentConfigId === docID);
      if (docFilter.length > 0) {
        value.noExpirationDate = docFilter[0].noExpirationDate || false;
        if(docFilter[0].noExpirationDate === true) {
          value.documentExpiration = null;
        }
        setValue({
          ...value,
          documentConfigId: docID,
          documentExpiration: value.documentExpiration,
          documentType: docFilter[0].name || '',
        });
      }
    }
  };
  
  return (
    <div className={style.uploadBox}>
      <div style={{ display: 'flex',flexWrap:'wrap' }}>
        <DocumentTypeDropdown
          title={'Document Type'}
          htmlFor="business-type-inpt"
          value={value?.documentConfigId || ''}
          documentTypes={shuffleDocumentList || []}
          required={required || !!value.file}
          changeHandler={(e: React.ChangeEvent<HTMLSelectElement>) => {
            setDocumentType(e.target.value);
          }}
        />
        <div>
          <InputField
            highlightOnFocus
            htmlFor={`${id}-doc-name-inpt`}
            labelTitle="Document Name"
            inputType="text"
            inputValue={value.userDocumentName}
            required={required || !!value.file}
            changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
              setValue({
                ...value,
                userDocumentName: e.target.value,
              });
            }}
          />
        </div>
        {/* <InputField
          highlightOnFocus
          htmlFor={`${id}-doc-type-inpt`}
          labelTitle="Document Type"
          inputType="text"
          inputValue={value.documentType}
          required={required}
          changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
            setValue({
              ...value,
              documentType: e.target.value,
            });
          }}
        /> */}
        <div>
          <InputField
            highlightOnFocus
            htmlFor={`${id}-doc-exp-inpt`}
            labelTitle="Document Expiration"
            inputType="date"
            inputValue={value.documentExpiration || undefined}
            //required={required || !!value.file}
            required={value.noExpirationDate !== true}
            changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
              setValue({
                ...value,
                documentExpiration: e.target.value,
              });
            }}
          />
        </div>
        {removeHandler && (
          <GenericButton
            title=""
            icon={className => <CloseImg className={className} />}
            clickHandler={removeHandler}
            color="transparent"
            outline="none"
            className={style.removeBtn}
          />
        )}
      </div>
      <GenericAlert color="red" title={error} hidden={!error} />
      <GenericAlert color="red" title={errorMessage} hidden={!errorMessage} />
      <GenericAlert color="green" title={successMessage} hidden={!successMessage} />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Dropzone
          onDrop={async acceptedFiles => {
            const f = acceptedFiles[0];
            setError('');
            readB64(f)
              .then(res => {
                setValue({
                  ...value,
                  file: res,
                  fileName: f.name,
                });
              })
              .catch(() => {
                setError('Something went wrong. Could not read file.');
              });
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <section style={{ width: '100%' }} className="wfp--dropzone">
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <div
                style={{ width: '100%' }}
                {...getRootProps({ className: 'wfp--dropzone__input' })}
              >
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <input {...getInputProps()} />
                <div className={style.typeItem}>
                  <div className={style.lowerPart}>
                    <p className={style.dragTitle}>
                      {value.fileName || 'Drag File to Upload'}
                    </p>
                    <p className={style.dragSubTitle}>or</p>
                    <button type="button" className={style.browseBtn}>
                      Browse Files
                    </button>
                  </div>
                </div>
              </div>
            </section>
          )}
        </Dropzone>
        <input
          ref={fileNameRef}
          type="text"
          id={`${id}-file-name`}
          value={value.fileName}
          required={required}
          disabled
          style={{
            width: '0px',
            height: '0px',
          }}
        />
      </div>
    </div>
  );
}
