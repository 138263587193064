import { FetchResult } from '@apollo/client';
import * as Sentry from '@sentry/react';
import PassInfo from '../../../../common_lib_front/types/passInfo';
import { backendClient } from '../../../../common_lib_front/utilities/BackendAPI';
import {
  DELETE_PASS,
  DELETE_PASS_RES,
  DELETE_PASS_VARS,
  DELETE_VEHICLE,
  DELETE_VEHICLE_RES,
  DELETE_VEHICLE_VARS,
} from './registerVehicleRequests';

// delete vehicle and pass if ids are provided
// throw customer readable error
export async function deletePass(props: {
  passId?: string,
  vehicleId?: string,
  passObj?: PassInfo
}): Promise<void> {
  const { passObj, passId, vehicleId } = props;
  const pid = passId || passObj?.passId;
  const vid = vehicleId || passObj?.vehicle.vehicleId;
  let vRes: FetchResult<DELETE_VEHICLE_RES> | undefined;
  let pRes: FetchResult<DELETE_PASS_RES> | undefined;
  Sentry.captureMessage('deleting pass in registration', {
    extra: {
      props,
    },
  });

  if (vid) {
    vRes = await backendClient.mutate<DELETE_VEHICLE_RES, DELETE_VEHICLE_VARS>({
      mutation: DELETE_VEHICLE,
      variables: {
        vehicleId: vid,
      },
    });
  }

  if (pid) {
    pRes = await backendClient.mutate<DELETE_PASS_RES, DELETE_PASS_VARS>({
      mutation: DELETE_PASS,
      variables: {
        passId: pid,
      },
    });
  }

  if (vRes?.errors || pRes?.errors) {
    throw Error('Something went wrong. Data may not be deleted.');
  }
  if (pRes?.data?.deletePass.error) {
    throw Error(pRes.data.deletePass.error);
  }
  if (vRes?.data?.deleteVehicle.error) {
    throw Error(vRes.data.deleteVehicle.error);
  }
}
