import { gql, useQuery } from '@apollo/client';
import html2canvas from 'html2canvas';
import React, {
  ReactElement,
  createRef,
} from 'react';
import LoadingDiamonds from '../../common_lib_front/components/loadingDiamonds/loadingDiamonds';
import PassInfo from '../../common_lib_front/types/passInfo';
import { formatDate } from '../../common_lib_front/utilities/formatDate';
import { passCredOrNumber } from '../../common_lib_front/utilities/passCredOrNumber';
import { useGetPassImage } from '../../hooks/useGetPassImage';
import style from './passGenerator.module.css';
import GenericAlert from '../../common_lib_front/components/genericAlert/genericAlert';
import { backendResponse } from '../../common_lib_front/types/backendResponse';

/**
 * TODO: Replace this api call with a call that will generate only one image at a time
 * This end point will generate all pass images for a reservation.
 */
const GENERATE_PASS_IMAGE = gql`
query GetApplications($registrationId: String!) {
  generatePassImage(registrationId: $registrationId) {
    success
    error
    data {
      url
      passId
    }
  }
}
`;
type GENERATE_PASS_IMAGE_VARS = {
  registrationId: string;
}
type GENERATE_PASS_IMAGE_RES = {
  generatePassImage: backendResponse<{
    url: string;
    passId: string;
  }[]>
}

interface Props {
  pass: PassInfo;
}

export default function PassGenerator(props: Props): ReactElement {
  const { pass } = props;
  const { data, loading } = useGetPassImage({
    variables: {
      passId: pass.passId,
    },
  });
  if (loading) return <></>;
  return (
    <div className={style.passImgBox}>
      <img
        src={data?.getPassImage.data?.url || pass.url}
        alt="My access pass"
        className={style.passImg}
      />
    </div>
  );
}

export function downloadablePassGenerator(props: Props): [ReactElement, () => void] {
  const ref = createRef<HTMLDivElement>();
  const { pass } = props;
  return [
    (
      <div
        id="download-pass-container"
        ref={ref}
      >
        <PassGenerator
          pass={pass}
        />
      </div>
    ),
    () => {
      if (pass.url) {
        const link = document.createElement('a');
        link.download = 'access-pass.png';
        link.href = pass.url;
        link.click();
        return;
      }

      const container = document.getElementById('download-pass-container');
      if (container) {
        html2canvas(container)
          .then((canvas) => {
            const link = document.createElement('a');
            link.download = 'access-pass.png';
            canvas.toBlob((blob) => {
              if (!blob) return;
              link.href = URL.createObjectURL(blob);
              link.click();
            });
          });
      }
    },
  ];
}
