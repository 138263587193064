import React, {
  ReactElement, useContext, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as HamburgerImg } from '../../assets/hamburger.svg';
import { ReactComponent as HamburgerCloseImg } from '../../assets/hamburgerClose.svg';
import { ReactComponent as LanguageIcon } from '../../assets/language.svg';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import store, { isLogedIn as reduxIsLogedIn } from '../../common_lib_front/utilities/store';
import useMediaQuery from '../../common_lib_front/utilities/useMediaQuery';
import { useGetApplication } from '../../hooks/useGetApplication';
import UserProfileButton from '../userProfileButton/userProfileButton';
import style from './header.module.css';
import { HelpTooltip } from '../../common_lib_front/components/HelpTooltip/HelpTooltip';

export const VendorHeaderTab = (props: {
  // active: boolean,
  href: string,
  title: string,
  activeRegex: RegExp,
}): React.ReactElement => {
  const {
    href, title, activeRegex,
  } = props;
  const { pathname } = useLocation();

  let clickedStyle;
  let boxStyle;
  if (activeRegex.test(pathname)) {
    // CSS FOR WHEN CLICKED
    clickedStyle = style.clicked;
    boxStyle = 'mainColor';
  }

  return (
    <Link to={href} className={`${style.navItem} textColorMain ${clickedStyle}`}>
      {title}
      <div className={`${boxStyle} ${style.navLine}`} />
    </Link>
  );
};

export const Header = (): React.ReactElement => {
  const [isLogedin, setIsLogedin] = useState<boolean>(reduxIsLogedIn());
  useEffect(
    () => store.subscribe(() => setIsLogedin(reduxIsLogedIn())),
    [setIsLogedin],
  );
  const { pathname } = useLocation();
  const { data: applicationData } = useGetApplication();
  // this regex test for logo middle patterns. The Issue is that the useLocation hook
  // can only be used inside Route
  const allowLinks = !/(\/vendor$|\/login|\/create-account|\/forgot-password)/.test(pathname) && (
    // header links are not allowed if application is incomplete
    !applicationData?.getApplication.data
    || applicationData?.getApplication.data?.status !== 'incomplete'
    || process.env.REACT_APP_DISABLE_INITIAL_REGISTRATION === 'true'
  );
  const [showLinks, setShowLinks] = useState<boolean>(false);
  const smallScreen = useMediaQuery('(max-width: 767px)');
  const { logo, name: communityName, zendeskURLs } = useContext(CommunityContext);

  useEffect(() => {
    // this regex test for logo middle patterns. The Issue is that the useLocation hook
    // can only be used inside Route
    const res = /(\/vendor)/.test(pathname);
    setShowLinks(false);
  }, [pathname]);

  let headerStyle;
  let invisiableBox;
  let profileBtn;
  let logoBox;
  let logoLayout;
  let profileBox;
  let helpBtn;

  if (isLogedin) {
    // TODO: add style for if userProfile button is shown
    profileBtn = style.profileBtnApper;
    helpBtn = style.helpBtnApper;
  } else {
    //
    profileBtn = style.profileBtnDisapper;
    helpBtn = style.helpBtDisapper;
  }
  if (showLinks) {
    // TODO: add style for if links are shown
    headerStyle = style.header;
    logoBox = style.logoBoxSmall;
    logoLayout = style.logoLeft;
    profileBox = style.profileLogoLeft;
  } else {
    // TODO: add style for if no links are shown
    headerStyle = style.headerLogoMiddle;
    invisiableBox = style.invisibleBoxApper;
    logoBox = style.logoBoxLarge;
    logoLayout = style.logoMiddle;
    profileBox = style.profileLogoMiddle;
  }

  if (allowLinks) {
    headerStyle = style.header;
    logoBox = style.logoBoxSmall;
    logoLayout = style.logoLeft;
    profileBox = style.profileLogoLeft;
    invisiableBox = style.invisibleBoxDisApper;
  }

  const { t, i18n } = useTranslation();

  return (
    <div className={`${headerStyle} white `}>
      {smallScreen && allowLinks
        ? (
          <div className={style.hamburgerBtn}>
            <GenericButton
              title=""
              outline="none"
              color="none"
              icon={(className) => (showLinks
                ? <HamburgerCloseImg className={style.hamburgerCloseImg} />
                : <HamburgerImg className={style.hamburgerImg} />
              )}
              clickHandler={() => setShowLinks(!showLinks)}
            />
          </div>
        )
        : null}
      <div className={invisiableBox} />
      <div className={logoBox}>
        <Link to="/vendor">
          {smallScreen
            ? communityName
            : <img src={logo} alt="logo" className={logoLayout} />}
        </Link>
      </div>
      {allowLinks && (smallScreen ? showLinks : true)
        ? (
          <div className={style.nav}>
            <VendorHeaderTab
              href="/vendor/company/profile"
              title={t('My Company Profile')}
              activeRegex={/\/vendor\/company/}
            />
            {(
              ['approved', 'needs-review'].includes(applicationData?.getApplication.data?.status || '')
              || process.env.REACT_APP_DISABLE_INITIAL_REGISTRATION === 'true'
            ) && (
            <>
              <VendorHeaderTab
                href="/vendor/registration"
                title={t('Buy New Pass')}
                activeRegex={/\/vendor\/registration/}
              />
              <VendorHeaderTab
                href="/vendor/my-passes"
                title={t('My Passes')}
                activeRegex={/\/vendor\/my-passes/}
              />
            </>
            )}

            <VendorHeaderTab
              href="/vendor/review-rules"
              title={t('Review Rules')}
              activeRegex={/\/vendor\/review-rules/}
            />
            {/* <VendorHeaderTab
                  href="/vendor/edit-profile"
                  title={t('My Profile')}
                  activeRegex={/\/vendor\/edit-profile/}
                />
                <VendorHeaderTab
                  href="/vendor/registration/rental"
                  title={t('FAQ')}
                  activeRegex={/\/vendor\/registration\/rental/}
                /> */}
          </div>
        )
        : null}
      <div className={profileBox}>

        <button
          onClick={() => {
            i18n.changeLanguage(i18n.language === 'en' ? 'es' : 'en');
          }}
          className={`${style.language} textColorWhite thirdColor `}
        >
          <span className={style.languageIcon}>
            <LanguageIcon />
          </span>
          {t('otherLanguage')}
        </button>

        <div className={profileBtn}>

          {isLogedin
            ? <UserProfileButton />
            : null}

          {zendeskURLs?.vendor ? (
            <Link to="/vendor/help">
              <p className={`${helpBtn} textColorMain`}>{t('Help')}</p>
            </Link>
          ) : (
            <div>
              <HelpTooltip>
                <Link to="https://symliv.com/get-in-touch">
                  <p className={`${helpBtn} textColorMain`}>{t('Help')}</p>
                </Link>
              </HelpTooltip>
            </div>
          )}

        </div>

      </div>
    </div>
  );
};
export default Header;
