import { gql } from '@apollo/client';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';

export const STEP_FOUR_QUERY = gql`
  query Step4Query {
    getCompanyProfile {
      success
      error
      data {
        userId
        companyName
        businessLicenseNumber
        altPhoneNumber
        businessPhone
        businessType
        address
        city
        state
        zipCode
        emergencyContactName
        emergencyPhoneNumber
      }
    }
  }
`;
export type STEP_FOUR_VARS = {
}
export type STEP_FOUR_RES = {
  getCompanyProfile: backendResponse<{
    userId: string,
    companyName: string,
    businessLicenseNumber: string,
    altPhoneNumber: string,
    businessPhone: string,
    businessType: string,
    address: string,
    city: string,
    state: string,
    zipCode: string,
    emergencyContactName: string,
    emergencyPhoneNumber: string,
  }>
}
