import { gql, useMutation, useQuery } from '@apollo/client';
import {
  compact, flatten, map, uniq, uniqBy,
} from 'lodash';
import React, { useContext, useState } from 'react';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import LoadingDiamonds from '../../../common_lib_front/components/loadingDiamonds/loadingDiamonds';
import { backendClient } from '../../../common_lib_front/utilities/BackendAPI';
import CommonDialog from '../../../components/commonDialog/commonDialog';
import { DocumentConfig } from '../../../components/companyDocumentTable/companyDocument';
import CompanyDocumentsGrid from '../../../components/companyDocumentsGrid/companyDocumentsGrid';
import UploadDocuments from '../../../components/uploadDocuments/uploadDocuments';
import { GET_ALL_PASS, GET_DOCUMENT_INFO_CONFIG } from '../../passes/purchasePass/passSelection/usePassSelection';
import style from './companyDocuments.module.css';

export const GET_COMPANY_DOCUMENT_QUERY = gql`
  query {
    getUserDocuments {
      success
      error
      data {
        documentId
        documentType 
        fileName
        userDocumentName
        documentUrl
        uploadDate
        documentExpiration
      }
    }
  }
`;

export const GET_DOCUMENT_CONFIG_QUERY = gql`
  query {
    getCommunityDocumentsConfig {
        success
        error
        data {
            documentConfigId
            name
            status
        }
    }
  }
`;

export const UPLOAD_COMPANY_DOCUMENT_MUTATION = gql`
  mutation UploadDocument(
    $fileName: String!,
    $documentType: String!,
    $userDocumentName: String!,
    $documentConfigId: String,
    $documentExpiration: DateTime,
    $file: String!,
  ){
    addUserDocument (
      fileName: $fileName,
      userDocumentName: $userDocumentName,
      documentConfigId: $documentConfigId,
      documentExpiration: $documentExpiration,
      file: $file,
      documentType: $documentType,
    ) {
      success
      error
      data {
        documentId
        userId
        fileName
        userDocumentName
        documentUrl
        documentConfigId
      }
    }
  }
`;

export default function CompanyDocuments(): React.ReactElement {
  const [showAddDocument, setShowAddDocument] = useState<boolean>(false);
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');

  const { communityId } = useContext(CommunityContext);
  const { data, loading: dataLoading, refetch: refetchData } = useQuery(
    GET_COMPANY_DOCUMENT_QUERY, {
      fetchPolicy: 'cache-and-network',
    },
  );
  // const { data: config, loading: configLoading } = useQuery(GET_DOCUMENT_CONFIG_QUERY);

  const documentData = data ? data.getUserDocuments.data : [];

  const [doUpload, { loading: isUploading }] = useMutation(UPLOAD_COMPANY_DOCUMENT_MUTATION, {
    onError: (e) => {
      setAlert(e.message);
      setAlertColor('red');
    },
    onCompleted: (d) => {
      if (!d.addUserDocument.success) {
        setAlert(d.addUserDocument.error);
        setAlertColor('red');
      }
    },
  });

  const uploadHandle = async function (list: any) {
    await doUpload({
      variables:
      {
        ...list,
        documentType: list.docType,
      },
    });
    setShowAddDocument(false);
    await refetchData({});
  };

  return (
    <div className={style.container}>
      <div className={style.box}>
        <div className={`${style.infoBox} white`}>
          <div className={`${style.subHeader} mainColor`}>
            <div className={style.subHeaderLeft}>
              <div className={`${style.circle} white`} />
              <h1 className={`${style.title} textColorWhite`}>
                Company Documents
              </h1>
            </div>
            <div className={style.subHeaderRight}>
              <GenericButton title="Add Documents" color="yellow" size="medium" outline="no-border" clickHandler={() => setShowAddDocument(true)} />
            </div>
          </div>
          <div className={style.mainContent}>
            <GenericAlert
              color={alertColor}
              title={alert}
              hidden={!alert}
            />

            {dataLoading ? (
              <LoadingDiamonds
                title="Loading Company Documents"
              />
            ) : (
              <CompanyDocumentsGrid
                data={documentData}
              />
            )}
          </div>
        </div>

        <CommonDialog
          showDialog={showAddDocument}
          title="Add Document"
          onClose={() => setShowAddDocument(false)}
          displayTop
        >
          { isUploading ? (
            <div className={style.relative}>
              <LoadingDiamonds
                title={isUploading ? 'Uploading documents' : 'Loading Document Configs'}
              />
            </div>
          ) : (
            <UploadDocuments onUpload={(list) => uploadHandle(list)} />
          )}
        </CommonDialog>
      </div>
    </div>
  );
}
