import {
  LazyQueryHookOptions,
  LazyQueryResultTuple,
  QueryHookOptions, QueryResult, gql, useLazyQuery, useQuery,
} from '@apollo/client';
import { backendResponse } from '../common_lib_front/types/backendResponse';
import { backendClient } from '../common_lib_front/utilities/BackendAPI';

const GET_PASS_IMAGE = gql`
query GetPassImage($passId: String!) {
  getPassImage(passId: $passId) {
    success
    error
    data {
      passId
      passInfoId
      passType
      url
    }
  }
}
`;
type GET_PASS_IMAGE_VARS = {
  passId: string;
}
type GET_PASS_IMAGE_RESPONSE_DATA = {
  passId: string;
  passInfoId: string;
  passType: string;
  url: string;
}
type GET_PASS_IMAGE_RES = {
  getPassImage: backendResponse<GET_PASS_IMAGE_RESPONSE_DATA>;
}

// async function
export async function getPassImage(passId: string): Promise<GET_PASS_IMAGE_RESPONSE_DATA> {
  return backendClient.query<GET_PASS_IMAGE_RES, GET_PASS_IMAGE_VARS>({
    query: GET_PASS_IMAGE,
    variables: { passId },
  })
    .then((res) => {
      if (res.data.getPassImage.success && res.data.getPassImage.data) {
        return res.data.getPassImage.data;
      }
      throw Error(res.data.getPassImage.error || 'Something went wrong. Could not get pass image.');
    });
}

// query
type useGetPassImageOptions = QueryHookOptions<GET_PASS_IMAGE_RES, GET_PASS_IMAGE_VARS>;
type useGetPassImageResult = QueryResult<GET_PASS_IMAGE_RES, GET_PASS_IMAGE_VARS>;
export const useGetPassImage = (options: useGetPassImageOptions):
useGetPassImageResult => useQuery<
GET_PASS_IMAGE_RES, GET_PASS_IMAGE_VARS
>(GET_PASS_IMAGE, options);

// lazy query
type useLazyGetPassImageOptions = LazyQueryHookOptions<GET_PASS_IMAGE_RES, GET_PASS_IMAGE_VARS>;
type useLazyGetPassImageResult = LazyQueryResultTuple<GET_PASS_IMAGE_RES, GET_PASS_IMAGE_VARS>;
export const useLazyGetPassImage = (options: useLazyGetPassImageOptions):
useLazyGetPassImageResult => useLazyQuery<
GET_PASS_IMAGE_RES, GET_PASS_IMAGE_VARS
>(GET_PASS_IMAGE, options);
