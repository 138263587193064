import {
  MutationHookOptions, MutationTuple, gql, useMutation,
} from '@apollo/client';
import { backendResponse } from '../common_lib_front/types/backendResponse';

const SET_APPLICATION_STATUS = gql`
mutation EditApplicationStatus($status: String!, $registrationId: String!) {
  editApplicationStatus(status: $status, registrationId: $registrationId) {
    error
    success
  }
}
`;

type SET_APPLICATION_STATUS_VARS = {
  registrationId: string;
  status: 'incomplete' | 'pending-review';
}

type SET_APPLICATION_STATUS_RES = {
  editApplicationStatus: backendResponse<undefined>;
}

type optionsType = MutationHookOptions<SET_APPLICATION_STATUS_RES, SET_APPLICATION_STATUS_VARS>;
type resType = MutationTuple<SET_APPLICATION_STATUS_RES, SET_APPLICATION_STATUS_VARS>;

export const useSEtApplicationStatus = (options?: optionsType): resType => useMutation<
  SET_APPLICATION_STATUS_RES, SET_APPLICATION_STATUS_VARS
>(SET_APPLICATION_STATUS, {
  ...options,
});
