import { ReactElement, useContext } from 'react';
import { Link } from 'react-router-dom';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import { ReactComponent as DocumentImg } from '../../assets/companyDocument.svg';
import { ReactComponent as ProfileImg } from '../../assets/companyProfile.svg';
import { ReactComponent as EmployeesImg } from '../../assets/employee.svg';
import { ReactComponent as VehicleImg } from '../../assets/vehicleWhite.svg';
import { useGetApplication } from '../../hooks/useGetApplication';
import { Footer } from '../footer/footer';
import style from './companyInfo.module.css';

interface CompanyInfoVendorProps {
  children?: ReactElement | ReactElement[];
  active?: string;
}

export default function CompanyInfo(props: CompanyInfoVendorProps): ReactElement {
  const { children, active } = props;
  const { data: applicationData } = useGetApplication();
  const { communityId, featuresConfig } = useContext(CommunityContext);

  // TODO: remove this it is only for example
  const tmpStyles: any = {
    profile: {},
    documents: {},
    employees: {},
    vehicles: {},
  };

  switch (active) {
    case 'profile':
      // TODO: add styles for active 'company profile' tab
      tmpStyles.profile.backgroundColor = 'var(--thirdColor)';
      break;
    case 'documents':
      // TODO: add styles for active 'company documents' tab
      tmpStyles.documents.backgroundColor = 'var(--thirdColor)';
      break;
    case 'employees':
      // TODO: add styles for active 'employees' tab
      tmpStyles.employees.backgroundColor = 'var(--thirdColor)';
      break;
    case 'vehicles':
      // TODO: add styles for active 'employees' tab
      tmpStyles.vehicles.backgroundColor = 'var(--thirdColor)';
      break;
    default:
  }

  return (
    <div className={style.container}>
      <div className={`${style.box} white `}>

        <div className={`${style.subNav} darkGrey `}>
          <div className={style.btnBox}>
            <Link to="/vendor/company/profile">
              <button style={tmpStyles.profile} className={`${style.btn} textColorWhite darkGrey `}>
                <div>
                  {' '}
                  <ProfileImg />
                </div>
                <p className={style.text}>Company Profile</p>
              </button>
            </Link>
            <Link to="/vendor/company/documents">
              <button style={tmpStyles.documents} className={`${style.btn} textColorWhite darkGrey `}>
                <div>
                  {' '}
                  <DocumentImg />
                </div>
                <p className={style.text}>Company Documents</p>
              </button>
            </Link>
            {(
              ['approved', 'needs-review'].includes(applicationData?.getApplication.data?.status || '')
              || process.env.REACT_APP_DISABLE_INITIAL_REGISTRATION === 'true'
            ) && (
              <>
                {!featuresConfig?.vendor?.hideEmployeeMenu && (
                  <Link to="/vendor/company/employees">
                    <button style={tmpStyles.employees} className={`${style.btn} textColorWhite darkGrey `}>
                      <div>
                        {' '}
                        <EmployeesImg />
                      </div>
                      <p className={style.text}>Employees</p>
                    </button>
                  </Link>
                )}

                {!featuresConfig?.vendor?.hideVehicleMenu && (
                  <Link to="/vendor/company/vehicles">
                    <button style={tmpStyles.vehicles} className={`${style.btn} textColorWhite darkGrey `}>
                      <div>
                        {' '}
                        <VehicleImg />
                      </div>
                      <p className={style.text}>Vehicles</p>
                    </button>
                  </Link>
                )}
              </>
            )}
          </div>

        </div>
        <div className={style.infoBox}>
          {children}
        </div>
      </div>
      <Footer />
    </div>

  );
}
CompanyInfo.defaultProps = {
  children: undefined,
  active: '',
};
