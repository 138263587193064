export type CompanyInfo = {
  companyInfoId?: string;
  businessLicenseNumber?: string;
  companyName?: string;
  businessPhone?: string;
  altPhoneNumber?: string;
  address?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  emergencyContactName?: string;
  emergencyPhoneNumber?: string;
  businessType?: string;
  [key: string]: any;
};

export type vendorInfoConfigType = {
  businessLicenseNumber: string;
  alternatePhone?: string;
  firstName: string;
  lastName: string;
  email: string;
  businessName: string;
  businessPhone: string;
  cellPhone: string;
  businessAddress: string;
  emergencyContactName: string;
  businessType: string;
};

export function newCompanyInfo(data: any = {}): CompanyInfo {
  const res: CompanyInfo = {
    businessLicneseNumber: '',
    companyName: '',
    altPhoneNumber: '',
    address: '',
    country: '',
    city: '',
    state: '',
    zipCode: '',
    firstName: '',
    lastName: '',
    email: '',
    emergencyContactName: '',
    emergencyPhoneNumber: '',
    businessType: '',
  };
  Object.assign(res, data);
  return res;
}

export const companyInfoRequiredFields = ['companyName'];

export default CompanyInfo;
