import { gql } from '@apollo/client';
import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef, useState,
} from 'react';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import DocumentTypeDropdown, { documentTypeOption } from '../../common_lib_front/components/documentTypesDropDown/documentTypesDropdown';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import InputField from '../../common_lib_front/components/inputField/inputField';
import { useDocumentConfigs } from '../../hooks/useDocumentConfigs';
import { DocumentConfig } from '../companyDocumentTable/companyDocument';
import style from './uploadDocuments.module.css';

const ACCEPTED_FILE_TYPES = '.pdf,.png,.jpg';
const MAX_UPLOAD_PAYLOAD_SIZE = 100 * 1024; // bodyParser default limit: 100KB
const MAX_UPLOAD_FILE_SIZE = `${Math.floor(MAX_UPLOAD_PAYLOAD_SIZE / 1024 / 1.33)}KB`; // base64 increase file size, so we should decrease this limit

const GET_MY_PASSES = gql`
  query {
    getMyPasses {
      success
      error
      data {
        passId
        number
        externalCredentialNumber
      }
    }
  }
`;

interface UploadDocumentsProps {
  onUpload: (docSubmit: any) => void
}

export default function (props: UploadDocumentsProps): ReactElement {
  const { onUpload } = props;
  const { t } = useTranslation();
  const [doc, setDoc] = useState<string>('');
  const [docType, setDocType] = useState<string>('');
  const [userDocumentName, setUserDocumentName] = useState<string>('');
  const [selectedDocName, setSelectedDocName] = useState<string>('');
  const [documentConfigId, setDocumentConfigId] = useState<string>('');
  const [documentExpiration, setDocumentExpiration] = useState<string | null>(null);
  const [noExpirationDate, setNoExpirationDate] = useState<boolean>(false);

  const { data: documentTypesData } = useDocumentConfigs({
    fetchPolicy: 'network-only',
  });

  const shuffleDocumentList = useMemo(() => {
    if (documentTypesData && documentTypesData.getCommunityDocumentsConfig
      && documentTypesData.getCommunityDocumentsConfig.data
      && documentTypesData.getCommunityDocumentsConfig.data.length > 0) {
      // change 'Other' option position to at the end of array
      const docList = documentTypesData.getCommunityDocumentsConfig.data;
      const findInd = docList.findIndex((ele) => ele.name === 'Other');
      if (findInd !== -1) { // Check if 'Other' element exists
        const otherObj = docList[findInd];
        docList.splice(findInd, 1);
        docList.push(otherObj);
        return docList;
      }
    }
    return documentTypesData?.getCommunityDocumentsConfig?.data || [];
  }, [documentTypesData]);

  const prepareUpload = () => {
    const docSubmit = {
      userDocumentName,
      fileName: selectedDocName,
      docType,
      file: doc,
      documentConfigId,
      documentExpiration,
    };
    if (docSubmit.fileName && docSubmit.file) {
      // if file name and file are selected then only this function calls
      onUpload(docSubmit);
    }
  };

  const setDocumentType = (docID: string) => {
    const docList = shuffleDocumentList || [];
    if (docList && docList.length > 0) {
      const docFilter = docList.filter((ele) => ele.documentConfigId === docID);
      if (docFilter.length > 0) {
        setDocType(docFilter[0].name);
        if (docFilter[0]?.noExpirationDate && docFilter[0].noExpirationDate === true) {
          setNoExpirationDate(docFilter[0].noExpirationDate);
          setDocumentExpiration(null);
        }
      }
    }
  };

  const myInputRef = useRef<HTMLInputElement[]>([]);

  myInputRef.current = [];
  // add element to ref list

  async function selectForUpload(event: any) {
    const file = event[0];
    if (!file) return;
    const imgDataURL: string = await new Promise((resolve: any) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(file);
    });
    setDoc(imgDataURL);
    setSelectedDocName(file.name);
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        prepareUpload();
      }}
    >
      <div className={style.typeLits}>
        <div className={style.uploadBox}>
          <div style={{ display: 'flex' }}>
            <DocumentTypeDropdown
              largeSize
              title="Document Type"
              htmlFor="business-type-inpt"
              value={documentConfigId || ''}
              required
              documentTypes={(shuffleDocumentList || [])
                .map((dt) => ({
                  name: dt.name,
                  documentConfigId: dt.documentConfigId,
                  noExpirationDate: dt.noExpirationDate,
                }))}
              changeHandler={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setDocumentConfigId(e.target.value);
                setDocumentType(e.target.value);
              }}
            />
            <div style={{ marginLeft: '10px' }}>
              <InputField
                highlightOnFocus
                largeSize
                htmlFor="doc-name-inpt"
                labelTitle="Document Name"
                inputType="text"
                inputValue={userDocumentName}
                required
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setUserDocumentName(e.target.value);
                }}
              />
            </div>
            {/* <InputField
              largeSize
              highlightOnFocus
              htmlFor="doc-type-inpt"
              labelTitle="Document Type"
              inputType="text"
              inputValue={docType}
              changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                setDocType(e.target.value);
              }}
            /> */}
            <div style={{ marginLeft: '10px' }}>
              <InputField
                highlightOnFocus
                largeSize
                htmlFor="doc-exp-inpt"
                labelTitle="Document Expiration"
                inputType="date"
                inputValue={documentExpiration || undefined}
                required={noExpirationDate !== true}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setDocumentExpiration(e.target.value);
                }}
              />
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Dropzone onDrop={(acceptedFiles) => selectForUpload(acceptedFiles)}>
              {({ getRootProps, getInputProps }) => (
                <section style={{ width: '100%' }} className="wfp--dropzone">
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <div
                    style={{ width: '100%' }}
                    // eslint-disable-next-line
                    {...getRootProps({ className: 'wfp--dropzone__input' })}
                  >
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <input {...getInputProps()} />
                    <div className={style.typeItem}>
                      <div className={style.lowerPart}>
                        <p className={style.dragTitle}>
                          <div>{selectedDocName || 'Drag File to Upload'}</div>
                        </p>
                        <p className={style.dragSubTitle}>or</p>
                        <button className={style.browseBtn}>Browse Files</button>
                      </div>
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
        </div>
      </div>
      <GenericButton
        className={style.upload}
        color="blue"
        size="large"
        type="submit"
        disabled={!doc.length}
        title={t('Upload')}
      />
    </form>
  );
}
