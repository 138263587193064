import { gql, useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import { PopUp } from '../../../common_lib_front/components/popUp/popUp';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';
import { newEmployeeInfo } from '../../../common_lib_front/types/employeeInfo';
import PassInfo, { newPassInfo } from '../../../common_lib_front/types/passInfo';
import { newVehicleInfo } from '../../../common_lib_front/types/vehicleInfo';
import PassForm, { passFormInput } from '../../../components/passForm/passForm';
import style from './completePass.module.css';

const GET_PASSES = gql`
query GetMyPasses {
  getMyPasses {
    success
    error
    data {
      addons
      endDate
      number
      externalCredentialNumber
      passId
      passInfoId
      passType
      registrationId
      startDate
      status
      vehicle {
        color
        fleetNumber
        licensePlate
        licensePlateState
        licensePrimaryDriver
        make
        primaryDriverName
        type
        vehicleId
        vehicleModel
        destination
      }
    }
  }
}
`;
type GET_PASSES_RES = {
  getMyPasses: backendResponse<{
    addons: string[],
    startDate: string,
    endDate: string,
    number: number,
    passId: string,
    passInfoId: string,
    passType: string,
    registrationId: string,
    status: string,
    vehicle: {
      color: string,
      fleetNumber: string,
      licensePlate: string,
      licensePlateState: string,
      licensePrimaryDriver: string,
      make: string,
      primaryDriverName: string,
      type: string,
      vehicleId: string,
      vehicleModel: string,
      destination: string,
    },
  }[]>
};

const EDIT = gql`
mutation Mutation(
  $passId: String!, 
  $vehicleId: String!
  $status: String!, 
  $licensePrimaryDriver: String!, 
  $primaryDriverName: String!, 
  $licensePlateState: String!, 
  $fleetNumber: String!, 
  $licensePlate: String!, 
  $color: String!, 
  $type: String!, 
  $vehicleModel: String!, 
  $make: String!, 
  $destination: String!
) {
  editPassStatus(
    status: $status,
    passId: $passId
  ) {
    error
    success
  }
  editVehicle(
    vehicleId: $vehicleId,
    licensePrimaryDriver: $licensePrimaryDriver, 
    primaryDriverName: $primaryDriverName, 
    licensePlateState: $licensePlateState, 
    fleetNumber: $fleetNumber, 
    licensePlate: $licensePlate, 
    color: $color, 
    type: $type, 
    vehicleModel: $vehicleModel, 
    make: $make, 
    destination: $destination,
  ) {
    error
    success
  }
}
`;
type EDIT_VARS = {
  status: string,
  passId: string,
  licensePrimaryDriver: string,
  primaryDriverName: string,
  licensePlateState: string,
  fleetNumber: string,
  licensePlate: string,
  color: string,
  type: string,
  vehicleModel: string,
  make: string,
  vehicleId: string,
  destination: string,
}
type EDIT_RES = {
  editPassStatus: backendResponse<undefined>,
  editVehicle: backendResponse<undefined>,
}

export default function CompletePass(): React.ReactElement {
  const history = useHistory();
  const { passId } = useParams<{ passId: string }>();
  const [pass, setPass] = useState<passFormInput>(newPassInfo());
  const [redirect, setRedirect] = useState<string>('');
  const { data: getData, error: getError } = useQuery<GET_PASSES_RES>(GET_PASSES, {
    onCompleted: (d) => {
      if (!d.getMyPasses.data) return;
      const tmp = d.getMyPasses.data.find((p) => p.passId === passId);
      setPass({
        ...newPassInfo(tmp as unknown as Partial<PassInfo>),
        employee: newEmployeeInfo(tmp?.vehicle),
        vehicle: newVehicleInfo(tmp?.vehicle),
        destination: tmp?.vehicle?.destination,
      });
    },
  });
  const [doEdit, { data: editData, error: editError }] = useMutation<EDIT_RES, EDIT_VARS>(EDIT, {
    onCompleted: (d) => {
      if (d.editPassStatus.success && d.editVehicle.success) {
        history.goBack();
      }
    },
  });

  return (
    <PopUp close={() => setRedirect('/vendor/my-passes')} title="Complete Pass">
      {redirect && <Redirect to={redirect} />}
      <form
        className={style.form}
        onSubmit={(e) => {
          e.preventDefault();
          doEdit({
            variables: {
              status: 'inactive',
              passId,
              licensePrimaryDriver: (pass.employee?.isLicenseSelected ? pass.employee?.identificationNumber : '') || pass.vehicle.licensePrimaryDriver || '',
              primaryDriverName: `${pass.employee?.firstName || ''} ${pass.employee?.lastName || ''}` || pass.vehicle.primaryDriverName,
              licensePlateState: pass.vehicle.licensePlateState,
              fleetNumber: pass.vehicle.fleetNumber,
              licensePlate: pass.vehicle.plateNumber,
              color: pass.vehicle.color,
              type: pass.vehicle.type,
              vehicleModel: pass.vehicle.model,
              make: pass.vehicle.make,
              // wrong vehicle id. Do not use
              vehicleId: pass.vehicle.vehicleId,
              // vehicleId:
              destination: pass.destination || '',
            },
          })
            .then((res) => {
              if (res.data?.editPassStatus.success && res.data.editVehicle.success) {
                setRedirect('/vendor/my-passes');
              }
            });
        }}
      >
        {editError && <GenericAlert color="red" title="Something went wrong. Changes could not be saved." />}
        {getError && <GenericAlert color="red" title="Something went wrong. Data could not be loaded and changes will not be able to be saved." />}
        {editData?.editPassStatus.error && <GenericAlert color="red" title={editData.editPassStatus.error} />}
        {editData?.editVehicle.error && <GenericAlert color="red" title={editData.editVehicle.error} />}
        {getData?.getMyPasses.error && <GenericAlert color="red" title={getData.getMyPasses.error} />}
        <PassForm
          allowRemove={false}
          data={pass}
          idx={0}
          removeHandler={() => { /* */ }}
          allowCompleteLater={false}
          updateHandler={(obj) => setPass((prev) => ({
            ...prev,
            ...obj,
            vehicle: newVehicleInfo({
              ...prev.vehicle,
              ...obj.vehicle,
              vehicleId: prev.vehicle.vehicleId,
            }),
            employee: newEmployeeInfo({
              ...prev.employee,
              ...obj.employee,
            }),
          }))}
        />
        {/* <VehicleInfoForm
          data={pass}
          idx={0}
          allowCompleteLater={false}
          allowRemove={false}
          allowEditAddons={false}
          updateHandler={
          (obj) => setPass(
            (prev) => newPassInfo({
              ...prev,
              ...obj,
              vehicle: {
                ...prev.vehicle,
                ...obj.vehicle,
              },
            }),
          )
          }
        /> */}
        <GenericButton
          type="submit"
          title="Complete"
          color="blue"
          outline="none"
          size="medium"
          shape="rectangle"
        />
      </form>
    </PopUp>
  );
}
