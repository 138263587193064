import React, { useMemo } from 'react';
import useOrderPrice, { PassPrice } from '../../common_lib_front/hooks/useOrderPrice';
import PassInfo from '../../common_lib_front/types/passInfo';
import PassSummary from './passSummary';
import style from './passSummaryCollection.module.css';

type PassSummaryCollectionProps = {
	showVehicleInfo: boolean;
	showEditBtn: boolean;
	showTotal: boolean;
  data: Array<PassInfo>;
  editLink?: string;
  isRenewal?: boolean;
}

export const PassSummaryCollection = (props: PassSummaryCollectionProps): React.ReactElement => {
  const {
    data, showTotal, editLink, isRenewal,
  } = props;
  const {
    totalPrice,
    passes,
  } = useOrderPrice(data);

  const passPriceInfo = useMemo(() => {
    const res = {} as Record<string, PassPrice>;
    passes?.forEach((p) => {
      res[p.passInfoId] = p;
    });
    return res;
  }, [passes]);

  return (
    <div className={style.infoBox}>
      {data.map((elem:PassInfo, idx:number) => (
        <div className={style.summaryBox} key={elem.passId}>
          <PassSummary
            data={elem}
            // allPasses={data}
            idx={idx}
            showVehicleInfo={props.showVehicleInfo}
            showEditBtn={props.showEditBtn}
            editLink={editLink}
            priceInfo={passes?.[idx] || passPriceInfo[elem.passInfoId]}
            isRenewal={isRenewal}
            // priceInfo={passPriceInfo[elem.passInfoId]}
          />
        </div>
      ))}
      {showTotal && (
        <div className={style.totalPriceBox}>
          <span className={style.totalPriceTitle}>
            Total
          </span>
          <span className={style.totalPriceValue}>
            $
            {totalPrice}
          </span>
        </div>
      )}
    </div>
  );
};
PassSummaryCollection.defaultProps = {
  editLink: '',
  isRenewal: false,
};

export default PassSummaryCollection;
