import React, { ReactElement, useState } from 'react';
import {
  gql, useQuery, ApolloError, useMutation,
} from '@apollo/client';
import { useTranslation } from 'react-i18next';
import InputField from '../../common_lib_front/components/inputField/inputField';
import style from './myProfileForm.module.css';
import alertIcon from '../../assets/alert.svg';
import GenericAlert from '../../common_lib_front/components/genericAlert/genericAlert';
import PhoneInputWrapper from '../../common_lib_front/components/phoneInputWrapper/phoneInputWrapper';
import UserInfo, { newUserInfo } from '../../common_lib_front/types/userInfo';
import { checkNull } from '../../common_lib_front/utilities/BackendAPI';
import UsStateSelect from '../usStateSelect/UsStateSelect';
import store from '../../common_lib_front/utilities/store';

const GET_USER_INFO = gql`
query GetUserInfo {
  getUserInfo {
    success
    error
    data {
      firstName
      lastName
      phoneNumber
      address
      city
      state
      zipCode
    }
  }
}
`;

const EDIT_USER_INFO = gql`
mutation EditUserInfo(
  $data: EditUserInput!,
) {
  editUserInfo(
    data: $data
  ) {
    success
    error
  }
}
`;

export const MyProfileForm = () :ReactElement => {
  const [userInfo, setUserInfo] = useState<UserInfo>(newUserInfo());
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green' | undefined>(undefined);
  const { t } = useTranslation();

  const { refetch } = useQuery(GET_USER_INFO, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'network-only',
    onError: (e: ApolloError) => {
      setAlert(e.message);
      setAlertColor('red');
    },
    onCompleted: (d) => {
      if (!d.getUserInfo.success) {
        setAlert(d.getUserInfo.error);
        setAlertColor('red');
      } else {
        setUserInfo(d.getUserInfo.data);
      }
    },
  });

  const [doSave, { loading: mutationLoading }] = useMutation(EDIT_USER_INFO, {
    onCompleted: (d: any) => {
      refetch();
      if (d.editUserInfo.success) {
        setAlert('Data has been saved');
        setAlertColor('green');
        if (userInfo.firstName !== '' || userInfo.lastName !== '') {
          store.dispatch({
            type: 'UPDATE_PROFILE',
            payload: userInfo,
          });
        }
      } else {
        setAlert(d.editUserInfo.error);
        setAlertColor('red');
      }
    },
    onError: (e: ApolloError) => {
      setAlert(e.message);
      setAlertColor('red');
    },
  });

  const updateUserInfo = (data: Partial<UserInfo>) => {
    setUserInfo({
      ...userInfo,
      ...data,
    });
  };

  return (
    <form
      onSubmit={(e:React.FormEvent<HTMLFormElement>):void => {
        e.preventDefault();
        doSave({
          variables: {
            data: checkNull({
              firstName: userInfo?.firstName,
              lastName: userInfo?.lastName,
              phoneNumber: userInfo?.phoneNumber,
              address: userInfo?.address,
              city: userInfo?.city,
              state: userInfo?.state,
              zipCode: userInfo?.zipCode,
            }),
          },
        });
      }}
      className={style.form}
    >
      <div>
        <GenericAlert
          color={alertColor}
          hidden={!alert}
          title={alert}
        />
      </div>
      {mutationLoading ? t('Saving...') : null}
      <div className={style.inputBox}>
        <div className={style.inputShort}>
          <InputField
            largeSize
            highlightOnFocus
            htmlFor="first-name-inpt"
            labelTitle={t('First Name')}
            inputValue={userInfo?.firstName}
            changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateUserInfo({ firstName: e.target.value });
            }}
          />
        </div>

        <div className={style.inputShort}>
          <InputField
            largeSize
            highlightOnFocus
            htmlFor="last-name-inpt"
            labelTitle={t('Last Name')}
            inputType="text"
            inputValue={userInfo?.lastName}
            changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateUserInfo({ lastName: e.target.value });
            }}
          />
        </div>
      </div>
      <div className={style.inputLong}>
        <PhoneInputWrapper
          standardInput
          inputTitle="Phone Number"
          value={(userInfo?.phoneNumber) ? userInfo?.phoneNumber : ''}
          changeHandler={(val: string) => {
            updateUserInfo({ phoneNumber: val });
          }}
        />
      </div>

      <div className={style.inputBox}>
        <div className={style.inputShort}>
          <InputField
            largeSize
            highlightOnFocus
            htmlFor="address-inpt"
            labelTitle={t('Home Address')}
            inputValue={userInfo?.address}
            changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateUserInfo({ address: e.target.value });
            }}
          />
        </div>

        <div className={style.inputShort}>
          <InputField
            largeSize
            highlightOnFocus
            htmlFor="city-inpt"
            labelTitle={t('City')}
            inputValue={userInfo?.city}
            changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateUserInfo({ city: e.target.value });
            }}
          />
        </div>

      </div>
      <div className={style.inputBox}>
        <div className={style.inputShort}>
          {/* <InputField
            largeSize
            highlightOnFocus
            htmlFor="state-inpt"
            labelTitle={t('State')}
            inputValue={userInfo?.state}
            changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateUserInfo({ state: e.target.value });
            }}
          /> */}
          <UsStateSelect
            title={t('State')}
            htmlFor="state-inpt"
            value={userInfo.state || ''}
            changeHandler={(e: React.ChangeEvent<HTMLSelectElement>) => {
              updateUserInfo({ state: e.target.value });
            }}
          />
        </div>

        <div className={style.inputShort}>
          <InputField
            largeSize
            highlightOnFocus
            htmlFor="zip-inpt"
            labelTitle={t('Zip Code')}
            formatCode="zip-strict"
            inputValue={userInfo?.zipCode}
            changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateUserInfo({ zipCode: e.target.value });
            }}
          />

        </div>
      </div>
      <div className={style.alertBox}>
        <img src={alertIcon} alt="" />
        <p className={`${style.alert} textColorMedium`}>{t('Please verify that the above information is current and accurate.')}</p>

      </div>
      <div className={style.btnBox}>
        <input
          type="submit"
          value={`${t('Save Changes')}`}
          className={`${style.btn} mainColor textColorWhite`}
        />

      </div>
    </form>
  );
};

export default MyProfileForm;
