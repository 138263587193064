import React from 'react';
import useOrderPrice from '../../common_lib_front/hooks/useOrderPrice';
import PassInfo from '../../common_lib_front/types/passInfo';
import { passCredOrNumber } from '../../common_lib_front/utilities/passCredOrNumber';
import CommonDialog from '../commonDialog/commonDialog';
import style from './confirmRenewDialog.module.css';

interface ConfirmRenewDialogProps {
  data: PassInfo[],
  showDialog: boolean,
  onClose: () => void,
  onRenew: () => void
}

export default function (props: ConfirmRenewDialogProps): React.ReactElement {
  const {
    data,
    showDialog,
    onClose,
    onRenew,
  } = props;

  const {
    totalPrice,
  } = useOrderPrice(data);

  return (
    <CommonDialog showDialog={showDialog} onClose={onClose}>
      <div className={style.content}>
        <p className={style.title}><b>Please confirm you&apos;d like to renew pass</b></p>
        {data.map((item) => (
          <p key={item.key} className={style.passNumber}>
            Pass Number:
            <span className={style.number}>{passCredOrNumber(item)}</span>
          </p>
        ))}

        <p className={style.cost}>
          Cost to renew pass:
          {' '}
          <span className={style.number}>
            $
            {totalPrice}
          </span>
        </p>
        <div className={style.btnBox}>
          <button className={style.btnRenew} onClick={() => onRenew()}>Renew Pass</button>
          <button className={style.btnCancel} onClick={() => onClose()}>Cancel</button>

        </div>
      </div>
    </CommonDialog>
  );
}
