import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, Link } from 'react-router-dom';
import CompanyInfo from '../../common_lib_front/types/companyInfo';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import style from './companyInformationReview.module.css';
import store from '../../common_lib_front/utilities/store';

type CompanyInformationReviewProps = {
  data: CompanyInfo;
  editLink?: string;
  docEditLink?: string | ((registrationId: string) => string);
  registrationId: string;
}

export const CompanyInformationReview = (props: CompanyInformationReviewProps): ReactElement => {
  const [editMode, setEditMode] = useState(false);

  const {
    data, editLink, registrationId, docEditLink,
  } = props;
  const history = useHistory();
  const { t } = useTranslation();

  const titles = [
    { title: 'Name', key: 'name' },
    { title: 'Email address', key: 'email' },
    { title: 'Business name', key: 'companyName' },
    { title: 'Business License', key: 'businessLicenseNumber' },
    { title: 'Business address', key: 'address' },
    { title: 'City', key: 'city' },
    { title: 'State', key: 'state' },
    { title: 'Zip code', key: 'zipCode' },
    { title: 'Business phone number', key: 'businessPhone' },
    { title: 'Alternate phone number', key: 'altPhoneNumber' },
    { title: 'Emergency Contact Name', key: 'emergencyContactName' },
    { title: 'Emergency Contact Cell phone number', key: 'emergencyPhoneNumber' },
  ];

  const getValue = (key: string): string | undefined => {
    const user = store?.getState()?.user;
    if (key === 'email') {
      return user?.email;
    }
    if (key === 'name') {
      return `${user?.firstName} ${user?.lastName}`;
    }
    return data?.[key];
  };

  return (
    <div className={style.infoFormBox}>
      <h3 className={`${style.reviewTitle} textColorSuperDark `}>{t('Review Information')}</h3>
      <p className={`${style.subTitle} textColorSuperDark `}>{t('Please verify all information below is correct')}</p>
      <div className={`${style.header} superDarkGrey `}>
        <h5 className={`${style.title} textColorWhite `}>{t('Company Information')}</h5>
        <div className={style.btnEdit}>
          <GenericButton
            color="transparent"
            title={t('Edit')}
            clickHandler={() => {
              if (editLink) {
                history.push(editLink);
              } else {
                setEditMode(!editMode);
              }
            }}
          />

        </div>
      </div>
      <div>
        {titles.map((e) => (
          <div className={style.companyInfo__row}>
            <div className={style.companyInfo__label}>
              {t(e.title)}
            </div>
            <div>
              {getValue(e.key)}
            </div>
          </div>
        ))}
      </div>
      {docEditLink && (
        <div className={style.editDocBtn}>
          <Link to={typeof docEditLink === 'string' ? docEditLink : docEditLink(registrationId)}>
            <GenericButton
              outline="large"
              title={t('Edit Documents')}
            />

          </Link>
        </div>
      )}
    </div>
  );
};
CompanyInformationReview.defaultProps = {
  editLink: '',
  docEditLink: '',
};

export default CompanyInformationReview;
