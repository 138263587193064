import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ReactComponent as CompanyImg } from '../../assets/company.svg';
import { ReactComponent as CreditCardImg } from '../../assets/creditCard.svg';
import { ReactComponent as RulesImg } from '../../assets/rules.svg';
import { ReactComponent as VehicleImg } from '../../assets/vehicle.svg';
import PortalCard from '../../components/portalCard/portalCard';
import { useGetApplication } from '../../hooks/useGetApplication';
import style from './vendorHome.module.css';

export default function Guest(): React.ReactElement {
  const { t } = useTranslation();
  const history = useHistory();
  const { data: applicationData } = useGetApplication({
    onCompleted: (d) => {
      if (process.env.REACT_APP_DISABLE_INITIAL_REGISTRATION === 'true') return;
      // imediately redirect to initial registration process if not complete
      if (['incomplete', undefined].includes(d?.getApplication.data?.status)) {
        history.replace('/vendor/initial-registration');
      }
    },
  });

  return (
    <div className={style.container}>
      <div className={style.box}>
        <div className={style.box__innerBox}>
          <PortalCard
            img={<CompanyImg />}
            title={t('My Company Profile')}
            description={t('Access and edit your company profile information including company name, address, contact info, documents, and employee info. Register new employees and upload new documents.')}
            href="/vendor/company/profile"
            btnLabel={t('Edit Info')}
          />
        </div>
        {(
          ['approved', 'needs-review'].includes(applicationData?.getApplication.data?.status || '')
          || process.env.REACT_APP_DISABLE_INITIAL_REGISTRATION === 'true'
        ) && (
          <>
            <div className={style.box__innerBox}>
              <PortalCard
                img={<CreditCardImg />}
                title={t('Purchase Passes')}
                description={t('Buy new passes and update billing information.')}
                href="/vendor/registration"
                btnLabel={t('Buy a New Pass')}
              />
            </div>
            <div className={style.box__innerBox}>
              <PortalCard
                img={<VehicleImg />}
                title={t('My Passes')}
                description={t('View and manage your passes. Edit or update vehicle information. Register additional vehicles. View historical purchases and registration information. Print digital passes or share passes with employees electronically via text message or email.')}
                href="/vendor/my-passes"
                btnLabel={t('Manage Passes')}
              />
            </div>
          </>
        )}

        {/* <div className={style.box__innerBox}>
          <PortalCard
            img={<ProfileImg />}
            title={t('My Profile')}
            description={t('Access and edit your profile information including name,
            address, and contact.')}
            href="/vendor/edit-profile"
            btnLabel={t('Edit Info')}
          />
        </div> */}

        <div className={style.box__innerBox}>
          <PortalCard
            img={<RulesImg />}
            title={t('Review Community Rules')}
            description={t('Review community policies, rules, and procedures before entering the community.')}
            href="/vendor/review-rules"
            btnLabel={t('Review Rules')}
          />
        </div>
      </div>
    </div>

  );
}
