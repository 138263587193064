import { Tooltip } from '@mui/material';
import React from 'react';
import style from './HelpTooltop.module.css';

type propsType = {
  children?: React.ReactElement;
};
export function HelpTooltip(props: propsType): React.ReactElement {
  const { children } = props;

  if (!children) return <></>;
  return (
    <Tooltip
      placement="bottom"
      title={
        <div className={style.wrapper}>
          <b>For assistance:</b>
          <ul>
            <li>please call (435)612-2668</li>
            <li>
              contact us at{' '}
              <a href="https://symliv.com/get-in-touch" target="_blank" rel="noreferrer">
                https://symliv.com/get-in-touch
              </a>
            </li>
          </ul>
        </div>
      }
    >
      {children}
    </Tooltip>
  );
}
