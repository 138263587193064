import { gql, useMutation, useQuery } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router-dom';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import { CompanyInfo as TCompanyInfo, newCompanyInfo, vendorInfoConfigType } from '../../../common_lib_front/types/companyInfo';
import CompanyInformationForm from '../../../components/companyInformationForm/companyInformationForm';
import { useGetRegistration } from '../../../hooks/useRegistration';
import { useSetRegistrationStep } from '../../../hooks/useSetRegistrationStep';
import { EDIT_COMPANY_PROFILE, GET_COMPANY_PROFILE } from '../../profile/companyProfile/companyProfile';
import RegistrationNavHeader from '../registrationNavHeader/registrationNavHeader';
import style from './registrationHome.module.css';

const STEP_NUMBER = 1;

export const REGISTER_COMPANY_PROFILE = gql`
mutation registerCompanyProfile(
  $data: CompanyInfoInput!
) {
  registerCompanyProfile (
    data: $data
  ) {
    success
    error
  }
}
`;

const EDIT_STEP = gql`
  mutation editStep(
    $registrationId: String!,
  ) {
    editRegistrationStepNumber (
      registrationId: $registrationId,
      stepNumber: ${STEP_NUMBER},
    ) {
      success
      error
    }
  }
`;

const vendorInfoConfig: vendorInfoConfigType = {
  businessLicenseNumber: 'required',
  firstName: 'required',
  lastName: 'required',
  email: 'required',
  businessName: 'required',
  businessPhone: 'required',
  cellPhone: 'required',
  businessAddress: 'required',
  alternatePhone: 'optional',
  emergencyContactName: 'optional',
  businessType: 'required',
};

const companyInfoRequiredFields = ['companyName', 'altPhoneNumber', 'businessPhone', 'address', 'city', 'state', 'zipCode', 'businessType'];

export default function RegistrationHome(): React.ReactElement {
  const [alert, setAlert] = useState<string>('');
  const [profile, setProfile] = useState<TCompanyInfo>(newCompanyInfo());
  const [redirect, setRedirect] = useState<string>('');
  // * If Company profile has already been existed, just call update
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { registrationId } = useParams<{ registrationId?: string }>();
  const { communityId, featuresConfig } = useContext(CommunityContext);
  const { t } = useTranslation();

  useQuery(GET_COMPANY_PROFILE, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (d) => {
      if (d.getCompanyProfile.success) {
        setProfile(d.getCompanyProfile.data);
        setIsEdit(true);
      }
    },
  });

  const { data: regData } = useGetRegistration({
    registrationId: registrationId || '',
  }, {
    fetchPolicy: 'network-only',
  });
  const [doEditStep] = useSetRegistrationStep({
    onError: (err) => console.warn(err),
  });

  const [doRegisterCompany] = useMutation(REGISTER_COMPANY_PROFILE);

  const [doEditCompany] = useMutation(EDIT_COMPANY_PROFILE);

  // const doEditStep = () => {
  //   setRedirect(`/vendor/initial-registration/${registrationId}/education`);
  // };

  const onSubmit = async () => {
    setAlert('');
    if (!featuresConfig?.vendor?.businessTypeRequired) {
      const index: number = companyInfoRequiredFields.indexOf('businessType');
      if (index !== -1) {
        companyInfoRequiredFields.splice(index, 1);
      }
    }
    if (companyInfoRequiredFields.some((k) => !profile?.[k])) {
      setAlert('Please fill out the required info');
    } else {
      // if ((window as any).registrationLockStepNum >= 2) {
      //   setRedirect(`/vendor/registration/${registrationId}/vehicle`);
      //   return;
      // }
      try {
        if (!isEdit) {
          const paylaod = {
            data: {
              companyName: profile.companyName,
              businessLicenseNumber: profile.businessLicenseNumber,
              businessPhone: profile.businessPhone,
              address: profile.address,
              state: profile.state,
              city: profile.city,
              zipCode: profile.zipCode,
              altPhoneNumber: profile.altPhoneNumber,
              emergencyContactName: profile.emergencyContactName,
              emergencyPhoneNumber: profile.emergencyPhoneNumber,
              businessType: profile.businessType,
              firstName: profile.firstName,
              lastName: profile.lastName,
              email: profile.email,
              // companyName: profile?.companyName,
              // businessLicenseNumber: profile?.businessLicenseNumber,
              // phoneNumber: profile?.phoneNumber,
              // businessPhone: profile?.businessPhone,
              // address: profile?.address,
              // city: profile?.city,
              // state: profile?.state,
              // zipCode: profile?.zipCode,
            },
          };
          if (!featuresConfig?.vendor?.businessTypeRequired) {
            delete paylaod.data.businessType;
          }
          const doRegisterCompanyRes = await doRegisterCompany({
            variables: paylaod,
          });

          if (!doRegisterCompanyRes?.data?.registerCompanyProfile?.success) {
            setAlert(doRegisterCompanyRes?.data?.registerCompanyProfile?.error);
            return;
          }
        } else {
          const paylaod = {
            data: {
              // firstName: profile.firstName,
              // lastName: profile.lastName,
              // email: profile.email,
              companyName: profile.companyName,
              businessLicenseNumber: profile.businessLicenseNumber,
              businessPhone: profile.businessPhone,
              address: profile.address,
              state: profile.state,
              city: profile.city,
              zipCode: profile.zipCode,
              altPhoneNumber: profile.altPhoneNumber,
              emergencyContactName: profile.emergencyContactName,
              emergencyPhoneNumber: profile.emergencyPhoneNumber,
              businessType: profile.businessType,
              firstName: profile.firstName,
              lastName: profile.lastName,
              email: profile.email,
              // companyName: profile?.companyName,
              // businessLicenseNumber: profile?.businessLicenseNumber,
              // phoneNumber: profile?.phoneNumber,
              // businessPhone: profile?.businessPhone,
              // address: profile?.address,
              // city: profile?.city,
              // state: profile?.state,
              // zipCode: profile?.zipCode,
            },
          };
          if (!featuresConfig?.vendor?.businessTypeRequired) {
            delete paylaod.data.businessType;
          }
          const doEditCompanyRes = await doEditCompany({
            variables: paylaod,
          });

          if (!doEditCompanyRes?.data?.editCompanyProfile?.success) {
            setAlert(doEditCompanyRes?.data?.editCompanyProfile?.error);
            return;
          }
        }
      } catch (e) {
        if (typeof e === 'string') {
          setAlert(e);
        } if (e instanceof Error) {
          setAlert(e.message);
        }
        console.error(e);
        return;
      }

      const currentStepNum = regData?.getRegistration.data?.[0]?.stepNumber;
      if (!currentStepNum || currentStepNum < 2) {
        await doEditStep({
          variables: {
            registrationId: registrationId || '',
            stepNumber: 2,
          },
        });
      }
      setRedirect(`/vendor/initial-registration/${registrationId}/education`);
    }
  };

  if (redirect) {
    return (
      <Redirect to={{
        pathname: redirect,
        state: { businessType: profile.businessType || null },
      }}
      />
    );
  }

  return (
    <div className={style.container}>
      <div className={`${style.box} white `}>
        <RegistrationNavHeader
          activeNum={1}
          title={`${t('Step 1')}: ${t('Register Your Company')}`}
          subtitle={t('Please provide the required information below')}
        />
        <GenericAlert
          color="red"
          title={alert}
          hidden={!alert}
        />
        <div className={style.flexBox}>
          <div>
            <CompanyInformationForm
              data={profile}
              setData={setProfile}
              vendorInfo={vendorInfoConfig}
              autofillFromUser
            />
          </div>
        </div>

        <div className={style.btn}>
          <GenericButton
            size="large"
            color="blue"
            title="Continue"
            clickHandler={onSubmit}
          />
        </div>
      </div>
    </div>
  );
}
