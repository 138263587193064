import React, {
  ReactElement, useContext, useEffect, useMemo, useState,
} from 'react';
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import { CommunityContext } from './common_lib_front/communityConfigs/communityContextProvider';
import * as CommonPages from './common_lib_front/pages';
import store, { isLogedIn as reduxIsLogedIn } from './common_lib_front/utilities/store';
import useUserGuiding from './common_lib_front/utilities/useUserGuiding';
import CompanyInfo from './components/companyInfo/companyInfo';
import { Footer } from './components/footer/footer';
import Header from './components/header/header';
import './css';
import useChatbot from './hooks/useChatbot';
import { useGetApplication } from './hooks/useGetApplication';
import * as Pages from './pages';

const alwaysRoutes: ReactElement[] = [
  <Route path="/guest/privacy-policy">
    <CommonPages.PrivacyPolicy />
  </Route>,
  <Route path="/guest/terms-conditions">
    <CommonPages.TermsAndConditions />
  </Route>,
  <Route path="/guest/help">
    <CommonPages.HelpPage />
  </Route>,
  <Route path="/vendor/help">
    <CommonPages.HelpPage
      role="vendor" // eslint-disable-line
    />
  </Route>,
];

// contains default route must be last
const notLoggedInRoutes: ReactElement[] = [
  <Route exact path="/create-account">
    <CommonPages.CreateAccount
      role="vendor" // eslint-disable-line
      successHref="/vendor"
    />
  </Route>,
  <Route exact path="/login">
    <CommonPages.Login
      role="vendor" // eslint-disable-line
      successHref="/vendor"
    />
  </Route>,
  <Route exact path="/forgot-password">
    <CommonPages.ForgotPasswordEmail />
  </Route>,
  <Route exact path="/forgot-password/verification-code">
    <CommonPages.ForgotPasswordVerification />
  </Route>,
  <Route path="/forgot-password/reset-password/:identifier">
    <CommonPages.ForgotPasswordReset />
  </Route>,
  // default route if not loged in
  <Route path="/">
    <Redirect to="/login" />
  </Route>,
];

const loggedInAlwaysRoutes: ReactElement[] = [
  <Route exact path="/vendor">
    <Pages.VendorHome />
  </Route>,
  <Route path="/vendor/company/profile">
    <CompanyInfo
      active="profile"
    >
      <Pages.CompanyProfile />
    </CompanyInfo>
  </Route>,
  <Route exact path="/vendor/edit-profile/reset-password">
    <Pages.EditProfileVendor active="password">
      <Pages.ResetPassword />
    </Pages.EditProfileVendor>
  </Route>,
  <Route path="/vendor/company/documents">
    <CompanyInfo
      active="documents"
    >
      <Pages.CompanyDocuments />
    </CompanyInfo>
  </Route>,
  <Route path="/vendor/popup/delete-document/:documentId">
    <Pages.DeleteDocument />
  </Route>,

  <Route exact path="/vendor/edit-profile">
    <Redirect to="/vendor/edit-profile/my-profile" />
  </Route>,
  <Route exact path="/vendor/edit-profile/my-profile">
    <Pages.EditProfileVendor active="profile">
      <Pages.MyProfile />
    </Pages.EditProfileVendor>
  </Route>,
  <Route path="/vendor/review-rules">
    <Pages.ReviewRules />
  </Route>,
];

const vehicleRoutes: ReactElement[] = [
  <Route path="/vendor/company/vehicles">
    <CompanyInfo
      active="vehicles"
    >
      <CommonPages.VehicleList addEditLink="/vendor/popup/add-edit-vehicle" />
    </CompanyInfo>
  </Route>,
  <Route path={['/vendor/popup/add-edit-vehicle/:vehicleId', '/vendor/popup/add-edit-vehicle']}>
    <CommonPages.VehicleDataFormPopup />
  </Route>,
];
const employeeRoutes: ReactElement[] = [
  <Route path="/vendor/company/employees">
    <CompanyInfo
      active="employees"
    >
      <Pages.Employee />
    </CompanyInfo>
  </Route>,
  <Route path="/vendor/popup/delete-employee/:employeeId">
    <Pages.DeleteEmployee />
  </Route>,
  <Route path="/vendor/popup/edit-employee/:employeeId">
    <Pages.EditEmployee />
  </Route>,
  <Route path="/vendor/popup/add-employee">
    <Pages.AddEmployee />
  </Route>,
];

const myPassesRoutes: ReactElement[] = [
  <Route path="/vendor/my-passes">
    <Pages.MyPasses />
  </Route>,
  <Route path="/vendor/popup/complete-pass/:passId">
    <Pages.CompletePass />
  </Route>,
  <Route path="/vendor/popup/refund-pass/:passId">
    <Pages.RefundPass />
  </Route>,
  <Route path="/vendor/registration">
    <Pages.PurchasePassProcess />
  </Route>,
  // purchase pass
  // <Route exact path="/vendor/registration/:registrationId/receipt">
  //   <Pages.OrderSummary />
  // </Route>,
  // <Route exact path="/vendor/registration/:registrationId/generate-passes">
  //   <Pages.GeneratePasses />
  // </Route>,
  // <Route exact path="/vendor/registration/:registrationId/secure-checkout">
  //   <Pages.SecureCheckout />
  // </Route>,
  // <Route exact path="/vendor/registration/:registrationId/documents">
  //   <Pages.UploadDocuments />
  // </Route>,
  // <Route exact path="/vendor/registration/:registrationId/vehicle/">
  //   <Pages.RegisterVehicle />
  // </Route>,
  // <Route exact path="/vendor/registration/:registrationId/community-orientation">
  //   <Pages.CommunityOrientation />
  // </Route>,
  // <Route exact path="/vendor/registration/:registrationId">
  //   <Pages.RegistrationHome />
  // </Route>,
  // <Route exact path="/vendor/registration/:registrationId/pass-selection">
  //   <Pages.PassSelection />
  // </Route>,
  // <Route path="/vendor/registration">
  //   <Pages.CreateRegistration />
  // </Route>,
];

// contains default route must be last
const applicationNotApprovedRoutes = (props: { includeDefault: boolean }): React.ReactElement[] => [
  <Route path="/vendor/initial-registration">
    <Pages.InitialRegistration />
  </Route>,
  ...(props.includeDefault
    ? [
      <Route path="/">
        <Redirect to="/vendor/initial-registration" />
      </Route>,
    ] : []),
];

export default function App(): ReactElement {
  const { communityId: domain } = useContext(CommunityContext);
  const [isLogedin, setIsLogedIn] = useState<boolean>(reduxIsLogedIn());
  useEffect(
    () => store.subscribe(() => setIsLogedIn(reduxIsLogedIn())),
    [setIsLogedIn],
  );
  useChatbot('vendor');

  const { data: applicationData } = useGetApplication({
    // eslint-disable-next-line no-console
    onError: (err) => console.warn(err),
    skip: !isLogedin,
  });

  useUserGuiding('vendor');

  const routes: (ReactElement | ReactElement[])[] = useMemo(() => {
    const res = [alwaysRoutes];
    if (isLogedin) {
      res.push(loggedInAlwaysRoutes);
      if (
        ['approved', 'needs-review'].includes(applicationData?.getApplication.data?.status || '')
        || process.env.REACT_APP_DISABLE_INITIAL_REGISTRATION === 'true'
      ) {
        res.push(myPassesRoutes);
        res.push(vehicleRoutes);
        res.push(employeeRoutes);
      } else if (!applicationData || !['approved', 'needs-review'].includes(applicationData?.getApplication.data?.status || '')) {
        // contains default route must be last
        res.push(applicationNotApprovedRoutes({
          includeDefault: !!applicationData?.getApplication.success,
        }));
      }
      if (process.env.REACT_APP_DISABLE_INITIAL_REGISTRATION === 'true') {
        // if registration is disabled, add routes anyway for validation
        // contains default route must be last
        res.push(applicationNotApprovedRoutes({
          includeDefault: false,
        }));
      }
    } else {
      // contains default route must be last
      res.push(notLoggedInRoutes);
    }
    return res;
  }, [isLogedin, applicationData?.getApplication.data?.status]);

  return (
    <div className="container">
      <Router basename={`/${domain}`}>
        <Header />
        <Switch>
          {routes}
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}
