import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import GenericAlert from '../../components/genericAlert/genericAlert';
import GenericButton from '../../components/genericButton/genericButton';
import LoadingDiamonds from '../../components/loadingDiamonds/loadingDiamonds';
import { useCompanyVehicles } from '../../hooks/useCompanyVehicles';
import VehicleInfo, { newVehicleInfo } from '../../types/vehicleInfo';
import VehicleListAGGrid from './vehicleListAGGrid';
import style from './vehicles.module.css';

type propsType = {
  addEditLink: string;
  excludeFields?: string[];
};

export default function VehicleListPage(props: propsType): React.ReactElement {
  const { addEditLink, excludeFields } = props;
  // const [alert, setAlert] = useState<string | undefined>(undefined);
  const { data, loading, error, refetch } = useCompanyVehicles({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });
  useEffect(() => {
    const elem = document.body;
    const handler = () => {
      refetch();
    };
    elem?.addEventListener('vehicle-data-invalidation', handler);
    return () => elem?.removeEventListener('vehicle-data-invalidation', handler);
  }, [refetch]);

  return (
    <div className={style.container}>
      <div className={style.box}>
        <div className={`${style.infoBox} white`}>
          <div className={`${style.subHeader} mainColor`}>
            <div className={style.subHeaderLeft}>
              <div className={`${style.circle} white`} />
              <h1 className={`${style.title} textColorWhite`}>Vehicles</h1>
            </div>
            <div className={style.subHeaderRight}>
              <Link
                to={{
                  pathname: addEditLink,
                }}
              >
                <GenericButton
                  color="yellow"
                  size="large"
                  title="Add vehicle +"
                  className={style.addBtn}
                  type="button"
                />
              </Link>
            </div>
          </div>
          <div className={style.mainContent}>
            <GenericAlert
              color="red"
              title="Something went wrong. Could not get data."
              hidden={!error}
            />
            <GenericAlert
              color="red"
              title={data?.getCompanyVehicles.error}
              hidden={!data?.getCompanyVehicles.error}
            />
            <div className={style.tableBox}>
              {loading ? (
                <LoadingDiamonds title="Loading Vehicles" />
              ) : (
                <VehicleListAGGrid
                  addEditLink={addEditLink}
                  excludeFields={excludeFields}
                  data={(data?.getCompanyVehicles?.data || []).map(v =>
                    newVehicleInfo(v as unknown as Partial<VehicleInfo>),
                  )}
                  // data={vehiclePassesList.map((d) => newVehicleInfo(d))}
                />
              )}
              {/* <table width="100%" cellSpacing="0px" className={style.table}>
                <thead>
                  <tr className={`${style.tableHeader} mainColor`}>
                    <th className={`${style.tableHeaderItem} textColorWhite`}>Last Name</th>
                    <th className={`${style.tableHeaderItem} textColorWhite`}>First Name</th>
                    <th className={`${style.tableHeaderItem} textColorWhite`}>Phone Number</th>
                    <th className={`${style.tableHeaderItem} textColorWhite`}>Email</th>
                    <th className={`${style.tableHeaderItem} textColorWhite`}>&nbsp;</th>
                  </tr>
                </thead>
                <tbody />
              </table> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
