import { gql, useQuery } from '@apollo/client';
import React, {
  useContext, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import { IDocumentConfig } from '../../common_lib_front/types/documentInfo';
import store, { documentConfig as reduxDocumentConfig } from '../../common_lib_front/utilities/store';
import style from './registrationNavHeader.module.css';
import RegistrationNavTile from './registrationNavTile';

const GET_REGISTRATION = gql`
  query GetRegistration (
    $registrationId: String!
  ) {
    getRegistration (
      registrationId: $registrationId
    ) {
      success
      error
      data {
        complete
        stepNumber
      }
    }
  }
`;

type Params = {
  registrationId: string;
}

type RegistrationNavHeaderProps = {
  title: string;
  subtitle: string;
  activeNum?: number;
}

export const RegistrationNavHeader = (props: RegistrationNavHeaderProps): React.ReactElement => {
  const { registrationId } = useParams<Params>();
  const { title, subtitle, activeNum } = props;
  const [completeNum, setCompleteNum] = useState<number>(0);
  const [completeAll, setCompleteAll] = useState<boolean>(false);
  const { name: communityName, communityId } = useContext(CommunityContext);

  useQuery(GET_REGISTRATION, {
    variables: { registrationId },
    fetchPolicy: 'no-cache',
    onError: () => {
      //
    },
    onCompleted: (d) => {
      // console.log(d.getRegistration.data[0]);
      if (d.getRegistration.success && d.getRegistration.data.length > 0) {
        setCompleteNum(d.getRegistration.data[0].stepNumber);
        setCompleteAll(d.getRegistration.data[0].complete);
      }
    },
  });

  useEffect(() => {
    (window as any).registrationLockStepNum = completeNum;
    return () => {
      (window as any).registrationLockStepNum = undefined;
    };
  }, [completeNum]);

  const { t } = useTranslation();

  return (
    <div className={style.navHeaderBox}>
      <div className={style.textBox}>
        <h2 className={`${style.titleGrey} textColorSuperDark `}>
          {t('Welcome to')}
          {' '}
          <p className={`${style.titleBlue} textColorMain `}>{communityName}</p>
        </h2>
        <div className={style.registrationBox}>
          <p className={`${style.text} textColorSuperDark `}>
            {t('registration_instructions')}
          </p>
        </div>
        {communityId === 'isleworth' && (
          <div className={style.instructionBox}>
            <div className={style.marginAutoText}>
              <p className={style.instructionTitle}>
                Important: Prior to purchasing an Employee Annual Pass or Project Pass
                <br />
                please ensure you complete an employee record with upload of a valid
                identification for each employee
                <br />
                Ensure the image is legible and cropped down to where only the ID is visible
                (no borders).
              </p>
            </div>
          </div>
        )}
        {
          communityId === 'sipoa' && activeNum === 0
            ? (
              <div className={style.instructionBox}>
                <div className={style.centerText}>
                  <p className={style.instructionTitle}>
                    {' '}
                    {t('Please note the following rates are applied depending on the quantity of passes you purchase:')}
                  </p>
                  <p>
                    -
                    {' '}
                    {t('Annual 2 Axles - When you buy up to 3 vehicle barcodes, the price is $300.00 per barcode. For purchases of 4 barcodes, the price is $275.00 each ($1,100 total). For purchases of more than 4, the price is $275.00 for the first four, then $150.00 per barcode for each additional vehicle.')}
                  </p>
                  <p>
                    -
                    {' '}
                    {t('Annual 3 Axles - When you buy up to 3 vehicle barcodes, the price is $450.00 per barcode. For purchases of 4 barcodes, the price is $425.00 each ($1,700 total). For purchases of more than 4, the price is $425.00 for the first four, then $225.00 per barcode for each additional vehicle.')}
                  </p>
                  <p>
                    -
                    {' '}
                    {t('Annual 4+ Axles - When you buy up to 3 vehicle barcodes, the price is $650.00 per barcode. For purchases of 4 barcodes, the price is $625.00 each ($2,500 total). For purchases of more than 4, the price is $625.00 for the first four, then $325.00 per barcode for each additional vehicle.')}
                  </p>
                  <p>
                    -
                    {' '}
                    {t('Annual Cement - For all purchases regardless of quantity, the price is $1,200.00 per barcode.')}
                  </p>
                  <p>
                    <b>
                      For
                      {' '}
                      <u>Replacement Barcodes</u>
                      {' '}
                      and
                      {' '}
                      <u>Loaner Barcode</u>
                      :
                      please contact the Seabrook Island POA, (843) 725-1564, 1202 Landfall Way.
                    </b>
                  </p>
                  <p className={style.instructionTitle}>
                    Be prepared to upload the following items: Driver&apos;s License,
                    Vehicle Registration, and Town of Seabrook Island Business License
                  </p>
                </div>
                {/* <div className={style.rightText}>
                  <p className={style.instructionTitle}>
                    { t('For daily passes, the rates are as follows:') }
                  </p>
                  <p>
                    -
                    {' '}
                    { t('2 Axles - One Day: $20.00, Two Days: $40.00, Five Days: $90.00') }
                  </p>
                  <p>
                    -
                    {' '}
                    { t('Trailer: Free') }
                  </p>
                  <p>
                    -
                    {' '}
                    { t('3 Axles - One Day: $35.00, Two Days: $70.00, Five Days: $165.00') }
                  </p>
                  <p>
                    -
                    {' '}
                    { t('4+ Axles - One Day: $45.00, Two Days: $90.00, Five Days: $210.00') }
                  </p>
                  <p>
                    -
                    {' '}
                    { t('Cement - One Day: $100.00') }
                  </p>
                  <p className={style.instructionTitle}>
                    { t('Please note, all purchases of multiple daily passes must be for
                     consecutive days in a single week, starting Monday through Saturday.') }
                  </p>
                  <p className={style.instructionTitle}>
                    Be prepared to upload a picture of your driver&apos;s license
                  </p>

                </div> */}
              </div>
            ) : null
        }
      </div>
      {activeNum !== 0 ? (
        <div className={style.iconBox}>
          {/* <RegistrationNavTile
            num={1}
            activeNum={activeNum || 0}
            completeNum={completeNum}
            completeAll={completeAll}
            imgName="DocumentsImg"
            title={t('Step 1')}
            text={t('Register Your Company')}
            href={`/vendor/registration/${registrationId}`}
          /> */}
          {/* <span className={`${style.line} specialGrey `} /> */}
          <RegistrationNavTile
            num={1}
            activeNum={activeNum || 0}
            completeNum={completeNum}
            completeAll={completeAll}
            imgName="VehicleImg"
            title={t('Step 1')}
            text={t('Complete Your Passes')}
            href={`/vendor/registration/${registrationId}/vehicle`}
          />
          <span className={`${style.line} specialGrey `} />
          <RegistrationNavTile
            num={2}
            activeNum={activeNum || 0}
            completeNum={completeNum}
            completeAll={completeAll}
            imgName="RulesImg"
            title={t('Step 2')}
            text={t('Community Orientation')}
            href={`/vendor/registration/${registrationId}/community-orientation`}
          />
          <span className={`${style.line} specialGrey `} />
          <RegistrationNavTile
            num={3}
            activeNum={activeNum || 0}
            completeNum={completeNum}
            completeAll={completeAll}
            imgName="CreditCardImg"
            title={t('Step 3')}
            text={t('Secure Checkout')}
            href={`/vendor/registration/${registrationId}/secure-checkout`}
          />

        </div>
      ) : null}
      <h2 className={`${style.title} textColorSuperDark `}>{title}</h2>
      <p className={`${style.subTitle} textColorSuperDark `}>{subtitle}</p>
    </div>
  );
};

RegistrationNavHeader.defaultProps = {
  activeNum: 0,
};

export default RegistrationNavHeader;

// {
//             // documentConfig && documentConfig.length > 0 ? (
//             //   <>
//             //     <span className={`${style.line} specialGrey `} />
//             //     <RegistrationNavTile
//             //       num={4}
//             //       activeNum={activeNum || 0}
//             //       completeNum={completeNum}
//             //       completeAll={completeAll}
//             //       imgName="DocumentUpload"
//             //       title={t('Step 4')}
//             //       text={t('Upload Documents')}
//             //       href={`/vendor/registration/${registrationId}/documents`}
//             //     />
//             //     <span className={`${style.line} specialGrey `} />
//             //     <RegistrationNavTile
//             //       num={5}
//             //       activeNum={activeNum || 0}
//             //       completeNum={completeNum}
//             //       completeAll={completeAll}
//             //       imgName="CreditCardImg"
//             //       title={t('Step 5')}
//             //       text={t('Secure Checkout')}
//             //       href={`/vendor/registration/${registrationId}/secure-checkout`}
//             //     />
//             //   </>
//             // ) : (
//             //   <>
//             //     <span className={`${style.line} specialGrey `} />
//             //     <RegistrationNavTile
//             //       num={4}
//             //       activeNum={activeNum || 0}
//             //       completeNum={completeNum}
//             //       completeAll={completeAll}
//             //       imgName="CreditCardImg"
//             //       title={t('Step 4')}
//             //       text={t('Secure Checkout')}
//             //       href={`/vendor/registration/${registrationId}/secure-checkout`}
//             //     />
//             //   </>
//             // )
//           }
