import { gql, useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { backendResponse } from '../../../../common_lib_front/types/backendResponse';
import { registrationStepProps } from '../wrapper';

const GET_REGISTRATION = gql`
  query GetRegistration {
    getRegistrationData {
      success
      error
      data {
        registrationId
        renew
        complete
      }
    }
  }
`;

type GET_REGISTRATION_RES = {
  getRegistrationData: backendResponse<{
    complete: boolean;
    registrationId: string;
    renew: boolean;
  }[]>
}

const CREATE_REGISTRATION = gql`
  mutation CreateRegistration ($type: String!) {
    createRegistration (type: $type) {
      success
      error
      data {
        registrationId
      }
    }
  }
`;

type CREATE_REGISTRATION_RES = {
  createRegistration: backendResponse<{
    registrationId: string;
  }[]>
}

export default function CreateRegistration(props: registrationStepProps): React.ReactElement {
  const { nextPath } = props;
  const { registrationId } = useParams<{ registrationId?: string }>();
  const [redirect, setRedirect] = useState<string>('');
  const [errMessage, setErrMessage] = useState<string>('');

  const [doCreateRegistration, { error }] = useMutation<
    CREATE_REGISTRATION_RES
  >(CREATE_REGISTRATION, {
    fetchPolicy: 'no-cache',
    variables: {
    },
    onError: (e) => {
      if (process.env.REACT_APP_DEBUG === 'true') {
        // setRedirect('/vendor/registration/0');
        setRedirect(nextPath('0'));
      }
    },
    onCompleted: (d) => {
      // setRedirect(`/vendor/registration/${d.createRegistration.dat
      // a[0].registrationId}/pass-selection`);
      if (d.createRegistration.data?.[0]?.registrationId) {
        setRedirect(nextPath(d.createRegistration.data[0].registrationId));
      } else {
        setErrMessage(d.createRegistration.error);
      }
    },
  });

  useQuery<GET_REGISTRATION_RES>(GET_REGISTRATION, {
    skip: !!registrationId,
    fetchPolicy: 'no-cache',
    onError: () => {
      doCreateRegistration({
        variables: {
          type: 'vendor',
        },
      });
    },
    onCompleted: (d) => {
      // console.log(d);
      if (
        d.getRegistrationData.success
        && d.getRegistrationData.data instanceof Array
        && d.getRegistrationData.data?.length > 0
      ) {
        // setRedirect(`/vendor/registration/${d.getRegistrationData.data[0]
        // .registrationId}/pass-selection`);
        if (d.getRegistrationData.data && d.getRegistrationData.data.length > 0) {
          if (!d.getRegistrationData.data[d.getRegistrationData.data.length - 1].renew
            && !d.getRegistrationData.data[d.getRegistrationData.data.length - 1].complete
          ) {
            // when pass is not for renew and pass last record is not completed
            setRedirect(
              nextPath(
                d.getRegistrationData.data[d.getRegistrationData.data.length - 1].registrationId,
              ),
            );
          } else {
            doCreateRegistration({
              variables: {
                type: 'vendor',
              },
            });
          }
        }
      } else {
        doCreateRegistration({
          variables: {
            type: 'vendor',
          },
        });
      }
    },
  });

  if (redirect) return <Redirect to={redirect} />;
  if (registrationId) return <Redirect to={nextPath(registrationId)} />;
  if (error) return <div>{`Error! ${error.message}`}</div>;
  if (errMessage) return <div>{`Error! ${errMessage}`}</div>;
  return <div>Loading ...</div>;
}
