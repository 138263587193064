import { gql, useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box } from '@mui/material';
import { ColumnState } from 'ag-grid-community';
import alertPic from '../../assets/alert.svg';
import { IdentificationTypes } from '../../common_lib/types/IdentificationTypes';
import { GenericButton } from '../../common_lib_front/components/genericButton/genericButton';
import LoadingDiamonds from '../../common_lib_front/components/loadingDiamonds/loadingDiamonds';
import { backendResponse } from '../../common_lib_front/types/backendResponse';
import EmployeeInfo from '../../common_lib_front/types/employeeInfo';
import PassInfo, { passStatusMapTitles, paymentStatusMapTitles } from '../../common_lib_front/types/passInfo';
import { passCredOrNumber } from '../../common_lib_front/utilities/passCredOrNumber';
import { getPassImage } from '../../hooks/useGetPassImage';
import CommonDialog from '../commonDialog/commonDialog';
import { downloadablePassGenerator } from '../passGenerator/passGenerator';
import SharePassDialog from '../sharePassDialog/sharePassDialog';
import { csvExport } from './csvExport';
import dataGridStyle from './myPassGrid.module.css';
import { useColDefs } from './useColDefs';
import GridColumnOrderMenu from '../../common_lib_front/components/gridColumnOrderMenu';
import { useUsers } from '../../common_lib_front/hooks/useUsers';

let passElem: React.ReactElement;
let takeScreenshot: () => void;

export type MyPassGridProps = {
  data: PassInfo[];
  showExpired: boolean;
  employees: EmployeeInfo[];
  onReload: () => void;
  onAlert: (someAlert: any) => void;
};

const DELETE_PASS = gql`
  mutation DeletePass($passId: String!) {
    deletePass(passId: $passId) {
      success
      error
    }
  }
`;

type DELETE_PASS_VARS = {
  passId: string;
};

type DELETE_PASS_RES = {
  deletePass: backendResponse<undefined>;
};

export default function MyPassGrid(props: MyPassGridProps): ReactElement {
  const {
    data, employees, onReload, showExpired, onAlert,
  } = props;

  const [shareInfoDialog, setShareInfoDialog] = useState<boolean>(false);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [showInfoDialog, setShowInfoDialog] = useState<boolean>(false);
  const [passDeleteMode, setPassDeleteMode] = useState<boolean>(false);
  const [shareDialog, setShareDialog] = useState<boolean>(false);
  const [bulkSelectedData, setBulkSelectedData] = useState<PassInfo[]>();
  const [deleteTitleSTR, setDeleteTitleSTR] = useState<string>('');
  const { getCurrentUserInformation } = useUsers();

  const { colDefs, currentPass, passInfoMode } = useColDefs({
    bulkSelectedData,
    showExpired,
    setShareDialog,
    setShowInfoDialog,
    setPassDeleteMode,
    setBulkSelectedData,
  });

  const gridRef = useRef<AgGridReact>(null); // for accessing Grid's API
  if (currentPass) {
    [passElem, takeScreenshot] = downloadablePassGenerator({
      pass: currentPass,
    });
  }
  const rowData = useMemo(
    () => data.flatMap((elem) => ({
      passType: elem.passInfo ? elem.passInfo.name : '',
      passId: elem.passId,
      // passNumber: elem.number ? elem.number : '',
      passNumber: passCredOrNumber(elem),
      paid: elem.paid ? elem.paid : '',
      addons: elem.addons ? elem.addons : '',
      destination: elem.vehicle?.destination || '',
      startDate: elem.startDate ? elem.startDate.slice(0, 10) : '',
      validThru: elem.endDate || '',
      passShared: elem.sharedWith.length > 0 ? 'Yes' : 'No',
      passStatus: elem.status || '',
      passPrice: elem.priceInfo?.price || '',
      actions: elem,
      make: elem.vehicle.make || '',
      model: elem.vehicle.model || '',
      color: elem.vehicle.color || '',
      platenumber: elem.vehicle.plateNumber || '',
      primarydrivername: elem.vehicle.primaryDriverName || '',
      registrationId: elem.registrationId || '',
      source:
          IdentificationTypes[
            elem.vehicle
              .identificationSource as keyof typeof IdentificationTypes
          ] || '',
      identificationNumber: elem.vehicle.licensePrimaryDriver || '',
    })),
    [data],
  );

  const isMultipleOptionSelection = !!(
    bulkSelectedData && bulkSelectedData.length > 0
  );

  const [doDeleteUser] = useMutation<DELETE_PASS_RES, DELETE_PASS_VARS>(
    DELETE_PASS,
    {
      onCompleted: (resp) => {
        if (resp.deletePass.success) {
          Sentry.captureMessage('deleted pass', {
            extra: {
              data: resp,
            },
          });
          onAlert({
            color: 'green',
            message: `${
              isMultipleOptionSelection ? 'Passes' : 'Pass'
            } Deleted Successfully!`,
          });
        } else {
          onAlert({
            color: 'red',
            message: 'Pass could not be deleted',
          });
        }
        setPassDeleteMode(false);
        onReload();
      },
      onError: (err) => {
        Sentry.captureMessage('failed to delete pass', {
          extra: {
            data: err,
          },
        });
        onAlert({
          color: 'red',
          message: err.message,
        });
        setPassDeleteMode(false);
        onReload();
      },
    },
  );

  // DefaultColDef sets props common to all Columns
  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    wrapHeaderText: true,
  };

  const onBtnExport = useCallback(
    () => csvExport({
      colDefs,
      gridRef,
    }),
    [colDefs],
  );

  // Quick filter event handler
  const onFilterTextChange = (e: { target: { value: string } }) => {
    gridRef.current?.api.setQuickFilter(e.target.value);
  };

  const onRowSelected = useCallback((event) => {
    // console.log(event.node.isSelected());
    // console.log('row selected', event?.node?.data);
  }, []);

  const onSelectionChanged = useCallback((event) => {
    const selectedRowsData = event.api
      .getSelectedNodes()
      .map((ele: { data: Record<string, unknown> }) => ele.data) || [];
    setBulkSelectedData(selectedRowsData);
    setShowInfoDialog(false);
    // console.log('row selected change', selectedRowsData);
  }, []);

  const multipleDownload = () => {
    if (bulkSelectedData && bulkSelectedData.length > 0) {
      bulkSelectedData.forEach(async (ele) => {
        const a = document.createElement('a');
        const url = await getPassImage(ele.actions.passId)
          .then((res) => res.url)
          .catch((err): string | null => {
            console.error(err);
            if (typeof ele.actions.url === 'string' && ele.actions.url.length > 5) {
              return ele.actions.url;
            }
            onAlert({
              color: 'red',
              message: 'Something went wrong. We are unable to download one or all of your passes.',
            });
            return null;
          });
        a.target = '_blank';
        // a.href = ele.actions.url || '';
        if (url) {
          a.href = url;
          a.click();
        }
      });
    }
  };

  useEffect(() => {
    if (isMultipleOptionSelection) {
      setDeleteTitleSTR(
        `${
          bulkSelectedData && bulkSelectedData.length > 0
            ? bulkSelectedData.map((ele) => ele.passNumber).join(', ')
            : ''
        }`,
      );
    } else if (currentPass) {
      setDeleteTitleSTR(currentPass.passNumber);
    } else {
      setDeleteTitleSTR('');
    }
  }, [passDeleteMode]);

  useEffect(() => {
    if (showInfoDialog && isMultipleOptionSelection) {
      multipleDownload();
    }
  }, [showInfoDialog]);

  const deletePasses = () => {
    if (
      isMultipleOptionSelection
      && bulkSelectedData
      && bulkSelectedData.length > 0
    ) {
      bulkSelectedData.forEach((ele) => {
        doDeleteUser({
          variables: {
            passId: ele.passId,
          },
        });
      });
    }

    if (!isMultipleOptionSelection && currentPass && currentPass.passId) {
      doDeleteUser({
        variables: {
          passId: currentPass.passId,
        },
      });
    }
  };

  const restoreState = useCallback(async () => {
    const userInfo = await getCurrentUserInformation();
    const gridSettings = userInfo?.gridSettings?.grids.filter((grid: { gridName: string; }) => grid.gridName === 'my-passes');
    if (!gridSettings || !gridSettings?.[0]) {
      console.log('no columns state to restore by, you must save state first');
      return;
    }

    if (gridSettings || gridSettings?.[0]) {
      const currentColState = gridSettings?.[0];
      gridRef.current!.columnApi.applyColumnState({
        state: currentColState.columns as unknown as ColumnState[],
        applyOrder: true,
      });
    }
  }, []);

  const onFirstDataRendered = useCallback(() => {
    restoreState();
  }, [restoreState]);

  return (
    <div>
      <div className={dataGridStyle.topLine}>
        <input
          type="search"
          className={dataGridStyle.gridSearch}
          onChange={onFilterTextChange}
          placeholder="Search anything here .."
        />

        <Box
          sx={{
            flexGrow: 1,
            display: {
              xs: 'none', md: 'flex', justifyContent: 'right', mr: 2,
            },
          }}
        >
          <GridColumnOrderMenu agGridRef={gridRef} referenceGrid="my-passes" onAlert={onAlert} />
        </Box>
        <Box sx={{ ml: 1 }}>
          <button className={dataGridStyle.btnExport} onClick={onBtnExport}>
            Export CSV
          </button>
        </Box>
      </div>
      <div className={dataGridStyle.paginationBox}>
        <label htmlFor="page-num-inpt">
          Show
          <select
            className={dataGridStyle.selectBox}
            value={itemsPerPage}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setItemsPerPage(Number.parseInt(e.target.value, 10));
            }}
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
          Per Page
        </label>
      </div>
      <div className={dataGridStyle.radiusBox}>
        <div
          className="ag-theme-alpine"
          style={{
            height: '100%',
            width: '100%',
            overflow: 'scroll',
          }}
        >
          <AgGridReact
            rowData={
              passInfoMode === 'vehicle' && isMultipleOptionSelection
                ? bulkSelectedData
                : rowData
            }
            // columnDefs={passInfoMode ? passInfoColumnDefs : vehicleInfoColumnDefs}
            columnDefs={colDefs}
            defaultColDef={defaultColDef}
            enableCellTextSelection
            ensureDomOrder
            animateRows
            pagination
            paginationPageSize={itemsPerPage}
            suppressRowClickSelection
            rowSelection="multiple"
            onRowSelected={onRowSelected}
            onSelectionChanged={onSelectionChanged}
            onFirstDataRendered={onFirstDataRendered}
            onGridReady={onFirstDataRendered}
            // paginationAutoPageSize
            ref={gridRef}
          />

          {!isMultipleOptionSelection && showInfoDialog && (
            <CommonDialog
              displayTop
              showDialog={showInfoDialog}
              onClose={() => {
                setShowInfoDialog(false);
                onReload();
              }}
              title={currentPass === null ? '' : passCredOrNumber(currentPass)}
              contentExtended
            >
              {currentPass !== null ? (
                <div className={dataGridStyle.infoDialog}>
                  <div className={dataGridStyle.infoBox} />
                  <div
                    className={`${dataGridStyle.infoBox} ${dataGridStyle.passBox}`}
                  >
                    {passElem || null}
                  </div>
                  <div
                    className={`${dataGridStyle.infoDialogTool} ${dataGridStyle.infoBox}`}
                  >
                    <GenericButton
                      className={dataGridStyle.infoDialogToolBtn}
                      title="Share Pass"
                      color="yellow"
                      size="large"
                      clickHandler={() => setShareDialog(true)}
                    />
                    <GenericButton
                      className={dataGridStyle.infoDialogToolBtn}
                      title="Download & Print"
                      color="blue"
                      size="large"
                      clickHandler={() => takeScreenshot && takeScreenshot()}
                    />
                  </div>
                </div>
              ) : (
                <LoadingDiamonds />
              )}
            </CommonDialog>
          )}

          <CommonDialog
            showDialog={passDeleteMode}
            onClose={() => {
              setPassDeleteMode(false);
              onReload();
            }}
            title={`Delete ${isMultipleOptionSelection ? 'Passes' : 'Pass'}`}
          >
            {currentPass !== null || isMultipleOptionSelection ? (
              <div className={dataGridStyle.box}>
                <div className={dataGridStyle.alert}>
                  <img
                    src={alertPic}
                    alt=""
                    className={dataGridStyle.alert_IMG}
                  />
                </div>
                <div className={dataGridStyle.warning}>
                  <h4>Warning:</h4>
                </div>
                <div
                  className={dataGridStyle.deleteStr}
                >
                  {`Are you sure you want to delete pass ${
                    isMultipleOptionSelection
                      ? deleteTitleSTR
                      : passCredOrNumber(currentPass || undefined)
                  }?`}
                </div>
                <div className={dataGridStyle.btnBox}>
                  <button
                    className={dataGridStyle.btnShare}
                    onClick={() => deletePasses()}
                  >
                    Delete
                  </button>
                  &nbsp;&nbsp;
                  <button
                    className={dataGridStyle.btnCancel}
                    onClick={() => {
                      setPassDeleteMode(false);
                      onReload();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className={dataGridStyle.box}>
                <LoadingDiamonds />
              </div>
            )}
          </CommonDialog>

          <CommonDialog
            showDialog={shareInfoDialog}
            onClose={() => {
              setShareInfoDialog(false);
              onReload();
            }}
            title="Pass Shared"
          >
            {currentPass !== null ? (
              <div className={dataGridStyle.sharedBox}>
                <p className={dataGridStyle.sharedTitle}>
                  <b>This pass has been shared with: </b>
                </p>
                {Array.isArray(currentPass.sharedWith)
                && currentPass.sharedWith.length ? (
                  <ul>
                    {currentPass.sharedWith.map((employee: EmployeeInfo) => (
                      <li
                        key={employee.employeeId}
                        className={dataGridStyle.sharedItem}
                      >
                        {employee.firstName}
                        {' '}
                        {employee.lastName}
                      </li>
                    ))}
                  </ul>
                  ) : (
                    <div>
                      <i>[No one]</i>
                    </div>
                  )}
              </div>
            ) : (
              <LoadingDiamonds />
            )}
          </CommonDialog>

          {currentPass ? (
            <SharePassDialog
              pass={currentPass}
              employees={employees}
              showDialog={shareDialog}
              onClose={() => {
                setShareDialog(false);
                onReload();
              }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
