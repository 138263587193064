import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import GenericButton from '../../../../common_lib_front/components/genericButton/genericButton';
import useOrderPrice from '../../../../common_lib_front/hooks/useOrderPrice';
// import CompanyInfo, { newCompanyInfo } from '../../../common_lib_front/types/companyInfo';
import CompanyInformationReview from '../../../../components/companyInformationReview/companyInformationReview';
import PassSummaryCollection from '../../../../components/passSummary/passSummaryCollection';
import PaymentInfoForm from '../../../../components/paymentInfoForm/paymentInfoForm';
import RegistrationNavHeader from '../../../../components/registrationNavHeader/registrationNavHeader';
import usePassSelection from '../passSelection/usePassSelection';
import { registrationStepProps } from '../wrapper';
import style from './secureCheckout.module.css';
import useSecureCheckout from './useSecureCheckout';

export default function SecureCheckout(props: registrationStepProps): React.ReactElement {
  // const [passes, setPasses] = useState([]);
  // const { registrationId } = useParams<{ registrationId: string }>();
  const {
    loading,
    companyInfo,
    registrationId,
    passes,
    redirect,
    submitHandler,
    selectedPayment,
    setSelectedPayment,
    isRenewal,
  } = useSecureCheckout(props);

  const {
    totalPrice,
  } = useOrderPrice(passes);

  // const [setCompanyInfo] = useState<CompanyInfo>(newCompanyInfo());
  const { t } = useTranslation();

  const { removeSelected } = usePassSelection();
  const [paymentByCheckDisabled, setPaymentByCheckDisabled] = useState(true);
  const history = useHistory();

  // Handle redirection inside useEffect
  useEffect(() => {
    if (redirect) {
      history.push(redirect);
    }
  }, [redirect]);

  const isFree = totalPrice === 0;

  useEffect(() => {
    let numAllowingCheckOrCash = 0;
    passes.forEach((pass) => {
      if (pass.priceInfo && pass.priceInfo.allowCashOrCheck) {
        numAllowingCheckOrCash++;
      }
    });

    if (numAllowingCheckOrCash === passes.length) {
      // all passes are annual passes
      setPaymentByCheckDisabled(false);
    } else {
      setPaymentByCheckDisabled(true);
    }
  }, [passes]);

  return (
    <div className={style.container}>
      <div className={`${style.box} white `}>
        <RegistrationNavHeader
          activeNum={3}
          title={`Step 3: ${t('Secure Checkout')}`}
          subtitle={t('step5_subtitle')}
        />
        <div className={style.flexBox}>
          <div className={style.boxLeft}>
            <CompanyInformationReview
              data={companyInfo}
              // editLink={`/vendor/registration/${registrationId}`}
              // docEditLink={`/vendor/registration/${registrationId}/documents`}
              registrationId={registrationId}
            />
          </div>

          <div className={style.boxRight}>
            <PassSummaryCollection
              showEditBtn
              showVehicleInfo
              showTotal
              data={passes}
              editLink={`/vendor/registration/${registrationId}/vehicle`}
              isRenewal={isRenewal}
            />
            {!isFree && (
              <PaymentInfoForm
                selectedPayment={selectedPayment}
                setSelectedPayment={setSelectedPayment}
                paymentByCheckDisabled={paymentByCheckDisabled}
              />
            )}
          </div>
        </div>
        <div className={style.btn}>
          <GenericButton
            size="large"
            color="blue"
            title={loading ? t('Please Wait...') : t(isFree ? 'Submit Information' : 'Confirm Purchase')}
            disabled={loading}
            clickHandler={() => {
              submitHandler();
              removeSelected();
            }}
          />
        </div>
      </div>
    </div>
  );
}
