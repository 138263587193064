import React from 'react';
import {
  Route, Switch,
} from 'react-router-dom';
import CommunityOrientation from './communityOrientation/communityOrientation';
import CreateRegistration from './createRegistration/createRegistration';
import GeneratePasses from './generatePasses/generatePasses';
import OrderSummary from './orderSummary/orderSummary';
import PassSelection from './passSelection/passSelection';
import RegisterVehicle from './registerVehicle/registerVehicle';
import SecureCheckout from './secureCheckout/secureCheckout';

export type registrationStepProps = {
  nextPath: (registrationId: string) => string;
  stepNum: number;
}

const allSteps = ['init', 'passSelection', 'home', 'vehicle', 'education', 'documents', 'checkout', 'generatePasses', 'receipt'] as const;
/*
  const activeSteps = [
    'init',
		'passSelection',
		'vehicle',
		'education',
		'checkout',
		'receipt',
  ] as const;
*/
const stepProps: Record<typeof allSteps[number], registrationStepProps> = {
  init: {
    nextPath: (rId) => `/vendor/registration/${rId}/pass-selection`,
    stepNum: 0,
  },
  passSelection: {
    nextPath: (rId) => `/vendor/registration/${rId}/vehicle`,
    stepNum: 0,
  },
  home: {
    nextPath: (rId) => `/vendor/registration/${rId}/vehicle`,
    stepNum: 0,
  },
  vehicle: {
    nextPath: (rId) => `/vendor/registration/${rId}/community-orientation`,
    stepNum: 1,
  },
  education: {
    nextPath: (rId) => `/vendor/registration/${rId}/secure-checkout`,
    stepNum: 2,
  },
  documents: {
    nextPath: (rId) => `/vendor/registration/${rId}/secure-checkout`,
    stepNum: 2,
  },
  checkout: {
    nextPath: (rId) => `/vendor/registration/${rId}/generate-passes`,
    stepNum: 3,
  },
  generatePasses: {
    nextPath: (rId) => `/vendor/registration/${rId}/receipt`,
    stepNum: 4,
  },
  receipt: {
    nextPath: () => '/vendor',
    stepNum: 4,
  },
};

/* eslint-disable react/jsx-props-no-spreading */
export default function PurchasePassWrapper(): React.ReactElement {
  return (
    <Switch>
      <Route exact path="/vendor/registration/:registrationId/pass-selection">
        <PassSelection {...stepProps.passSelection} />
      </Route>
      {/* <Route exact path="/vendor/registration/:registrationId/company-info">
        <RegistrationHome {...stepProps.home} />
      </Route> */}
      <Route exact path="/vendor/registration/:registrationId/vehicle/">
        <RegisterVehicle {...stepProps.vehicle} />
      </Route>
      <Route exact path="/vendor/registration/:registrationId/community-orientation">
        <CommunityOrientation {...stepProps.education} />
      </Route>
      {/* <Route exact path="/vendor/registration/:registrationId/documents">
        <UploadDocument {...stepProps.documents} />
      </Route> */}
      <Route exact path="/vendor/registration/:registrationId/secure-checkout">
        <SecureCheckout {...stepProps.checkout} />
      </Route>
      <Route exact path="/vendor/registration/:registrationId/generate-passes">
        <GeneratePasses {...stepProps.generatePasses} />
      </Route>
      <Route exact path="/vendor/registration/:registrationId/receipt">
        <OrderSummary {...stepProps.receipt} />
      </Route>
      <Route path="/vendor/registration/:registrationId">
        <CreateRegistration {...stepProps.init} />
      </Route>
      <Route path="/vendor/registration">
        <CreateRegistration {...stepProps.init} />
      </Route>
    </Switch>
  );
}
