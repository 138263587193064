import {
  QueryHookOptions, QueryResult, gql, useQuery,
} from '@apollo/client';
import { backendResponse } from '../common_lib_front/types/backendResponse';

const GET_APPLICATION = gql`
query GetApplicationByRegistration ($registrationId: String!) {
  getApplicationByRegistration(registrationId: $registrationId) {
    success
    error
    data {
      approvedBy
      registrationId
      status
      type
      userId
    }
  }
}
`;
type GET_APPLICATION_VARS = {
  registrationId: string;
}
type GET_APPLICATION_RES = {
  getApplicationByRegistration: backendResponse<{
    approvedBy: string;
    registrationId: string;
    status: string;
    type: string;
    userId: string;
  }>;
}

type optionsType = QueryHookOptions<GET_APPLICATION_RES, GET_APPLICATION_VARS>;
type returnType = QueryResult<GET_APPLICATION_RES, GET_APPLICATION_VARS>;
export const useGetApplicationByRegistration = (options: optionsType): returnType => useQuery<
GET_APPLICATION_RES, GET_APPLICATION_VARS
>(GET_APPLICATION, options);
