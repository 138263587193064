import React, { ReactChild, ReactElement, useState } from 'react';
import { ReactComponent as CloseImg } from '../../assets/close.svg';
import style from './commonDialog.module.css';

interface CommonDialogProps {
  title?: string,
  showDialog?: boolean,
  onClose?: () => void,
  children?: ReactChild | Array<ReactChild>;
  displayTop?:boolean,
  contentExtended?: boolean,
}

export default function CommonDialog(props: CommonDialogProps): ReactElement {
  const {
    onClose,
    showDialog,
    title,
    children,
    displayTop,
    contentExtended,
  } = props;

  const doClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      {showDialog ? (
        <div className={displayTop ? style.wrapperTop : style.wrapper}>
          <button className={style.bg} onClick={() => doClose()}>&nbsp;</button>
          <div className={style.main}>
            {title ? (
              <div className={style.header}>
                <div className={style.headerContent}>
                  {title}
                </div>
                <button className={style.headerClose} onClick={() => doClose()}>
                  <CloseImg />
                </button>
              </div>
            ) : null}

            <div className={(!contentExtended) ? style.content : style.contentExtended}>
              {children}
            </div>
          </div>
        </div>

      ) : null}
    </>
  );
}

CommonDialog.defaultProps = {
  title: null,
  showDialog: false,
  onClose: () => { /* pass */ },
  children: (<></>),
  displayTop: false,
  contentExtended: false,
};
