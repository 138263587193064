import { gql } from '@apollo/client';
import React, {
  useContext, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import { useGetRegistration } from '../../../hooks/useRegistration';
import style from './registrationNavHeader.module.css';
import RegistrationNavTile from './registrationNavTile';

const GET_REGISTRATION = gql`
  query GetRegistration(
    $registrationId: String!
  ) {
    getRegistration (
      registrationId: $registrationId
    ) {
      success
      error
      data {
        complete
        stepNumber
      }
    }
  }
`;

type Params = {
  registrationId: string;
}

type RegistrationNavHeaderProps = {
  title: string;
  subtitle: string | JSX.Element;
  activeNum?: number;
}

export const RegistrationNavHeader = (props: RegistrationNavHeaderProps): React.ReactElement => {
  const { registrationId } = useParams<Params>();
  const { title, subtitle, activeNum } = props;
  const [completeNum, setCompleteNum] = useState<number>(0);
  const [completeAll, setCompleteAll] = useState<boolean>(false);
  const { name: communityName } = useContext(CommunityContext);

  useGetRegistration({ registrationId }, {
    fetchPolicy: 'no-cache',
    onCompleted: (d) => {
      if (d.getRegistration.success && d.getRegistration.data) {
        setCompleteNum(d?.getRegistration?.data[0]?.stepNumber);
        setCompleteAll(d?.getRegistration?.data[0]?.complete);
      }
    },
    onError: (e) => {
      console.warn(e);
    },
  });

  useEffect(() => {
    (window as any).registrationLockStepNum = completeNum;
    return () => {
      (window as any).registrationLockStepNum = undefined;
    };
  }, [completeNum]);

  const { t } = useTranslation();

  return (
    <div className={style.navHeaderBox}>
      <div className={style.textBox}>
        <h2 className={`${style.titleGrey} textColorSuperDark `}>
          {t('Welcome to')}
          {' '}
          <p className={`${style.titleBlue} textColorMain `}>{communityName}</p>
        </h2>
        <div className={style.registrationBox}>
          <p className={`${style.text} textColorSuperDark `}>
            {t('registration_instructions')}
          </p>
        </div>

      </div>
      {activeNum !== 0 ? (
        <div className={style.iconBox}>
          <RegistrationNavTile
            num={1}
            activeNum={activeNum || 0}
            completeNum={Math.max(completeNum, activeNum || 0)}
            completeAll={completeAll}
            imgName="DocumentsImg"
            title={t('Step 1')}
            text={t('Register Your Company')}
            href={`/vendor/initial-registration/${registrationId}/company-info`}
          />
          <span className={`${style.line} specialGrey `} />
          <RegistrationNavTile
            num={2}
            activeNum={activeNum || 0}
            completeNum={Math.max(completeNum, activeNum || 0)}
            completeAll={completeAll}
            imgName="RulesImg"
            title={t('Step 2')}
            text={t('Community Orientation')}
            href={`/vendor/initial-registration/${registrationId}/education`}
          />
          <span className={`${style.line} specialGrey `} />
          <RegistrationNavTile
            num={3}
            activeNum={activeNum || 0}
            completeNum={Math.max(completeNum, activeNum || 0)}
            completeAll={completeAll}
            imgName="DocumentUpload"
            title={t('Step 3')}
            text={t('Upload Documents')}
            href={`/vendor/initial-registration/${registrationId}/documents`}
          />
          <span className={`${style.line} specialGrey `} />
          <RegistrationNavTile
            num={4}
            activeNum={activeNum || 0}
            completeNum={Math.max(completeNum, activeNum || 0)}
            completeAll={completeAll}
            imgName="CreditCardImg"
            title={t('Step 4')}
            text={t('Secure Checkout')}
            href={`/vendor/initial-registration/${registrationId}/secure-checkout`}
          />
        </div>
      ) : null}
      <h2 className={`${style.title} textColorSuperDark `}>{title}</h2>
      <p className={`${style.subTitle} textColorSuperDark `}>{subtitle}</p>
    </div>
  );
};
RegistrationNavHeader.defaultProps = {
  activeNum: 0,
};

export default RegistrationNavHeader;
