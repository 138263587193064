import { ReactElement, useCallback } from 'react';
import { Redirect } from 'react-router-dom';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import LoadingDiamonds from '../../../common_lib_front/components/loadingDiamonds/loadingDiamonds';
import { useCreateApplication } from '../../../hooks/createApplication';
import { useCreateRegistration } from '../../../hooks/createRegistration';
import { useGetApplication } from '../../../hooks/useGetApplication';

export default function Initialize(): ReactElement {
  const [doCreateApplication, {
    data: createApplicationData,
    error: createApplicationError,
    loading: createApplicationLoading,
  }] = useCreateApplication({
    onError: (err) => console.warn(err),
  });

  const [doCreateRegistration, {
    data: createRegistrationData,
    error: createRegistrationError,
    loading: createRegistrationLoading,
  }] = useCreateRegistration({
    onError: (err) => console.warn(err),
  });

  const doCreateBoth = useCallback(async () => {
    // create registration
    const reg = await doCreateRegistration({
      variables: {
        type: 'vendor-initial',
      },
    });
    if (!reg.data?.createRegistration.data?.[0]?.registrationId) return;
    // create application
    doCreateApplication({
      variables: {
        registrationId: reg.data.createRegistration.data?.[0]?.registrationId,
        type: 'vendor',
      },
    });
  }, [doCreateApplication, doCreateRegistration]);

  const {
    data: getApplicationData,
    error: getApplicationError,
    loading: getApplicationLoading,
    refetch: refetchApplication,
  } = useGetApplication({
    fetchPolicy: 'network-only',
    onError: (err) => console.warn(err),
    onCompleted: async (d) => {
      // application does not exist
      if (!d.getApplication.data?.registrationId) {
        // create registration
        await doCreateBoth();
        refetchApplication();
      }
    },
  });

  return (
    <div style={{ marginTop: '200px' }}>
      <div style={{ width: '80%', marginLeft: '10%' }}>
        {/* redirect to home */}
        { ['approved', 'needs-review'].includes(getApplicationData?.getApplication.data?.status || '') && (
          <Redirect to="/vendor" />
        )}
        {/* redirect to next step */}
        {(
          !['approved', 'needs-review'].includes(getApplicationData?.getApplication.data?.status || '')
          && getApplicationData?.getApplication.data?.registrationId
        ) && (
          <Redirect to={`/vendor/initial-registration/${getApplicationData.getApplication.data.registrationId}/company-info`} />
        )}
        {/* redirect to next step */}
        {(createApplicationData?.createApplication.data?.registrationId) && (
          <Redirect to={`/vendor/initial-registration/${createApplicationData.createApplication.data.registrationId}/company-info`} />
        )}
        {/* error message */}
        {(
          getApplicationError
          || createApplicationError
          || createRegistrationError
        ) && (
          <GenericAlert
            title="Something went wrong. Could not start registration process."
            color="red"
          />
        )}
        {/* error message */}
        {/* {(getApplicationData?.getApplication.error) && (
          <GenericAlert
            title={getApplicationData.getApplication.error}
            color="red"
          />
        )} */}
        {/* error message */}
        {(createApplicationData?.createApplication.error) && (
          <GenericAlert
            title={createApplicationData.createApplication.error}
            color="red"
          />
        )}
        {/* error message */}
        {(createRegistrationData?.createRegistration.error) && (
          <GenericAlert
            title={createRegistrationData.createRegistration.error}
            color="red"
          />
        )}
        {/* Loading icon */}
        {(
          getApplicationLoading
          || createApplicationLoading
          || createRegistrationLoading
        ) && (
          <LoadingDiamonds />
        )}
      </div>
    </div>
  );
}
