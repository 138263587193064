import {
  MutationHookOptions, MutationTuple, gql, useMutation,
} from '@apollo/client';
import { backendResponse } from '../common_lib_front/types/backendResponse';

const CREATE_APPLICATION = gql`
mutation CreateApplication($type: String!, $registrationId: String!) {
  createApplication(type: $type, registrationId: $registrationId) {
    error
    success
    data {
      approvedBy
      registrationId
      type
      userId
    }
  }
}
`;

type CREATE_APPLICATION_VARS = {
  type: 'resident' | 'host' | 'vendor' | 'vendor-pass' | 'vendor-renewal';
  registrationId: string;
}

export type createApplicationResponse = {
  approvedBy: string;
  registrationId: string;
  status: string;
  type: string;
  userId: string;
}

type CREATE_APPLICATION_RES = {
  createApplication: backendResponse<createApplicationResponse>;
};

type optionsType = MutationHookOptions<CREATE_APPLICATION_RES, CREATE_APPLICATION_VARS>;
type resType = MutationTuple<CREATE_APPLICATION_RES, CREATE_APPLICATION_VARS>;

export const useCreateApplication = (options?: optionsType): resType => useMutation<
  CREATE_APPLICATION_RES, CREATE_APPLICATION_VARS
>(CREATE_APPLICATION, {
  ...options,
});
