import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { CommunityContext } from '../../../../common_lib_front/communityConfigs/communityContextProvider';
import GenericAlert from '../../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../../common_lib_front/components/genericButton/genericButton';
import useOrderPrice from '../../../../common_lib_front/hooks/useOrderPrice';
import PassInfo from '../../../../common_lib_front/types/passInfo';
import PassGenerator from '../../../../components/passGenerator/passGenerator';
import PassSummaryCollection from '../../../../components/passSummary/passSummaryCollection';
import RegistrationNavHeader from '../../../../components/registrationNavHeader/registrationNavHeader';
import { exportPdf } from '../../../../utilities/pdf';
import { registrationStepProps } from '../wrapper';
import style from './orderSummary.module.css';
import useOrderSummary from './useOrderSummary';

export default function OrderSummary(props: registrationStepProps): React.ReactElement {
  const { nextPath } = props;
  const { registrationId } = useParams<{ registrationId: string }>();
  const { passes, errorMsg, refetch } = useOrderSummary();
  const { name: communityName, communityId } = useContext(CommunityContext);

  const { t } = useTranslation();
  const nowDate = new Date();
  const {
    totalPrice,
  } = useOrderPrice(passes);

  const downloadPdf = async () => {
    const pdf: any = await exportPdf(passes.map((p) => p.url));
    if (pdf) {
      pdf.save('access-passes.pdf');
    }
  };

  const printPdf = async () => {
    refetch().then(async (response) => {
      if (response.data.getPassesByRegistrationWithPassInfo
        && response.data.getPassesByRegistrationWithPassInfo.data
        && response.data.getPassesByRegistrationWithPassInfo.data.length > 0) {
        const pdf = await
        exportPdf(response.data.getPassesByRegistrationWithPassInfo.data.map((p) => p.url));
        if (pdf) {
          const blob = pdf.output('blob');
          const nextWindow = window.open(URL.createObjectURL(blob), '_blank');
          nextWindow?.focus();
          nextWindow?.print();
        }
      }
    });
  };

  const history = useHistory();
  const alert = errorMsg;

  return (
    <div className={style.container}>
      <div className={`${style.box} white `}>
        <RegistrationNavHeader
          activeNum={6}
          title={t('Thank you')}
          // subtitle={t('step6_subtitle')}
          subtitle=""
        />
        <GenericAlert
          color="red"
          title={alert}
          hidden={!alert}
        />

        <div className={style.flexBox}>
          <div className={style.boxLeft}>
            <div className={style.infoBox}>
              <div className={style.innerInfoBox}>
                <h3 className={`${style.title} textColorSuperDark `}>
                  {t('Order Summary')}
                </h3>
                <p className={`${style.text} textColorSuperDark `}>
                  {t('A confirmation email has been sent to your email')}
                  {' '}
                  {t('along with your Community Access Pass')}
                  {' '}
                  {communityId === 'sipoa' && t('Voucher')}
                </p>
                <ul>
                  <li>
                    <b>
                      {t('Community Pass Number')}
                      :
                      {' '}
                    </b>
                    <span>VCB23865782</span>
                  </li>
                  <li>
                    <b>
                      {t('Date')}
                      :
                      {' '}
                    </b>
                    <span>{nowDate.toLocaleDateString('en-US')}</span>
                  </li>
                  <li>
                    <b>
                      {t('Purchase Total')}
                      :
                      {' '}
                    </b>
                    <span>
                      $
                      {totalPrice}
                    </span>
                  </li>
                  <li>
                    <b>
                      {t('Payment Method')}
                      :
                      {' '}
                    </b>
                    <span>Card</span>
                  </li>
                </ul>
                {passes[0]?.passInfo?.passApprovalRequire !== true && (
                  <div className={style.btnBox}>
                    <div className={style.btn}>
                      <GenericButton
                        color="blue"
                        outline="large"
                        title={communityId === 'sipoa' ? t('Print Pass voucher/QR code & Receipt') : t('Print Passes & Receipt')}
                        clickHandler={() => {
                          printPdf();
                        }}
                      />
                    </div>
                    {/* <div className={style.btn}>
                    <GenericButton
                      size="large"
                      color="blue"
                      title={
                        communityId === 'sipoa' ?
                        t('Share Pass voucher/QR code') :
                        t('Share Pass')
                      }
                      clickHandler={() => {
                        history.push('/vendor/my-passes');
                      }}
                    />
                  </div>
                  <div className={style.btn}>
                    <GenericButton
                      size="large"
                      color="blue"
                      title={
                        communityId === 'sipoa' ?
                        t('Download Pass voucher/QR code') :
                        t('Download Access Pass')
                      }
                      clickHandler={() => {
                        downloadPdf();
                      }}
                    />
                  </div> */}
                  </div>
                )}
              </div>
            </div>

            <div id="export-pdf-container" className={style.passList}>
              {passes?.map((p: PassInfo) => (
                p?.passInfo?.passApprovalRequire !== true && (
                  <PassGenerator pass={p} />
                )
              ))}
            </div>

            {/* <div className={style.instruction}>Custom Instruction from pass builder...</div> */}
          </div>

          <div className={style.boxRight}>
            <PassSummaryCollection
              showEditBtn={false}
              showVehicleInfo
              showTotal
              data={passes}
            />
          </div>
        </div>
        <div className={style.btnContinue}>
          <GenericButton
            size="large"
            color="blue"
            title="Complete"
            clickHandler={() => history.push(nextPath(registrationId))}
          />
        </div>
      </div>
    </div>
  );
}
