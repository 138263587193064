export type GroupType = {
  communityId: string,
  passInfoList: string[],
  passGroupId: string
  name: string
}

interface IFilterRuleSet {
  communityId: string;
  businessType: string | RegExp | 'DEFAULT';
  startWith: 'ALL' | 'NONE';
  // satisfyRules: 'ALL' | 'SOME';
  rules: IFilterRule[];
  disable?: boolean;
}
interface IFilterRule {
  pattern?: string | RegExp;
  passGroupId?: string;
  useBusinessTypePattern?: boolean;
  type: 'EXCLUDE' | 'INCLUDE';
  disable?: boolean;
}

const filterRules: IFilterRuleSet[] = [
  {
    communityId: 'DEFAULT',
    businessType: 'DEFAULT',
    startWith: 'ALL',
    // satisfyRules: 'ALL',
    rules: [],
  },
  // oceanlakes default rule
  {
    communityId: 'oceanlakes',
    businessType: 'DEFAULT',
    startWith: 'ALL',
    // satisfyRules: 'ALL',
    rules: [
      {
        pattern: /^special/i,
        type: 'EXCLUDE',
      },
      {
        pattern: /^vendor/i,
        type: 'EXCLUDE',
      },
    ],
  },
  // oceanlakes special access rule
  {
    communityId: 'oceanlakes',
    businessType: /special access/i,
    startWith: 'NONE',
    // satisfyRules: 'ALL',
    rules: [
      {
        pattern: /^special/i,
        type: 'INCLUDE',
      },
    ],
  },
  // oceanlakes vendor (oceanlakes) rule
  {
    communityId: 'oceanlakes',
    businessType: /vendor \(ocean lakes\)/i,
    startWith: 'NONE',
    // satisfyRules: 'ALL',
    rules: [
      {
        pattern: /^vendor/i,
        type: 'INCLUDE',
      },
    ],
  },
  {
    communityId: 'sipoa',
    businessType: 'DEFAULT',
    startWith: 'ALL',
    // satisfyRules: 'ALL',
    rules: [
      {
        pattern: /camp.*christopher/i,
        type: 'EXCLUDE',
      },
      {
        pattern: /seabrook island club/i,
        type: 'EXCLUDE',
      },
      {
        pattern: /seabrook island poa/i,
        type: 'EXCLUDE',
      },
      {
        pattern: /seabrook island real estate/i,
        type: 'EXCLUDE',
      },
      {
        pattern: /town of seabrook island/i,
        type: 'EXCLUDE',
      },
      {
        pattern: /services/i,
        type: 'EXCLUDE',
      },
      {
        pattern: /seabrook island utility/i,
        type: 'EXCLUDE',
      },
    ],
  },
  {
    communityId: 'sipoa',
    businessType: /camp.*christopher/i,
    startWith: 'NONE',
    // satisfyRules: 'ALL',
    rules: [
      {
        useBusinessTypePattern: true,
        type: 'INCLUDE',
      },
    ],
  },
  {
    communityId: 'sipoa',
    businessType: /seabrook island club/i,
    startWith: 'NONE',
    // satisfyRules: 'ALL',
    rules: [
      {
        useBusinessTypePattern: true,
        type: 'INCLUDE',
      },
    ],
  },
  {
    communityId: 'sipoa',
    businessType: /seabrook island poa/i,
    startWith: 'NONE',
    // satisfyRules: 'ALL',
    rules: [
      {
        useBusinessTypePattern: true,
        type: 'INCLUDE',
      },
    ],
  },
  {
    communityId: 'sipoa',
    businessType: /(seabrook island)?\s?real estate/i,
    startWith: 'NONE',
    // satisfyRules: 'ALL',
    rules: [
      {
        useBusinessTypePattern: true,
        type: 'INCLUDE',
      },
    ],
  },
  {
    communityId: 'sipoa',
    businessType: /(town of seabrook island|tosi)/i,
    startWith: 'NONE',
    // satisfyRules: 'ALL',
    rules: [
      {
        useBusinessTypePattern: true,
        type: 'INCLUDE',
      },
    ],
  },
  {
    communityId: 'sipoa',
    businessType: /^services$/i,
    startWith: 'NONE',
    // satisfyRules: 'ALL',
    rules: [
      {
        useBusinessTypePattern: true,
        type: 'INCLUDE',
      },
    ],
  },
  {
    communityId: 'sipoa',
    businessType: /utilit(ies|y)/i,
    startWith: 'NONE',
    // satisfyRules: 'ALL',
    rules: [
      {
        useBusinessTypePattern: true,
        type: 'INCLUDE',
      },
    ],
  },
];

const stringOrRegexTest = (pattern: string | RegExp, val: string): boolean => {
  if (typeof pattern === 'string') {
    return pattern === val;
  }
  return pattern.test(val);
};

const findRelevantFilterSet = (
  communityId: string,
  businessType: string,
): IFilterRuleSet | null => {
  let defaultRule: IFilterRuleSet | null = null;
  let communityDefaultRule: IFilterRuleSet | null = null;
  let ruleSet: IFilterRuleSet | null = null;
  filterRules.forEach((rs) => {
    if (rs.communityId === 'DEFAULT') {
      defaultRule = rs;
      return;
    }
    if (rs.communityId !== communityId) return;
    if (rs.businessType === 'DEFAULT') {
      communityDefaultRule = rs;
      return;
    }
    if (stringOrRegexTest(rs.businessType, businessType)) {
      if (!ruleSet) {
        ruleSet = rs;
      } else {
        throw Error('Multiple applicable rules found.');
      }
    }
  });
  return ruleSet || communityDefaultRule || defaultRule;
};

const defaultGroups = (ruleSet: IFilterRuleSet, groups: GroupType[]): GroupType[] => {
  if (ruleSet.startWith === 'ALL') return groups;
  return [];
};

const testRule = (rule: IFilterRule, group: GroupType, businessType: string | RegExp): boolean => {
  if (rule.passGroupId) {
    return rule.passGroupId === group.passGroupId;
  }
  if (rule.useBusinessTypePattern) {
    return stringOrRegexTest(businessType, group.name);
  }
  if (rule.pattern) {
    return stringOrRegexTest(rule.pattern, group.name);
  }
  throw Error('Invalid rule. Rules must specify one of [passGroupId, useBusinessTypePattern, pattern]');
};

export const passGroupsFilter = (
  communityId: string,
  businessType: string,
  passGroups: GroupType[],
): GroupType[] => {
  const ruleSet = findRelevantFilterSet(communityId, businessType);
  if (!ruleSet) return passGroups;
  let res = defaultGroups(ruleSet, passGroups);
  passGroups.forEach((pg) => {
    ruleSet.rules.forEach((rule) => {
      const testPass = testRule(rule, pg, ruleSet.businessType);
      if (rule.type === 'EXCLUDE' && testPass) {
        res = res.filter((g) => g.passGroupId !== pg.passGroupId);
      } else if (rule.type === 'INCLUDE' && testPass) {
        res.push(pg);
      }
    });
  });
  return res;
};
