import {
  useMutation, useQuery,
} from '@apollo/client';
import { TFunction } from 'i18next';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { newPassInfo } from '../../../../common_lib_front/types/passInfo';
import { newVehicleInfo } from '../../../../common_lib_front/types/vehicleInfo';
import { passFormInput } from '../../../../components/passForm/passForm';
import { useCreateApplication } from '../../../../hooks/createApplication';
import { useGetApplicationByRegistration } from '../../../../hooks/useApplicationByRegistration';
import { useGetAllPassInfos } from '../../../../hooks/useGetAllPassInfos';
import usePassSelection from '../passSelection/usePassSelection';
import { registrationStepProps } from '../wrapper';
import { createEditVehiclePass } from './createEditVehiclePass';
import {
  STEP2_QUERY,
  STEP2_QUERY_RES,
  STEP_TWO_SUMBIT,
} from './registerVehicleRequests';
/*
Default dates are set from this info in passBuilderDateReange from vehicleInfoForm
*/

type useRegisterVehicleRes = {
  redirect: string,
  doSubmit: () => Promise<void>,
  alert: string,
  setAlert: Dispatch<SetStateAction<string>>,
  passes: Array<passFormInput>,
  setPasses: React.Dispatch<React.SetStateAction<passFormInput[]>>,
  t: TFunction,
  isRenewal: boolean,
  loading:boolean,
  saveToLocalStorage: () => void
}

export default function useRegisterVehicle(props: registrationStepProps): useRegisterVehicleRes {
  const [passes, setPasses] = useState<Array<passFormInput>>([]);
  const [redirect, setRedirect] = useState<string>('');
  const { registrationId } = useParams<{ registrationId: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [alert, setAlert] = useState<string>('');
  let { selectedPassTypes } = usePassSelection();
  const { data: passConfigData } = useGetAllPassInfos();
  // TODO: implement a hook to find application by registrationId
  // const applicationsData = undefined;
  const { data: applicationData } = useGetApplicationByRegistration({
    variables: {
      registrationId,
    },
  });
  const [doCreateApplication] = useCreateApplication();
  const { t } = useTranslation();

  const localStorageData = localStorage.getItem('passFormCollection');

  const saveToLocalStorage = () => {
    localStorage.setItem('passFormCollection', JSON.stringify(passes));
  };

  const { data: s2qDataRaw } = useQuery<STEP2_QUERY_RES>(STEP2_QUERY, {
    variables: { registrationId },
    fetchPolicy: 'no-cache',
    onCompleted: async (d) => {
      let renew = false;
      if (d.getRegistration.success && d.getRegistration.data) {
        renew = d.getRegistration.data[0].renew;
      }

      const res: passFormInput[] = d.getPassesByRegistration.data?.map((p) => {
        // WARNING: do not rehydtate the vehicle.employee
        // vehicle.employee is only set on user event
        const temp: passFormInput = newPassInfo({
          ...p,
          vehicle: newVehicleInfo(p.vehicle || {}),
        });
        temp.destination = p.vehicle?.destination;
        return temp;
      }) || [];

      if (renew) {
        selectedPassTypes = [];
        for (let i = 0; i < res.length; i += 1) {
          const passType = {
            name: res[i].passType,
            passInfoId: res[i].passInfoId,
            quantity: 0,
            communityId: '',
            portal: '',
            complete: true,
            description: '',
            priceInfo: { price: 0 },
            educationRequired: '',
          };

          let found = false;
          // eslint-disable-next-line no-restricted-syntax
          for (const p of selectedPassTypes) {
            if (res[i].passInfoId === p.passInfoId) {
              p.quantity += 1;
              found = true;
              break;
            }
          }
          if (!found) selectedPassTypes.push(passType);
        }
      }

      // cached for later
      localStorage.setItem('remotePasses', JSON.stringify(res));

      // populate initial passes
      if (res.length === 0) {
        selectedPassTypes?.forEach((pass) => {
          for (let i = 0; i < pass.quantity; i += 1) {
            res.push(newPassInfo({
              // startDate: formatDate(defaultDates.startDate),
              // endDate: formatDate(defaultDates.endDate),
              passType: pass.name,
              passInfoId: pass.passInfoId,
              price: Number(pass.priceInfo.price) || 0,
              priceInfo: pass.priceInfo,
            }));
          }
        });
      }
       
      if (localStorageData) {
        setPasses(JSON.parse(localStorageData));
        localStorage.removeItem('passFormCollection');
      } else {
        setPasses(res);
      }      
    },
  });

  const [doSubmitStep2] = useMutation(STEP_TWO_SUMBIT, {
    onError: (e) => {
      console.error(e);
      setAlert('Something went wrong. Your changes may not have been saved.');
      if (process.env.REACT_APP_DEBUG === 'true') {
        // setRedirect(`/vendor/registration/${registrationId || 0}/community-orientation`);
        setRedirect(props.nextPath(registrationId));
      }
    },
    onCompleted: (d: any) => {
      // setRedirect(`/vendor/registration/${registrationId}/community-orientation`);
      setRedirect(props.nextPath(registrationId));
    },
  });

  const doSubmit = async () => {
    if (passConfigData?.getPassInfosByCommunity.data?.some((pi) => (
      pi.needsApplication
      && passes.findIndex((p) => p.passInfoId === pi.passInfoId) > -1
      && !applicationData?.getApplicationByRegistration.data
    ))) {
      doCreateApplication({
        variables: {
          registrationId,
          type: 'vendor-pass',
        },
      });
    }
    // const resStr = localStorage.getItem('remotePasses');
    // if (resStr) {
    //   const res = JSON.parse(resStr);
    //   res.forEach((resItem: PassInfo) => {
    //     backendClient.mutate({
    //       mutation: DELETE_PASS,
    //       variables: {
    //         passId: resItem.passId,
    //         vehicleId: resItem.vehicle.vehicleId,
    //       },
    //     })
    //       .catch((err) => {
    //         console.log(`pass save error ${err}`);
    //         setAlert(t('Some data may not have been saved. Please try again la
    // ter or report this issue'));
    //       });
    //   });
    // }
    setLoading(true);
    Promise.all(passes.map((p) => createEditVehiclePass({
      p,
      registrationId,
      isRenewal: s2qDataRaw?.getRegistration.data?.[0].renew || false,
    })))
      .then(() => {
        if ((window as any).registrationLockStepNum > props.stepNum) {
          // setRedirect(`/vendor/registration/${registrationId}/community-orientation`);
          setRedirect(props.nextPath(registrationId));
          setLoading(false);
          return;
        }
        doSubmitStep2({
          variables: {
            registrationId,
            stepNumber: props.stepNum + 1,
          },
        });
      })
      .catch((error) => {
        console.log(`pass save error ${error}`);
        if (error instanceof Error) {
          setAlert(error.message);
        } else if (typeof error === 'string') {
          setAlert(error);
        } else {
          setAlert(t('Some data may not have been saved. Please try again later or report this issue'));
        }
      });
  };

  return {
    redirect,
    doSubmit,
    alert,
    passes,
    setPasses,
    t,
    setAlert,
    isRenewal: s2qDataRaw?.getRegistration.data?.[0].renew || false,
    loading,
    saveToLocalStorage
  };
}
