import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import { Redirect } from 'react-router-dom';
import InputField from '../../common_lib_front/components/inputField/inputField';
import style from './passwordResetForm.module.css';
import GenericAlert from '../../common_lib_front/components/genericAlert/genericAlert';

type PasswordResetFormProps ={
	onSubmit?: () => void;
}

const RESET_PASSWORD = gql`
  mutation ResetPassword (
    $confirmPassword: String!
    $newPassword: String!
    $currentPassword: String!
  ) {
    changePassword (
      confirmPassword: $confirmPassword
      newPassword: $newPassword
      currentPassword: $currentPassword
    ) {
      success
      error
    }
  }
`;

export const PasswordResetForm = (props:PasswordResetFormProps) :ReactElement => {
  const [oldPass, setOldPass] = useState<string>('');
  const [newPass, setNewPass] = useState<string>('');
  const [confPass, setConfPass] = useState<string>('');
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green' | undefined>('red');
  const [redirect, setRedirect] = useState<string>('');
  const { t } = useTranslation();

  const [doReset] = useMutation(RESET_PASSWORD, {
    onError: (e) => {
      setAlert(e.message);
      setAlertColor('red');
    },
    onCompleted: (d) => {
      if (!d.changePassword?.success) {
        setAlert(d.changePassword?.error);
        setAlertColor('red');
      } else {
        setAlert(t('Reset Password Successfully!'));
        setAlertColor('green');
      }
    },
  });

  if (redirect) {
    return <Redirect to={redirect} />;
  }
  return (
    <form
      onSubmit={(e:React.FormEvent<HTMLFormElement>):void => {
        e.preventDefault();
        if (props.onSubmit !== undefined) {
          doReset({
            variables: {
              confirmPassword: confPass,
              newPassword: newPass,
              currentPassword: oldPass,
            },
          });
          props.onSubmit();
        }
      }}
      className={style.form}
    >
      <div>
        <GenericAlert
          color={alertColor}
          title={alert}
          hidden={!alert}
        />

      </div>
      <div className={style.inputLong}>
        <InputField
          largeSize
          highlightOnFocus
          htmlFor="old-pass-inpt"
          labelTitle={t('Enter your old password')}
          inputType="password"
          inputValue={oldPass}
          changeHandler={(e:React.ChangeEvent<HTMLInputElement>) => {
            setOldPass(e.target.value);
          }}
        />

      </div>
      <div className={style.inputLong}>
        <InputField
          largeSize
          highlightOnFocus
          htmlFor="new-pass-inpt"
          labelTitle={t('Enter your new password')}
          inputType="password"
          inputValue={newPass}
          changeHandler={(e:React.ChangeEvent<HTMLInputElement>) => {
            setNewPass(e.target.value);
          }}
        />

      </div>
      <div className={style.inputLong}>
        <InputField
          largeSize
          highlightOnFocus
          htmlFor="cont-pass-inpt"
          labelTitle={t('Confirm your new password')}
          inputType="password"
          inputValue={confPass}
          changeHandler={(e:React.ChangeEvent<HTMLInputElement>) => {
            setConfPass(e.target.value);
          }}
        />

      </div>
      <div className={style.btnBox}>
        <input
          type="submit"
          value={`${t('Save Changes')}`}
          className={`${style.btn} mainColor textColorWhite `}
        />

      </div>
    </form>
  );
};
PasswordResetForm.defaultProps = {
  onSubmit: () => {
    //
  },
};

export default PasswordResetForm;
