import React, { ReactElement, useState } from 'react';
import { Redirect } from 'react-router-dom';
import ScormWrapper from '../../common_lib_front/components/scormWrapper/scormWrapper';
import style from './reviewRules.module.css';

export default function ReviewRules(): ReactElement {
  const [redirect, setRedirect] = useState<string>('');

  if (redirect) {
    return (
      <Redirect to={redirect} />
    );
  }

  return (
    <div className={style.container}>
      <div className={style.box}>
        <div className={style.flexBox}>
          <div className={style.LMSWindow}>
            <ScormWrapper
              portal="vendor"
              continueHandler={() => {
                setRedirect('/vendor');
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
