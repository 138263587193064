import { gql, useQuery } from '@apollo/client';
import {
  compact,
  flatten, map, uniq,
} from 'lodash';
import { useContext, useMemo, useState } from 'react';
import { CommunityContext } from '../../../../common_lib_front/communityConfigs/communityContextProvider';
import { IDocumentConfig } from '../../../../common_lib_front/types/documentInfo';
import { backendClient } from '../../../../common_lib_front/utilities/BackendAPI';
import store from '../../../../common_lib_front/utilities/store';
import { GET_COMPANY_PROFILE, GET_COMPANY_PROFILE_RES } from '../../../profile/companyProfile/companyProfile';
import { backendResponse } from '../../../../common_lib_front/types/backendResponse';
import { GroupType, passGroupsFilter } from './passGroupSpecialRules';

export const GET_PASS_GROUP = gql`
  query getPassGroups (
    $communityId: String!
  ) {
    getPassGroups(
      communityId: $communityId
    ){
      success
      error
      data {
        communityId
        passInfoList
        passGroupId
        name
      }
    }
  }
`;
type GET_PASS_GROUP_RES = {
  getPassGroups: backendResponse<GroupType[]>
}

export const GET_ALL_PASS = gql`
  query getPassInfosByCommunity (
    $communityId: String!
    $complete: Boolean!
  ) {
    getPassInfosByCommunity(
      communityId: $communityId
      complete: $complete
    ){
      success
      error
      data {
        passInfoId
        communityId
        portal
        complete
        name
        priceInfo{
          price
        }
        description
        educationRequired
      }
    }
  }
`;

export const GET_DOCUMENT_INFO_CONFIG = gql`
  query GetDocumentInfoConfig (
    $passInfoId: String!
  ) {
    getDocumentInfoConfigs (
      passInfoId: $passInfoId
    ) {
      success
      error
      data {
        passInfoId
        documentInfoId
        name
        required
      }
    }
  }
`;

export type PassType = {
  passInfoId: string,
  communityId: string,
  portal: string,
  complete: boolean,
  name: string,
  description: string,
  priceInfo: { price: number },
  educationRequired: string,
  quantity: number
}

type usePassSelectionRes = {
  passGroups: GroupType[],
  passTypes: PassType[],
  selectedPassTypes: PassType[],
  setQuantity: (passType: PassType, quantity: number) => void,
  incQuantity: (passType: PassType, amount: number) => void,
  saveSelected: () => void,
  removeSelected: () => void,
  isSelected: () => boolean
}

export default function usePassSelection(): usePassSelectionRes {
  const { communityId } = useContext(CommunityContext);
  const [passTypes, setPassTypes] = useState<PassType[]>([]);
  const [documentInfoConfigs, setDocumentInfoConfigs] = useState<any>([]);
  const [selectedPassTypes,
    setSelectedPassTypes] = useState<PassType[]>(store.getState().selectedPassTypes);

  const { data: groupData } = useQuery<GET_PASS_GROUP_RES>(GET_PASS_GROUP, {
    fetchPolicy: 'network-only',
    variables: {
      communityId,
    },
  });

  const { data: companyProfileData } = useQuery<GET_COMPANY_PROFILE_RES>(GET_COMPANY_PROFILE);

  useQuery(GET_ALL_PASS, {
    fetchPolicy: 'network-only',
    variables: {
      communityId,
      complete: true,
    },
    onCompleted: async (allPassData) => {
      const passInfoIds = compact(uniq(map(allPassData?.getPassInfosByCommunity.data, 'passInfoId')));
      try {
        // * Get DocumentInfoConfig by passInfoIds (from passes) and set to state
        const documentInfoConfigRes = await Promise.all(
          map(passInfoIds, (id: string) => backendClient.query({
            query: GET_DOCUMENT_INFO_CONFIG,
            variables: { passInfoId: id },
          })),
        );
        setDocumentInfoConfigs(flatten(map(documentInfoConfigRes, 'data.getDocumentInfoConfigs.data')));
        setPassTypes(allPassData?.getPassInfosByCommunity.data
          .filter((passType: PassType) => passType.portal === 'vendor')
          .map((passType: PassType) => {
            const selected = selectedPassTypes?.filter(
              (item) => item.passInfoId === passType.passInfoId,
            )[0]; // <--- this indexing is probably stupid. I didn't write it
            // eslint-disable-next-line no-param-reassign
            passType.quantity = selected?.quantity || 0;
            return passType;
          }));
      } catch (error: any) {
        console.log(error);
      }
    },
  });

  const setQuantity = (passType: PassType, quantity: number): void => {
    if (!Number.isNaN(quantity) && quantity >= 0) {
      // eslint-disable-next-line no-param-reassign
      passType.quantity = quantity;
    }

    setPassTypes([
      ...passTypes,
    ]);
  };

  const incQuantity = (passType: PassType, amount: number): void => {
    setQuantity(passType, passType.quantity + amount);
  };

  const saveSelected = () => {
    const lstIds = passTypes
      .filter((passType: PassType): boolean => passType.quantity > 0)
      .map((obj) => obj.passInfoId);
    const result = documentInfoConfigs
      .filter(({ passInfoId }: IDocumentConfig) => lstIds.includes(passInfoId));

    store.dispatch({
      type: 'SAVE_SELECTED',
      payload: {
        selectedPasses: passTypes.filter((passType: PassType): boolean => passType.quantity > 0),
        documentsConfig: result,
      },
    });

    setSelectedPassTypes(store.getState().selectedPassTypes);
  };

  const removeSelected = () => {
    store.dispatch({
      type: 'REMOVE_SELECTED',
      payload: [],
    });
  };

  const isSelected = (): boolean => passTypes
    .filter((passType: PassType): boolean => passType.quantity > 0).length !== 0;

  const filteredPassGroups = useMemo(() => passGroupsFilter(
    communityId,
    companyProfileData?.getCompanyProfile.data?.businessType || '',
    groupData?.getPassGroups.data || [],
  ), [groupData, communityId, companyProfileData]);

  return {
    passGroups: filteredPassGroups,
    passTypes,
    selectedPassTypes,
    setQuantity,
    incQuantity,
    saveSelected,
    removeSelected,
    isSelected,
  };
}
