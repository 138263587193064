import Tippy from '@tippyjs/react';
import React, { useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { gql, useMutation } from '@apollo/client';
import { ReactComponent as ActionImg } from '../../assets/actionIcon.svg';
import { PassStatuses } from '../../common_lib/types/passInfo';
import PassInfo, { passStatusMapTitles, paymentStatusMapTitles } from '../../common_lib_front/types/passInfo';
import { useCreateApplication } from '../../hooks/createApplication';
import { getPassImage } from '../../hooks/useGetPassImage';
import { exportPdf } from '../../utilities/pdf';
import style from './myPassGrid.module.css';
import isRefundOptionAllow from '../../common_lib_front/utilities/refundOptionConfig';

interface PopupCellRendererProps {
  data: {
    actions: PassInfo,
  };
  setCurrentPass: (pass: PassInfo) => void;
  setShowInfoDialog: (show: boolean) => void;
  setShareDialog: (show: boolean) => void;
  setPassInfoMode: (passInfoMode: 'pass' | 'vehicle') => void;
  setPassDeleteMode: (show: boolean) => void;
  passInfoMode: 'pass' | 'vehicle';
  onRenew: () => void;
  bulkSelectedData: PassInfo[] | undefined;
}

const RENEW_PASSES = gql`
  mutation initializeRenewal(
    $passIds: [String!]!
  ) {
    initializeRenewal(
      passIds: $passIds
    ) {
      success
      data {
        registrationId
      }
      error
    }
  }
`;

/* eslint-disable react/prop-types */
const PopupCellRenderer: React.FC<PopupCellRendererProps> = ({
  data,
  setCurrentPass,
  setShowInfoDialog,
  setShareDialog,
  passInfoMode,
  setPassInfoMode,
  setPassDeleteMode,
  bulkSelectedData,
}) => {
  const tippyRef = useRef<Element>(null);
  const [visible, setVisible] = useState(false);
  const history = useHistory();
  const show = () => setVisible(true);
  const hide = () => setVisible(false);
  // console.log('GRID data: ', data);
  const [doRenewPass] = useMutation(RENEW_PASSES);
  const [doCreateApplication] = useCreateApplication();

  const printPdf = async (pass: PassInfo) => {
    const url = await getPassImage(data?.actions?.passId)
      .then((res) => res.url)
      .catch((err): string | null => {
        console.error(err);
        if (typeof data?.actions?.url === 'string' && data.actions.url.length > 5) {
          return data.actions.url;
        }
        // onAlert({
        //   color: 'red',
        //   message: 'Something went wrong. We are unable to download one or all of your passes.',
        // });
        return null;
      });
    if (!url) {
      window.alert('Something went wrong. We could not get your pass image.');
      return;
    }
    const pdf = await exportPdf([url]);
    if (pdf) {
      const blob = pdf.output('blob');
      const nextWindow = window.open(URL.createObjectURL(blob), '_blank');
      nextWindow?.focus();
      nextWindow?.print();
    }
  };

  const isMultipleOptionSelection = !!(bulkSelectedData && bulkSelectedData.length > 0);

  const onClickHandler = (option: string) => {
    hide();
    if (option === 'download') {
      setCurrentPass(data?.actions);
      setShowInfoDialog(true);
    }
    if (option === 'print') {
      printPdf(data?.actions);
    }
    if (option === 'share') {
      setCurrentPass(data?.actions);
      setShareDialog(true);
    }
    if (option === 'refund') {
      history.push(`/vendor/popup/refund-pass/${data?.actions?.passId}`);
    }
    if (option === 'renew') {
      doRenewPass({
        variables: {
          passIds: [data?.actions?.passId],
        },
        onCompleted: (d) => {
          if (d?.initializeRenewal.success) {
            doCreateApplication({
              variables: {
                registrationId: d?.initializeRenewal?.data[0]?.registrationId,
                type: 'vendor-renewal',
              },
              onCompleted: (complete) => {
                history.push(`/vendor/registration/${d?.initializeRenewal?.data[0]?.registrationId}/vehicle`, { type: 'renew' });
              },
            });
          }
        },
      });
    }
    if (option === 'delete') {
      setCurrentPass(data?.actions);
      setPassDeleteMode(true);
    }
    if (option === 'vehicleinfo') {
      setPassInfoMode('vehicle');
    }
    if (option === 'passinfo') {
      setPassInfoMode('pass');
    }
    if (option === 'purchase') {
      history.push(`/vendor/registration/${data?.actions?.registrationId}/vehicle`);
    }
  };
  const dropDownContent = (
    <div className={style.menuContainer} role="menu">
      {data?.actions?.status === 'incomplete-rental-car' && !isMultipleOptionSelection ? (
        <Link
          to={`/vendor/popup/complete-pass/${data?.actions?.passId}`}
          tabIndex={0} // Add tabIndex to make it focusable
          className={style.menuItem}
        >
          <span className={style.itemText}>Complete</span>
        </Link>
      ) : (
        <>
          {data?.actions?.paid === 'unpaid' && !isMultipleOptionSelection
            && (
              <div
                onClick={() => onClickHandler('purchase')}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onClickHandler('purchase');
                  }
                }}
                role="menuitem"
                tabIndex={0} // Add tabIndex to make it focusable
                className={style.menuItem}
              >
                <span className={style.itemText}>Complete and Purchase</span>
              </div>
            )}
          { ((data?.actions?.passInfo?.passApprovalRequire && data?.actions?.applicationData?.status === 'approved') || (!data?.actions?.passInfo?.passApprovalRequire && ['active', 'inactive'].includes(data?.actions?.status))) && (
            <div
              onClick={() => onClickHandler('download')}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onClickHandler('download');
                }
              }}
              role="menuitem"
              tabIndex={0} // Add tabIndex to make it focusable
              className={style.menuItem}
            >
              <span className={style.itemText}>Download</span>
            </div>
          )}
          {/* {data?.actions?.applicationData?.status
          ? data?.actions?.applicationData?.status === 'approved'
          : ['active', 'inactive'].includes(data?.actions?.status) && (
            <div
              onClick={() => onClickHandler('print')}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onClickHandler('print');
                }
              }}
              role="menuitem"
              tabIndex={0} // Add tabIndex to make it focusable
              className={style.menuItem}
            >
              <span className={style.itemText}>Print</span>
            </div>
          )} */}
          { ((data?.actions?.passInfo?.passApprovalRequire && data?.actions?.applicationData?.status === 'approved') || (!data?.actions?.passInfo?.passApprovalRequire && ['active', 'inactive'].includes(data?.actions?.status))) && (
            <div
              onClick={() => onClickHandler('share')}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onClickHandler('share');
                }
              }}
              role="menuitem"
              tabIndex={0} // Add tabIndex to make it focusable
              className={style.menuItem}
            >
              <span className={style.itemText}>Share</span>
            </div>
          )}
          {!isMultipleOptionSelection ? (
            <div
              onClick={() => onClickHandler('renew')}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onClickHandler('renew');
                }
              }}
              role="menuitem"
              tabIndex={0} // Add tabIndex to make it focusable
              className={style.menuItem}
            >
              <span className={style.itemText}>Renew</span>
            </div>
          ) : null}
          {
            // eslint-disable-next-line max-len
            isRefundOptionAllow(data?.actions?.status, data?.actions?.paid) && !isMultipleOptionSelection && (
              <div
                onClick={() => onClickHandler('refund')}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onClickHandler('refund');
                  }
                }}
                role="menuitem"
                tabIndex={0} // Add tabIndex to make it focusable
                className={style.menuItem}
              >
                <span className={style.itemText}>Refund</span>
              </div>
            )
          }
          {(data?.actions?.status as string) !== PassStatuses.inviteDeleted && (
            <div
              onClick={() => onClickHandler('delete')}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onClickHandler('delete');
                }
              }}
              role="menuitem"
              tabIndex={0} // Add tabIndex to make it focusable
              className={style.menuItem}
            >
              <span className={style.itemText}>Delete</span>
            </div>
          )}
          {/* {passInfoMode === 'pass' ? (
            <div
              onClick={() => onClickHandler('vehicleinfo')}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onClickHandler('vehicleinfo');
                }
              }}
              role="menuitem"
              tabIndex={0} // Add tabIndex to make it focusable
              className={style.menuItem}
            >
              <span className={style.itemText}>Vehicle Info</span>
            </div>
          ) : (
            <div
              onClick={() => onClickHandler('passinfo')}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onClickHandler('passinfo');
                }
              }}
              role="menuitem"
              tabIndex={0} // Add tabIndex to make it focusable
              className={style.menuItem}
            >
              <span className={style.itemText}>Pass Info</span>
            </div>
          )} */}
        </>
      )}
    </div>
  );

  return (
    <Tippy
      ref={tippyRef}
      content={dropDownContent}
      visible={visible}
      onClickOutside={hide}
      allowHTML
      arrow={false}
      appendTo={document.body}
      interactive
      placement="right"
    >
      <div className={style.actionBox}>
        <button className={style.actionBtn} onClick={visible ? hide : show}>
          <p>Actions</p>
          <ActionImg />
        </button>
      </div>
    </Tippy>
  );
};

export default PopupCellRenderer;
