import VehicleInfo, { newVehicleInfo } from './vehicleInfo';

export const PassTypes = {
  generic: 'generic',
  single: 'single',
  // host: 'host',
  vendor: 'vendor',
  invitedGuest: 'invited-guest',
  fastPass: 'fast-pass',
  gateAttendant: 'gate-attendant',
  wristband: 'wristband',
  resident: 'resident',
  visitor: 'visitor',
} as const;

export const PaymentTypes = {
  none: 'none',
  ach: 'ach',
  creditCard: 'creditCard',
  cash: 'cash',
  free: 'free',
  prepaidCreditCard: 'prepaidCreditCard',
  prepaidACH: 'prepaidACH',
  onAccount: 'on-account',
} as const;

export const PassStatuses = {
  active: 'active',
  inactive: 'inactive',
  incomplete: 'incomplete',
  rentalIncomplete: 'incomplete-rental-car',
  expired: 'expired',
  refunded: 'refunded',
  inviteDeleted: 'invite-deleted',
  temporaryIssued: 'temporary-issued',
  pendingRenewal: 'pending-renewal',
  deleted: 'deleted',
  suspended: 'suspended',
  deleteRequested: 'delete-requested',
  needsReview: 'needs-review',
} as const;

/**
 * This is meant to be all pass statuses that
 * should trigger an update of the associated
 * PDK person's `enabled` status when an admin
 * user manually changes a pass's status -Parker
*/
export const DeactivationReactivationStatuses: string[] = [
  PassStatuses.active,
  PassStatuses.inactive,
  PassStatuses.incomplete,
  PassStatuses.rentalIncomplete,
  PassStatuses.suspended,
];

// this type makes it possible to ensure at compile-time
// that the set of keys in passStatusMapTiles matches the
// set of values of the PassStatuses definition
type ReversePassStatusMapType = {
  [Property in typeof PassStatuses[keyof typeof PassStatuses]]: string;
};
interface ISeriouslyHaveToExtendATypeToAddAnIndexSignature extends ReversePassStatusMapType {
  [key: string]: string
}

export const passStatusMapTitles: ISeriouslyHaveToExtendATypeToAddAnIndexSignature = {
  active: 'Activated',
  inactive: 'Ready to Scan',
  incomplete: 'Incomplete',
  'incomplete-rental-car': 'Incomplete Rental Car',
  expired: 'Expired',
  refunded: 'Refunded',
  'temporary-issued': 'Temporary Pass',
  'invite-deleted': 'Invite Deleted',
  deleted: 'Deleted',
  'pending-renewal': 'Pending Renewal',
  suspended: 'Suspended',
  'delete-requested': 'Delete Requested',
  'needs-review': 'Needs Review',
};

export const PaymentStatuses = {
  paid: 'paid',
  unpaid: 'unpaid',
  noCharge: 'no-charge',
  refunded: 'refunded',
  achFailed: 'ach-failed',
  achPending: 'ach-pending',
} as const;

export type PassStatusesType = {
  -readonly [Property in keyof typeof PassStatuses]: typeof PassStatuses[Property]
}
export type PassStatusesValues = PassStatusesType[keyof PassStatusesType];

export type PassInfo = {
  vehicle: VehicleInfo;
  startDate: string;
  validThrough: string;
  price: number;
  number: string;
  status: PassStatusesValues;
  [key: string]: any;
}

export function newPassInfo(data: any = {}): PassInfo {
  const res = {
    vehicle: newVehicleInfo(data.vehicle),
    startDate: '',
    validThrough: '',
    price: 10,
    number: '',
    status: 'active' as PassStatusesValues,
  };
  Object.assign(res, data);
  return res;
}

export function passIsPaid(paymentStatus: string): boolean {
  // we treat achPending as paid
  return ([PaymentStatuses.paid, PaymentStatuses.achPending] as string[]).includes(paymentStatus);
}

export default PassInfo;
