import React, { useEffect } from 'react';
import { ReactComponent as AddIcon } from '../../assets/add.svg';
import { ReactComponent as DelIcon } from '../../assets/del.svg';
import style from './textArrayInput.module.css';

type TAIProps = {
  value: string[];
  setValue: (nVal: string[]) => void;
  id: string;
  required?: number;
  label?: string;
  addLabel?: string;
};
export default function TextArrayInput(props: TAIProps): React.ReactElement {
  const { value, setValue, id, required, label, addLabel } = props;

  // update item in array
  function update(nval: string, idx: number): void {
    const newVal = [...value];
    newVal[idx] = nval;
    setValue(newVal);
  }

  // remove item from array
  function remove(idx: number): void {
    const newVal = [...value];
    newVal.splice(idx, 1);
    setValue(newVal);
  }

  // add new item to array
  function add(): void {
    const newVal = [...value, ''];
    setValue(newVal);
  }

  // check validity
  useEffect(() => {
    // add one if array is empty
    if (value.length === 0) {
      add();
      // add one if the required length is not met
    } else if (required && value.length < required) {
      add();
    }
  }, [value.length, required]);

  return (
    <div className={style.wrapper}>
      {label && <label htmlFor={`${id}-text-array-${value.length - 1}`}>{label}</label>}
      <ul>
        {value.map((v, idx) => (
          <li key={idx}>
            <input
              value={v}
              type="text"
              required={
                Boolean(idx) || (typeof required === 'number' ? required > idx : false)
              }
              onChange={e => update(e.target.value, idx)}
            />
            {idx > 0 && <DelIcon className={style.delIcon} onClick={() => remove(idx)} />}
          </li>
        ))}
      </ul>
      {/* <button type="button" onClick={add}>Add</button> */}
      <label htmlFor={`${id}-add-btn`} className={style.addBox}>
        {/* <input id={`${id}-add-btn`} type="checkbox" checked={false} value="" onChange={add} /> */}
        <AddIcon id={`${id}-add-btn`} onClick={add} className={style.addIcon} />
        {addLabel}
      </label>
    </div>
  );
}
TextArrayInput.defaultProps = {
  required: 0,
  label: '',
  addLabel: 'Add',
};
