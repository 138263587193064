import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useQuery } from '@apollo/client';
import style from './businessTypesDropdown.module.css';

type businessTypesDropdown = {
  title: string,
  value: string,
  changeHandler: (e: React.ChangeEvent<HTMLSelectElement>) => void,
  htmlFor: string,
  readonly?: boolean
}

type businessObj = {
  name: string;
  educationUrl: string;
};

const GET_BUSINESS_TYPES = gql`
query getBusinessTypes {
  getBusinessTypes {
    success
    data {
      name
      educationUrl
      fee
    }
    error
  }
}
`;

export default function BusinessTypeDropdown(props: businessTypesDropdown): ReactElement {
  const {
    title, value, changeHandler, htmlFor, readonly,
  } = props;

  const [businessTypes, setBusinessTypes] = useState([]);

  useQuery(GET_BUSINESS_TYPES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (d) => {
      if (d.getBusinessTypes.success) {
        const filterData = d.getBusinessTypes.data.map((ele: businessObj) => ele.name) || [];
        setBusinessTypes(filterData);
      }
    },
  });

  const { t } = useTranslation();

  return (
    <label htmlFor={htmlFor} className={`${style.label} textColorMedium`}>
      {title}
      <select
        disabled={readonly}
        value={value}
        id={htmlFor}
        onChange={changeHandler}
        className={`${style.select} `}
      >
        <option hidden value="">{t('Select Your Business Type')}</option>
        {businessTypes.map((s: string) => (
          <option key={s} value={s}>
            {s}
          </option>
        ))}
      </select>
    </label>
  );
}

BusinessTypeDropdown.defaultProps = {
  readonly: false,
};
