import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCompanyVehicles } from '../../hooks/useCompanyVehicles';
import PassInfo, { newPassInfo } from '../../types/passInfo';
import { newVehicleInfo, VehicleInfo } from '../../types/vehicleInfo';
import VehicleFormPopup from './vehicleFormPopup';

type VehicleDataWrapperRenderType = (params: {
  data: PassInfo;
  setData: React.Dispatch<React.SetStateAction<PassInfo>>;
}) => ReactElement;
type VehicleDataWrapperProps = {
  render: VehicleDataWrapperRenderType;
};

export default function VehicleDataWrapper(props: VehicleDataWrapperProps): ReactElement {
  const { vehicleId } = useParams<{ vehicleId?: string }>();
  const { render } = props;
  const [data, setData] = useState<PassInfo>(newPassInfo());

  const { refetch } = useCompanyVehicles({
    onCompleted: d => {
      if (!vehicleId) return;
      const result = d.getCompanyVehicles.data?.find(v => v.vehicleId === vehicleId);
      if (!result) return;
      setData(prev =>
        newPassInfo({
          ...prev,
          vehicle: newVehicleInfo(result as unknown as Partial<VehicleInfo>),
        }),
      );
    },
  });

  useEffect(() => {
    const handler = () => refetch();
    document.addEventListener('vehicle-data-invalidation', handler);
    return () => document.removeEventListener('vehicle-data-invalidation', handler);
  }, [refetch]);

  return render({ data, setData });
}

type VDFPprops = {
  excludeFields?: string[];
};
export function VehicleDataFormPopup(props: VDFPprops): ReactElement {
  const { excludeFields } = props;
  return (
    <VehicleDataWrapper
      render={({ data }) => (
        <VehicleFormPopup
          pass={data}
          excludeFields={excludeFields}
          // setPass={setData}
        />
      )}
    />
  );
}
