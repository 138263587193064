import React from 'react';
// import { useTranslation } from 'react-i18next';
import { IspecialInstructionInfo } from '../../types/specialInstructionInfo';
import style from './specialInstruction.module.css';
import useSpecialInstruction from './useSpecialInstruction';

export type SpecialInstructionProps = {
  pathName?: string;
  instructions?: IspecialInstructionInfo[] | [];
};

function SpecialInstructionRender(props: {
  instruction: IspecialInstructionInfo;
}): React.ReactElement {
  const { instruction } = props;
  switch (instruction.type) {
    case 'bold':
      return <h3>{instruction.text}</h3>;
    case 'body':
    default:
      return <p className={style.instructionText}>{instruction.text}</p>;
  }
}

export default function SpecialInstruction(
  props: SpecialInstructionProps,
): React.ReactElement {
  const { instructionsData } = useSpecialInstruction(props);

  return (
    <div className={style.instructionContainer}>
      {instructionsData &&
        instructionsData.map(item => (
          <SpecialInstructionRender key={item.text} instruction={item} />
        ))}
    </div>
  );
}
