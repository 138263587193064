import React, { useEffect, useRef } from 'react';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import useContainerBlur from '../../common_lib_front/utilities/useContainerBlur';
import style from './selectTable.module.css';

type TBase = Record<string, unknown>;

export type SelectTableHeaderConfig<T extends TBase> = {
  label: string;
  field: keyof T | ((val: T) => React.ReactNode);
}[];

type SelectTableProps<T extends TBase> = {
  headers: SelectTableHeaderConfig<T>;
  data: T[];
  displayVal: keyof T | ((val?: T) => string);
  value?: T;
  setValue: (val: T | undefined) => void;
  id: string;
  topHead?: React.ReactElement;
  required?:boolean;
  noHeader?: boolean;
  preventClear?: boolean;
  validityTest?: (obj: T) => boolean;
  disabled?: boolean;
  displayPlaceholder?: string;
}

function keyOrFnHelper<T extends TBase, K>(
  val: T | undefined,
  displayVal: keyof T | ((v: T) => K),
  displayPlaceholder?: string,
): string | K {
  if (!val) {
    return displayPlaceholder || '';
  }
  if (['string', 'number', 'symbol'].includes(typeof displayVal)) {
    const tmp = val?.[displayVal as keyof T];
    if (typeof tmp === 'string') return tmp;
    return '';
  }
  if (typeof displayVal === 'function') {
    return displayVal(val!);
  }
  return '';
}

export default function SelectTable<T extends TBase>(props: SelectTableProps<T>)
: React.ReactElement {
  const {
    headers,
    data,
    displayVal,
    value,
    setValue,
    id,
    topHead,
    required,
    noHeader,
    preventClear,
    validityTest,
    disabled,
    displayPlaceholder,
  } = props;
  const { open, containerId, setOpen } = useContainerBlur(`${id}-container`);
  const inpt = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const isValid = (!!value && validityTest) ? validityTest(value) : !!value;
    if (required && !isValid) {
      inpt.current?.setCustomValidity('Please select an option from the table.');
    } else {
      inpt.current?.setCustomValidity('');
    }
    // inpt.current?.reportValidity();
  }, [required, inpt, value]);

  return (
    <div id={containerId} className={style.wrapper}>
      <input ref={inpt} id={id} type="text" value={keyOrFnHelper(value, displayVal, displayPlaceholder)} onFocus={() => setOpen(true)} className={style.defaultInput} disabled={disabled} />
      {open && (
        <div className={style.dropdown}>
          {topHead && (
            <div className={style.dropdownHead}>
              {topHead}
            </div>
          )}
          <table className={style.dropdownTable}>
            {!noHeader && (
              <thead>
                <tr>
                  {headers.map((h) => <th>{h.label}</th>)}
                </tr>
              </thead>
            )}
            {data.length > 0 && (
              <tbody>
                {data.map((d) => (
                  <tr
                    onClick={() => {
                      setValue(d);
                      inpt.current?.blur();
                    }}
                    className={style.selectableRow}
                  >
                    {headers.map((h) => <td>{keyOrFnHelper(d, h.field)}</td>)}
                  </tr>
                ))}
              </tbody>
            )}
          </table>
          {(value && !preventClear) && (
            <div className={style.clear}>
              <GenericButton
                color="white"
                title="Clear"
                outline="small"
                clickHandler={() => {
                  setValue(undefined);
                }}
              />
            </div>
          )}
          {data.length === 0 && (
            <div className={style.emptyState}>
              <p>No Options Yet...</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
SelectTable.defaultProps = {
  value: undefined,
  topHead: undefined,
  required: false,
  noHeader: false,
  preventClear: false,
  validityTest: undefined,
  disabled: false,
  displayPlaceholder: undefined,
};
