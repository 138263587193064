import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { formatDateWithMonthName } from '../../common_lib_front/utilities/formatDate';
import { passStatusTitlesMap } from './useColDefs';
import { paymentStatusMapTitles } from '../../common_lib_front/types/passInfo';

// set fields to export and order that they will be exported
const columnKeysAllowList = [
  'passType',
  'passNumber',
  'addons',
  'destination',
  'startDate',
  'validThru',
  'passShared',
  'passStatus',
  'paid',
  'passPrice',
  'make',
  'model',
  'color',
  'platenumber',
  'primarydrivername',
  'identificationNumber',
];

type csvExportPropsType = {
  colDefs: ColDef[];
  gridRef: React.RefObject<AgGridReact<any>>;
}
// Export to CSV
export const csvExport = ({ colDefs, gridRef }: csvExportPropsType): void => {
  // collect allowed headers
  const headers: string[] = [];
  // collect header names in correct order
  columnKeysAllowList.forEach((ckey) => {
    headers.push(
      // try to use header name from colDefs, use ckey as backup
      colDefs.find((cd) => cd.field === ckey)?.headerName || ckey,
    );
  });

  // collect data
  const dataToExport: string[] = [headers.join(',')];
  // collect data in grid so that we can manipulate it
  const allNodes: any[] = [];
  gridRef.current?.api.forEachNode((row) => {
    if (row.displayed) allNodes.push(row);
  });

  // sort and add data to export
  allNodes
    .sort((a, b) => a.rowIndex - b.rowIndex)
    .forEach((row) => {
      const { data: item } = row;
      const itemData: any[] = [];
      // handle special cases
      columnKeysAllowList.forEach((key: string) => {
        if (key === 'paid') {
          itemData.push(paymentStatusMapTitles[item[key]] || item[key]);
        } else if (key === 'passStatus') {
          itemData.push(passStatusTitlesMap[item[key]] || item[key]);
        } else if (key === 'validThru' || key === 'startDate') {
          itemData.push(formatDateWithMonthName(new Date(item[key])));
        } else {
          itemData.push(`"${item[key]}"`);
        }
      });
      // join row data
      dataToExport.push(itemData.join(','));
    });
  // join rows
  const str = dataToExport.join('\r\n');
  // download
  const blob = new Blob([str], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const pom = document.createElement('a');
  pom.href = url;
  pom.setAttribute('download', 'export.csv');
  pom.click();
};
