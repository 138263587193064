import { gql, useMutation, useQuery } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { keyBy, map, uniq } from 'lodash';
import { useTranslation } from 'react-i18next';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import LoadingDiamonds from '../../../common_lib_front/components/loadingDiamonds/loadingDiamonds';
import EmployeeInfo from '../../../common_lib_front/types/employeeInfo';
import PassInfo, { newPassInfo } from '../../../common_lib_front/types/passInfo';

import { ReactComponent as ExpandImg } from '../../../assets/expandDown.svg';
import ConfirmRenewDialog from '../../../components/confirmRenewDialog/confirmRenewDialog';

import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import { PassType } from '../../../common_lib_front/types/passType';
import { backendClient } from '../../../common_lib_front/utilities/BackendAPI';
import store from '../../../common_lib_front/utilities/store';
import MyPassGrid from '../../../components/myPassGrid/myPassGrid';
import { GET_PRICE_INFO } from '../purchasePass/registerVehicle/registerVehicleRequests';
import style from './myPasses.module.css';

const GET_MY_PASSES_QUERY = gql`
  query {
    getMyPasses {
      success
      error
      data {
        passId
        passType
        passInfoId
        registrationId
        userId
        startDate
        endDate
        status
        paid
        number
        externalCredentialNumber
        url
        addons
        sharedWith {
          employeeId
          firstName
          lastName
          email
          phoneNumber
        }
        vehicle {
          vehicleId
          make
          vehicleModel
          type
          color
          licensePlate
          fleetNumber
          licensePlateState
          primaryDriverName
          licensePrimaryDriver
          destination
          employeeId
          identificationSource
        }
        passInfo {
          name
          passApprovalRequire
        }
        applicationData {
          type
          registrationId
          status
        }
      }
    }
  }
`;

const GET_PASS_INFO = gql`
  query getPassInfo($passInfoId: String!) {
    getPassInfo(passInfoId: $passInfoId) {
      success
      error
      data {
        name
        passInfoId
        passApprovalRequire
      }
    }
  }
`;

export const GET_EMPLOYEE = gql`
  query Query (
    $employeeId: String
  ) {
    getEmployee (
      employeeId: $employeeId
    ) {
      success
      error
      data {
        employeeId
        firstName
        lastName
        phoneNumber
        email
        isLicenseSelected
        source
        identificationNumber
      }
    }
  }
`;

export const RENEW_PASS_MUTATION = gql`
  mutation RenewPass (
    $passes: RenewPassInput!
  ) {
    renewPass(
      passes: $passes
    ) {
      data {
        registrationId
      }
    }
  }
`;

export default function MyPasses(): React.ReactElement {
  const [expandView, setExpandView] = useState<boolean>(false);
  const [renewMode, setRenewMode] = useState<boolean>(false);
  const [selected, setSelected] = useState<PassInfo[]>([]);
  const [showRenew, setShowRenew] = useState<boolean>(false);

  const { t } = useTranslation();

  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');

  const [employees, setEmployees] = useState<EmployeeInfo[]>([]);
  const [passData, setPassData] = useState<PassInfo[]>([]);
  const [redirect, setRedirect] = useState<string>('');
  const { communityId } = useContext(CommunityContext);

  const { loading: dataLoading, refetch: refetchData } = useQuery(GET_MY_PASSES_QUERY, {
    fetchPolicy: 'cache-and-network',
    onError: (e) => {
      setAlert(e.message);
      setAlertColor('red');
    },
    onCompleted: async (d) => {
      if (!d.getMyPasses.success) {
        setAlert(d.getMyPasses.error);
        setAlertColor('red');
      } else {
        setPassData(d.getMyPasses.data.map((pass: any) => newPassInfo(pass)));
      }

      const res = d?.getMyPasses?.data?.map((p: any) => newPassInfo(p));
      // Get passes' prices by passInfoId
      const passInfoIds = uniq(
        map(res, 'passInfoId'),
      );
      try {
        const priceInfoRes = await Promise.all(
          map(passInfoIds, (id: string) => backendClient.query({
            query: GET_PRICE_INFO,
            variables: { passInfoId: id },
          })),
        );
        // Set price info to passes by passInfoId
        const priceInfoMap = keyBy(map(priceInfoRes, 'data.getPriceInfo.data'), 'passInfoId');
        res?.forEach((item: any) => {
          item.priceInfo = priceInfoMap[item.passInfoId];
        });
        const passInfoRes = await Promise.all(
          map(passInfoIds, (id: string) => backendClient.query({
            query: GET_PASS_INFO,
            variables: { passInfoId: id },
          })),
        );
        // Set pass info to passes by passInfoId
        const passInfoMap = keyBy(map(passInfoRes, 'data.getPassInfo.data'), 'passInfoId');
        res?.forEach((item: any) => {
          item.passInfo = passInfoMap[item.passInfoId];
        });
      } catch (error: any) {
        console.log(error);
      }
      setPassData(res);
    },
  });

  const { loading: employeeLoading } = useQuery(GET_EMPLOYEE, {
    fetchPolicy: 'cache-and-network',
    onError: (e) => {
      setAlert(e.message);
      setAlertColor('red');
    },
    onCompleted: (d) => {
      if (!d.getEmployee.success) {
        setAlert(d.getEmployee.error);
        setAlertColor('red');
      } else {
        setEmployees(d.getEmployee.data);
      }
    },
  });

  const [doRenew, { loading: isRenewing }] = useMutation(RENEW_PASS_MUTATION, {
    onError: (e) => {
      setAlert(e.message);
      setAlertColor('red');
    },
    onCompleted: (d) => {
      if (!d.renewPass.success) {
        setAlert(d.renewPass.error);
        setAlertColor('red');
      }
      return d.renewPass;
    },
  });

  const activatedFilter = function (info: PassInfo): boolean {
    return info.status !== 'expired';
  };

  const expiredFilter = function (info: PassInfo): boolean {
    return info.status === 'expired';
  };

  const handleSelect = function (list: PassInfo[], origin: PassInfo[]) {
    const listSet = new Set(list);
    const updatedSet = new Set([...selected, ...listSet]);

    origin.filter((item) => !listSet.has(item)).forEach((item) => updatedSet.delete(item));
    setSelected([...updatedSet]);
  };

  const activatedData = passData.filter(activatedFilter);
  const expiredData = passData.filter(expiredFilter);

  const isSelect = function (): boolean {
    return selected.length !== 0;
  };

  // pass handle
  const onRenew = function (pass: PassInfo) {
    setSelected([pass]);
    setShowRenew(true);
  };

  const confirmRenew = async function () {
    const newPassTypes = selected.map((el) => ({
      passInfoId: el.passInfoId,
      communityId,
      complete: true,
      name: el.passType,
      quantity: 1,
    }));
    store.dispatch({
      type: 'SAVE_SELECTED',
      payload: newPassTypes,
    });
    if (selected) {
      // We call doRenew endpoint which will create a
      // new registration and assign all selected passes to it
      const renewPass = await doRenew({
        variables: {
          passes: { passId: selected.map((pass) => pass.passId) },
        },
      });
      if (renewPass) {
        setRedirect(`/vendor/registration/${renewPass.data.renewPass.data.registrationId}/vehicle`);
      }
    }
    setShowRenew(false);
    await refetchData({});
  };

  if (redirect) {
    return (
      <Redirect to={redirect} />
    );
  }

  return (
    <div className={style.container}>
      <div className={style.box}>
        <div className={`${style.infoBox} white`}>
          <div className={`${style.subHeader} mainColor`}>
            <div className={style.subHeaderLeft}>
              <div className={`${style.circle} white`} />
              <h1 className={`${style.title} textColorWhite`}>
                {t('My Passes')}
              </h1>
            </div>
            {/* <div className={style.subHeaderRight}>
              {renewMode ? (
                <>
                  {isSelect() ? (
                    <GenericButton title="Confirm" size="large" outline="no-border" clickHandler={
                      () => setShowRenew(true)} className={style.confirm} />
                  ) : (
                    <GenericButton title={t('Renew Passes')} size="large" outline="no-border" click
                    Handler={() => setRenewMode(false)} className={style.renewActive} />
                  )}
                </>
              ) : (
                <GenericButton title={t('Renew Passes')} size="large" outline="no-border" clic
                kHandler={() => setRenewMode(true)} className={style.renewInactive} />
              )}
              </div> */}
          </div>

          <div className={style.mainContent}>
            <GenericAlert
              color={alertColor}
              title={alert}
              hidden={!alert}
            />

            {dataLoading || employeeLoading || isRenewing ? (
              <div className={style.loadingBox}>
                <LoadingDiamonds
                  title="Loading Pass Records"
                />
              </div>
            ) : (
              <>
                <MyPassGrid
                  data={activatedData}
                  showExpired={false}
                  employees={employees}
                  // onRenew={(pass) => onRenew(pass)}
                  onReload={() => refetchData({})}
                  onAlert={(someAlert: any) => {
                    setAlertColor(someAlert.color);
                    setAlert(someAlert.message);
                  }}
                />
                {expandView ? (

                  <button className={style.extendBtn} onClick={() => setExpandView(false)}>
                    <ExpandImg className={`${style.expandUp} ${style.svg}`} />
                    <p>{t('Click here to hide expired passes vehicle info')}</p>
                  </button>
                ) : (
                  <button className={style.extendBtn} onClick={() => setExpandView(true)}>
                    <ExpandImg className={style.svg} />
                    <p>{t('Click here to view expired passes vehicle info')}</p>
                  </button>
                )}
                {expandView ? (
                  <MyPassGrid
                    data={expiredData}
                    // showRenew={renewMode}
                    showExpired={expandView}
                    employees={employees}
                    // onSelect={(list) => handleSelect(list, expiredData)}
                    // onRenew={(pass) => onRenew(pass)}
                    onReload={() => refetchData({})}
                    onAlert={(someAlert: any) => {
                      setAlertColor(someAlert.color);
                      setAlert(someAlert.message);
                    }}
                  />
                ) : null}
              </>
            )}
          </div>
        </div>

        <ConfirmRenewDialog
          showDialog={showRenew}
          data={selected}
          onClose={() => setShowRenew(false)}
          onRenew={() => confirmRenew()}
        />
      </div>
    </div>
  );
}
