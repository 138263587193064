import React from 'react';
import {
  Redirect, Route, Switch, useParams,
} from 'react-router-dom';
import RegistrationHome from './registrationHome/registrationHome';
import CommunityOrientation from './communityOrientation/communityOrientation';
import UploadDocument from './uploadDocuments/uploadDocuments';
import SecureCheckout from './secureCheckout/secureCheckout';
import OrderSummary from './orderSummary/orderSummary';
import Initialize from './initialize/initialize';

export default function InitialOrientation(): React.ReactElement {
  const { registrationId } = useParams<{ registrationId?: string }>();
  return (
    <div>
      <Switch>
        <Route path="/vendor/initial-registration/initialize">
          <Initialize />
        </Route>
        <Route path="/vendor/initial-registration/:registrationId/company-info">
          <RegistrationHome />
        </Route>
        <Route path="/vendor/initial-registration/:registrationId/education">
          <CommunityOrientation />
        </Route>
        <Route path="/vendor/initial-registration/:registrationId/documents">
          <UploadDocument />
        </Route>
        <Route path="/vendor/initial-registration/:registrationId/secure-checkout">
          <SecureCheckout />
        </Route>
        <Route path="/vendor/initial-registration/:registrationId/receipt">
          <OrderSummary />
        </Route>
        <Route path="/vendor/initial-registration/:registrationId">
          <Redirect to={`/vendor/initial-registration/${registrationId}/company-info`} />
        </Route>
        <Route path="/vendor/initial-registration">
          <Redirect to="/vendor/initial-registration/initialize" />
        </Route>
      </Switch>
    </div>
  );
}
